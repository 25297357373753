import React from 'react';

export default isCollasped => [
  {
    name: 'ID',
    key: 'id',
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: 'Image',
    key: 'imgurl',
    options: {
      filter: false,
      sort: false,
      display: isCollasped ? false : true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div className="cell-image-parent">
            <div className="image-container">
              <img className="image-body" src={value} alt="" />
            </div>
          </div>
        );
      },
      setCellProps: (cellValue, rowIndex, columnIndex) => ({
        className: 'cell-image',
      }),
    },
  },
  {
    name: 'Name',
    key: 'name',
  },
  {
    name: 'Text',
    key: 'text',
    options: {
      display: isCollasped ? false : true,
    },
  },
  // {
  //   name: 'Name Colour',
  //   key: 'nameHexColour',
  //   options: {
  //     filter: false,
  //     sort: false,
  //     setCellProps: (cellValue, rowIndex, columnIndex) => ({
  //       className: 'cell-color-container',
  //     }),
  //     customBodyRender: (value, tableMeta, updateValue) => {
  //       return (
  //         <div className="hex-color">
  //           <span
  //             className="collection-color"
  //             style={{ backgroundColor: value }}
  //           />
  //           <span className="collection-color-code">{value || ''}</span>
  //         </div>
  //       );
  //     },
  //   },
  // },
  // {
  //   name: 'Text Colour',
  //   key: 'textHexColour',
  //   options: {
  //     filter: false,
  //     sort: false,
  //     setCellProps: (cellValue, rowIndex, columnIndex) => ({
  //       className: 'cell-color-container',
  //     }),
  //     customBodyRender: (value, tableMeta, updateValue) => {
  //       return (
  //         <div className="hex-color">
  //           <span
  //             className="collection-color"
  //             style={{ backgroundColor: value }}
  //           />
  //           <span className="collection-color-code">{value}</span>
  //         </div>
  //       );
  //     },
  //   },
  // },
  // {
  //   name: 'Colour',
  //   key: 'hexColour',
  //   options: {
  //     filter: false,
  //     sort: false,
  //     setCellProps: (cellValue, rowIndex, columnIndex) => ({
  //       className: 'cell-color-container',
  //     }),
  //     customBodyRender: (value, tableMeta, updateValue) => {
  //       return (
  //         <div className="hex-color">
  //           <span
  //             className="collection-color"
  //             style={{ backgroundColor: value }}
  //           />
  //           <span className="collection-color-code">{value}</span>
  //         </div>
  //       );
  //     },
  //   },
  // },
  // {
  //   name: 'Collection Type ID',
  //   key: 'typeId',
  //   options: {
  //     filter: false,
  //     sort: false,
  //   },
  // },
];
