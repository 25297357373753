// import { push } from 'connected-react-router';
import Notifications from "react-notification-system-redux";
import API from "@aws-amplify/api";
import Auth from "@aws-amplify/auth";

// import { logout } from '_actions/user';

export const request = (key, path, options = {}) =>
  Auth.currentSession().then(session => {
    const config = {
      headers: Object.assign({ Authorization: session.getIdToken().jwtToken })
    };

    return API[key](
      "sixtilesAPI",
      path,
      Object.assign({ ...options, ...config })
    )
      .then(handleSuccess)
      .catch(handleError);
  });

export const handleSuccess = resp => resp;

export const handleError = error => {
  if (error.response) {
    throw error.response;
  } else {
    const response = {
      status: 500,
      body: { message: "Internal Server error" }
    };
    throw response;
  }
};

export const dispatchError = dispatch => (res = {}) => {
  // if (res.status === 401) {
  //   // dispatch(logout());
  //   // dispatch(push('/login'));
  // }

  const { message = "error" } = res.data || {};

  dispatch(
    Notifications.error({
      title: `Error: ${res.status || "Internal"}`,
      message,
      position: "tr"
    })
  );

  // throw res;
};

export function handleErrorRes(dispatch, res) {
  const { message = "error" } = res.data || {};
  if (!dispatch) {
    if (process.env.NODE_ENV === "development") {
      console.log("handleErrorRes: Dispatch does not exist", res);
    }
    return Promise.resolve();
  }
  dispatch(
    Notifications.error({
      title: `Error: ${res.status}`,
      message,
      position: "tr"
    })
  );
}
