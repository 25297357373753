import React, { Component } from 'react';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { CardTemplateItem } from '../item';
import { CardTemplateActiveItem } from '../activeItem';

const NUM_COLUMNS = 4;

export class CardTemplatesContainer extends Component {
  createCardTemplate = () => {
    const { confirmAddClick } = this.props;

    confirmAddClick();
  };

  render() {
    const {
      history,
      cardTemplates = [],
      tileTemplateId,
      packTemplateId,
      tileTemplateName,
      isAdding,
      cardTemplateId,
    } = this.props;
    const remainder = NUM_COLUMNS - cardTemplates.length % NUM_COLUMNS;

    const dummyItems = remainder !== NUM_COLUMNS ? [...Array(remainder)] : [];
    const showActiveItem = cardTemplateId || isAdding;

    const activeCardTemplate = this.props.match.params.cardTemplateId;

    return (
      <React.Fragment>
        {!showActiveItem &&
          <div className="pack-templates-header">
            <span className="pack-templates-header-title">Cards</span>
            <div className="pack-templates-actions">
              <Tooltip title={'Create Card'}>
                <IconButton
                  onClick={this.createCardTemplate}
                  className={'toolbar-selected-button'}
                  disabled={false}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>}
        {showActiveItem &&
          <CardTemplateActiveItem
            id={activeCardTemplate}
            match={this.props.match}
            isActive
            tileTemplateName={tileTemplateName}
            history={history}
            tileTemplateId={tileTemplateId}
            packTemplateId={packTemplateId}
            onEditClick={this.props.onEditClick}
          />}
        {!showActiveItem &&
          <div className={'pack-templates'}>
            {cardTemplates &&
              cardTemplates.map(x =>
                <CardTemplateItem
                  id={x}
                  key={x}
                  activeId={this.props.match.params.cardTemplateId}
                  history={history}
                  tileTemplateId={tileTemplateId}
                  onEditClick={this.props.onEditClick}
                />,
              )}
            {dummyItems.map((_, i) =>
              <div key={i} className="pack-template dummy" />,
            )}
          </div>}
      </React.Fragment>
    );
  }
}
