import { connect } from 'react-redux';
import Container from './dialog';
import {
  attemptUpdateBatchData,
  attemptBatchCreate,
  updateBatchDuplicates,
  attemptUpdateBatchNouns,
  clearBatchJob,
} from '_thunks/batch';

const mapStateToProps = (state, props) => {
  return {
    ...state.batch,
  };
};

const mapStateToDispatch = (dispatch, props) => ({
  updateBatchData: (key, data) => dispatch(attemptUpdateBatchData(key, data)),
  clearBatchJob: () => dispatch(clearBatchJob()),
  batchCreate: data => dispatch(attemptBatchCreate(data)),
  updateBatchDuplicates: titleCase =>
    dispatch(updateBatchDuplicates(titleCase)),
  updateBatchNouns: (nouns, titleCase) =>
    dispatch(attemptUpdateBatchNouns(nouns, titleCase)),
});

export const BatchTileTemplateModal = connect(
  mapStateToProps,
  mapStateToDispatch,
)(Container);
export default { BatchTileTemplateModal };
