import React from 'react';
import { IntroSection } from '_components';

export function DashboardSection() {
  return (
    <div className="dashboard-section">
      <IntroSection />
    </div>
  );
}

export default { DashboardSection };
