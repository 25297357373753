import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { setPanel } from '_actions/sectionView';
import { attemptUpdateFormData } from '_thunks/views';

import { componentMap } from '../tileTemplate/section/componentMap';
import { Panel } from './panel';

import { TileItem } from '../tileTemplate/item';
class _RoutePanel extends PureComponent {
  componentDidMount() {
    const { id, setPanel } = this.props;

    if (id) {
      setPanel(this.props);
    }

    this.setState({
      height: this.elemHeight < 400 ? 400 : this.elemHeight,
    });
  }

  componentDidUpdate(prevProps) {
    const { id, setPanel } = prevProps;

    if (this.props.id && this.props.id !== id) {
      return setPanel(this.props);
    }
    // }
    // if (!this.props.id && this.props.id !== id) {
    //   return clearItemPanel();
    // }
  }

  getCustomClass = () => {
    const { type } = this.props;

    switch (type) {
      case 'table':
        return 'table menu';
      case 'form':
        return 'edit-form';
      default:
        return '';
    }
  };

  renderChildren = () => {
    const { children } = this.props;
    return children;
  };

  renderPanel = () => {
    const PanelComponent = TileItem;

    return (
      <PanelComponent
        dataKey={'tileTemplates'}
        match={this.props.match}
        {...this.props}
      />
    );
  };

  renderForm = () => {
    const { panels, active } = this.props;
    const panel = panels['ternary'];
    if (!panel) return null;

    const { component: componentName, ...panelProps } = panel;
    const PanelComponent = componentMap[componentName];
    return (
      <Panel {...panel} hasCollasped={!!active}>
        <PanelComponent
          {...panelProps}
          dataKey={'tileTemplates'}
          {...this.props}
          match={this.props.match}
          history={this.props.history}
          isPanel
        />
      </Panel>
    );
  };

  render() {
    const { domain, hasCollasped, active, id } = this.props;

    return (
      <React.Fragment>
        <div
          key={domain}
          className={classNames(
            'section-panel',
            'item-wrapper item-wrapper-panel',
            {
              collaspe: hasCollasped,
            },
          )}
        >
          <div
            className={`item-wrapper item-wrapper-panel${!id
              ? ' placeholder'
              : ''}`}
          >
            <Panel key={'secondary'} {...this.props} hasCollasped={!!active}>
              {this.renderPanel()}
            </Panel>
          </div>
        </div>
        {this.renderForm()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { match, history: { location: { pathname } } } = props;

  let pathSplit = pathname.split('/');
  const params = {
    id: pathSplit[2],
    tileTemplateId: pathSplit[2],
    packTemplateId: pathSplit[4],
    cardTemplateId: pathSplit[6],
  };
  match.params = params;
  return {
    panels: state.sectionView.tileTemplate.panels,
    ...((props.match && props.match.params) || {}),
  };
};

const mapDispatchToProps = dispatch => ({
  updateFormData: data => dispatch(attemptUpdateFormData('tileTemplate', data)),
  setPanel: ({ match, history, queries }) => {
    dispatch(
      setPanel('tileTemplate', {
        domain: 'tileTemplate',
        type: 'item',
        level: 'secondary',
        component: 'tileTemplateItem',
      }),
    );
  },
});

export const RoutePanel = connect(mapStateToProps, mapDispatchToProps)(
  withRouter(_RoutePanel),
);

export default { RoutePanel };
