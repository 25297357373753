export const collectionTypeDefault = {
  name: {
    presence: true,
  },
  text: {
    presence: false,
  },
};

export const collectionTypeConstraints = {
  POST: {
    ...collectionTypeDefault,
  },
  PUT: {
    ...collectionTypeDefault,
    id: {
      presence: true,
    },
  },
};

export default {
  collectionTypeConstraints,
  collectionTypeDefault,
};
