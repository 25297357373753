// import React from 'react';

export default [
  {
    name: 'ID',
    key: 'id',
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: 'Name',
    key: 'name',
    options: {
      display: true,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'email',
    key: 'email',
    options: {
      display: true,
      filter: false,
      sort: true,
    },
  },
  // {
  //   name: 'isSuper',
  //   key: 'isSuper',
  //   options: {
  //     display: true,
  //     filter: false,
  //     sort: false,
  //   },
  // },
  // {
  //   name: 'isModerator',
  //   key: 'isModerator',
  //   options: {
  //     display: true,
  //     filter: true,
  //     sort: true,
  //   },
  // },
  // {
  //   name: 'isRead',
  //   key: 'isRead',
  //   options: {
  //     display: true,
  //     filter: true,
  //     sort: true,
  //   },
  // },
];
