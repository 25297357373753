import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '_components';

export class PopoverDialog extends Component {
  componentDidMount() {
    if (this.props.parentDimensions) this.positionPopover();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.parentDimensions) this.positionPopover();
  }

  renderTitle = title => {
    if (title) {
      return <div className="popover-header">{title}</div>;
    }
    return null;
  };

  positionPopover = () => {
    const popover = this.refs.popover;
    const parentDimensions = this.props.parentDimensions;
    const popoverHeight = popover.offsetHeight;

    popover.setAttribute(
      'style',
      `top: -${popoverHeight + parentDimensions.h - 5}px;`,
    );
  };

  render() {
    const {
      title,
      children,
      onCancel,
      onOK,
      cancelText,
      okText,
      className,
    } = this.props;

    const classes = classNames('popover', className);

    return (
      <div ref="popover" className={classes}>
        {this.renderTitle(title)}
        <div className="popover-body">{children}</div>
        <div className="popover-footer">
          <Button
            onClick={onCancel}
            className="btn-default"
            label={cancelText}
          />
          <Button onClick={onOK} className="btn-success" label={okText} />
        </div>
      </div>
    );
  }
}

PopoverDialog.propTypes = {
  title: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onOK: PropTypes.func.isRequired,
  cancelText: PropTypes.string.isRequired,
  okText: PropTypes.string.isRequired,
  className: PropTypes.string,
  parentDimensions: PropTypes.object,
};

PopoverDialog.defaultProps = {
  cancelText: 'Cancel',
  okText: 'Ok',
};

export default { PopoverDialog };
