// import { validate } from 'validate.js';
import { normalizeCardTemplate } from "../normalise/tileTemplates";

export const SET_CARD_TEMPLATES = "SET_CARD_TEMPLATES";
export const REMOVE_CARD_TEMPLATE = "REMOVE_CARD_TEMPLATE";
export const UPDATE_CARD_TEMPLATE = "UPDATE_CARD_TEMPLATE";
export const ADD_CARD_TEMPLATE = "ADD_CARD_TEMPLATE";
export const ADD_PACK_TEMPLATE_CARD_TEMPLATE =
  "ADD_PACK_TEMPLATE_CARD_TEMPLATE";

export function setCardTemplates(cardTemplates) {
  return {
    type: SET_CARD_TEMPLATES,
    cardTemplates
  };
}

export const addCardTemplate = cardTemplate => ({
  type: ADD_CARD_TEMPLATE,
  id: cardTemplate.id,
  cardTemplate
});

export const addPackTemplateCardTemplate = (id, cardTemplate = {}) => ({
  type: ADD_PACK_TEMPLATE_CARD_TEMPLATE,
  id,
  cardTemplateId: cardTemplate.id
});

export const removeCardTemplate = item => ({
  type: REMOVE_CARD_TEMPLATE,
  ...item
});

export const updateCardTemplate = cardTemplate => ({
  type: UPDATE_CARD_TEMPLATE,
  id: cardTemplate.id,
  cardTemplate: {
    ...normalizeCardTemplate(cardTemplate),
    fieldTemplates: undefined
  }
});
