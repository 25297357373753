import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { Navbar } from './navbar';
import { homeRoutes } from '../router/routes';

export class NavbarContainer extends Component {
  static propTypes = {
    router: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { tab } =
      homeRoutes.find(
        route =>
          route.path.split('/')[1] ===
          (props.router && props.router.location.pathname.split('/')[1]),
      ) || {};

    this.state = {
      loading: {},
      activeRoute: tab || 'collections',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.router.location.pathname.split('/') !==
      this.props.router.location.pathname.split('/')
    ) {
      const { tab } =
        homeRoutes.find(
          route =>
            route.path.split('/')[1] ===
            (nextProps.router &&
              nextProps.router.location.pathname.split('/')[1]),
        ) || {};

      this.setState({
        activeRoute: tab || 'collections',
      });
    }
  }

  switchRoute = event => {
    const path = event.target.getAttribute('data-key');
    this.props.dispatch(push(`/${path}`));
    this.setState({ activeRoute: path });
  };

  onMoreClick = path => {
    this.props.dispatch(push(`/${path}`));
    this.setState({ activeRoute: path });
  };

  render() {
    return (
      Object.values(this.state.loading).every(item => item === false) &&
      <Navbar
        switchRoute={this.switchRoute}
        activeRoute={this.state.activeRoute}
        onMoreClick={this.onMoreClick}
      />
    );
  }
}

export default { NavbarContainer };
