import {
  getCategories,
  updateCategory as editCategory,
  deleteCategory,
  createCategory,
} from '_api/categories';
import {
  setCategories,
  removeCategory,
  updateCategory,
  addCategory,
} from '_actions/categories';

import { dispatchError } from '_utils/api';

export const attemptGetCategories = () => (dispatch, getState) => {
  if (getState().fetchStatus.categories) return Promise.resolve({});
  return getCategories()
    .then(data => {
      dispatch(setCategories(data));
      return data;
    })
    .catch(dispatchError(dispatch));
};

export const attemptCreateCategory = item => dispatch =>
  createCategory(item)
    .then(data => {
      dispatch(addCategory(data));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptDeleteCategory = id => dispatch =>
  deleteCategory(id)
    .then(data => {
      dispatch(removeCategory(id));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptUpdateCategory = item => dispatch =>
  editCategory(item)
    .then(data => {
      dispatch(updateCategory(data));
      return data;
    })
    .catch(dispatchError(dispatch));
