import React, { Component } from 'react';
import { PackTemplates } from '_components';
import ItemRow from './itemRow';

export class TileItemContainer extends Component {
  render() {
    const { history, match, id, item, isAdding } = this.props;

    if (!item && !isAdding)
      return <div className="section-panel placeholder" />;
    return (
      <React.Fragment>
        <ItemRow {...this.props} />
        {id &&
          <PackTemplates
            {...this.props}
            match={match}
            history={history}
            dataKey="tileTemplates"
          />}
      </React.Fragment>
    );
  }
}

export default { TileItemContainer };
