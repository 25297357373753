import { connect } from 'react-redux';
import { attemptGetCategories } from '_thunks/categories';
import { CategoriesPageContainer } from './categoriesPageContainer';

const mapDispatchToProps = dispatch => ({
  getCategories: () => dispatch(attemptGetCategories()),
});

export const CategoriesPage = connect(
  null,
  mapDispatchToProps,
)(CategoriesPageContainer);

export default { CategoriesPage };
