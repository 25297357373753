import React from 'react';
import PropTypes from 'prop-types';
import { BaseTable, EmptyCollectionsMessage } from '_components';
import options from './options';
import columns from './columns';

export function CollectionTable(props) {
  const { collections, handleRowClick, handleAddClick, ...rest } = props;
  if (!collections || collections.length === 0)
    return <EmptyCollectionsMessage />;
  const columnKeys = columns(props.isCollasped).map(col => col.key);
  const cleanedData = collections.map(collection => {
    return columnKeys.map(key => collection[key]);
  });
  const cleanedColumns = columns(props.isCollasped).map(({ key, ...clean }) => clean);

  return (
    <BaseTable
      rows={cleanedData}
      columns={cleanedColumns}
      options={options(handleRowClick, handleAddClick)}
      {...rest}
    />
  );
}

CollectionTable.propTypes = {
  collections: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleRowClick: PropTypes.func.isRequired,
};

CollectionTable.defaultProps = {
  collections: [],
};

export default { CollectionTable };
