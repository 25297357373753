import React from 'react';
import PropTypes from 'prop-types';
import { BaseTable, EmptyCategoriesMessage } from '_components';
import options from './options';
import columns from './columns';

export function CategoryTable(props) {
  const { categories, handleRowClick, handleAddClick, ...rest } = props;
  if (!categories || categories.length === 0) return <EmptyCategoriesMessage />;
  const columnKeys = columns.map(col => col.key);
  const cleanedData = categories.map(collection => {
    return columnKeys.map(key => collection[key]);
  });
  const cleanedColumns = columns.map(({ key, ...clean }) => clean);

  return (
    <BaseTable
      title={props.title}
      rows={cleanedData}
      columns={cleanedColumns}
      options={options(handleRowClick, handleAddClick)}
      {...rest}
    />
  );
}

CategoryTable.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
};

CategoryTable.defaultProps = {
  title: null,
  categories: [],
};

export default { CategoryTable };
