import { connect } from 'react-redux';
import { PackTemplateActiveItemContainer } from './container';
import { withActiveItem } from '../../templates';
import {
  attemptUpdatePackTemplate,
  attemptDeletePackTemplate,
} from '_thunks/packTemplates';
import { attemptUpdateFormData } from '../../../store/thunks/views';
import {
  setPanel,
  clearPanel,
  updateSectionView,
  closeSectionForm,
} from '../../../store/actions/sectionView';

const mapDispatchToProps = dispatch => ({
  setPanel: panel => dispatch(setPanel('tileTemplate', panel)),
  createItem: item => dispatch(attemptUpdatePackTemplate(item, true)),
  updateItem: item => dispatch(attemptUpdatePackTemplate(item)),
  deleteItem: item => dispatch(attemptDeletePackTemplate(item)),

  clearPanel: level => {
    dispatch(clearPanel('tileTemplate', level));
  },
  clearForm: () => {
    dispatch(closeSectionForm('tileTemplate'));
  },
  updateSectionView: sectionView =>
    dispatch(updateSectionView('tileTemplate', sectionView)),
  setFormStatus: ({ status }) => {
    return dispatch(
      attemptUpdateFormData('packTemplate', {
        status,
      }),
    );
  },
});

const mapStateToProps = (state, props) => {
  const { match: { params: { id, packTemplateId } } } = props;
  return {
    dispatch: props.dispatch,
    formKey: 'packTemplate',
    addComponent: 'addPackTemplateForm',
    itemCloseUrl: `/tile-templates/${id}`,
    deleteLabel: 'Pack',
    tileTemplateId: id,
    id: packTemplateId,
    ...state.sectionView.tileTemplate,
    formData: state.form.packTemplate.data,
    editData: state.form.packTemplate.data,
    ...(packTemplateId ? state.packTemplates.byId[packTemplateId] : {}),
    tileTemplate: id ? state.tileTemplates.find(x => x.id === id) : {},
    ...state.form.packTemplate,
    isActive: state.sectionView.tileTemplate.active === 'packTemplate',
    method: state.sectionView.tileTemplate.method,
    isAdding: state.sectionView.tileTemplate.method === 'add',
    isEditing: state.sectionView.tileTemplate.method === 'edit',
    newImage: state.editImage.pack,
    showActions:
      !state.sectionView.tileTemplate.active ||
      state.sectionView.tileTemplate.active === 'packTemplate',
  };
};

export const PackTemplateActiveItem = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withActiveItem(PackTemplateActiveItemContainer));

export default { PackTemplateActiveItem };
