import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Avatar } from '_components';

class NavbarUserMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleLogout = event => {
    this.setState({ anchorEl: event.currentTarget }, this.props.logout);
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;

    return (
      <div
        className={'navbar-user-wrapper button btn btn-action btn-st-primary'}
      >
        <div
          aria-owns={anchorEl ? 'navbar-user-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          className={'navbar-user-menu-inner'}
        >
          <Avatar size="md" />
          <div className="user-name is-size-6 has-text-grey">
            {this.props.user && this.props.user.username
              ? `Hi, ${this.props.user.username}`
              : ''}
          </div>
        </div>
        <Menu
          id="navbar-user-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          PopoverClasses={{ paper: 'navbar-user-menu-paper' }}
        >
          {/* <MenuItem onClick={this.handleClose}>Profile</MenuItem>
          <MenuItem onClick={this.handleClose}>My account</MenuItem> */}
          <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
    );
  }
}

export default NavbarUserMenu;
