import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  attemptDeleteCollectionType,
  attemptUpdateCollectionType,
  attemptCreateCollectionType
} from "_thunks/collectionTypes";
import { sectionHOC, Section } from "../../templates/section";
import { attemptUpdateFormData } from "_thunks/views";
import { collectionTypeDefault as constraints } from "_api/constraints";
import { withFieldActions } from "../../form";
import { SummaryRow } from "_components";
import { CollectionTypesTable } from "../table";
import { AddCollectionTypeForm } from "../form";

const mapStateToProps = state => ({
  isDirty: state.form.collectionType.isDirty,
  items: state.collectionTypes,
  isValid: state.form.collectionType.isValid,
  validated: state.form.collectionType.validated,
  formData: state.form.collectionType.data,
  getData: id => state.collectionTypes[id],
  constraints
});

const mapDispatchToProps = dispatch => ({
  updateFormData: data =>
    dispatch(attemptUpdateFormData("collectionType", data)),
  deleteItem: id => dispatch(attemptDeleteCollectionType(id)),
  createItem: item => dispatch(attemptCreateCollectionType(item)),
  updateItem: item => dispatch(attemptUpdateCollectionType(item)),
  resetFormData: () =>
    dispatch(
      attemptUpdateFormData("collectionType", {
        reset: true
      })
    )
});

const itemSelector = data => {
  return {
    title: data.name,
    subtitle: data.text
  };
};

export const CollectionTypesSection = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withFieldActions(
      sectionHOC(Section, {
        itemSelector: itemSelector,
        ItemComponent: SummaryRow,
        TableComponent: CollectionTypesTable,
        AddFormComponent: AddCollectionTypeForm,
        routePrefix: "/collection-types",
        key: "Collection Type",
        dataKey: "collectionTypes",
        formKey: "collectionType",
        // metaActions,
        constraints
      })
    )
  )
);

export default { CollectionTypesSection };
