import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  ImageUploadModal,
  getImageScaledToCanvas,
  updateEditImage,
  attemptGetCategoryImages,
  imageDimensions,
} from 'shared-code';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import OpenInNew from '@material-ui/icons/OpenInNew';
import classNames from 'classnames';
import { selectImageField } from '../../../selectors';

class _ImageField extends PureComponent {
  static propTypes = {
    validation: PropTypes.string,
    showPreview: PropTypes.bool,
  };

  static defaultProps = {
    validation: undefined,
    showPreview: true,
  };

  state = {
    showEditor: false,
  };

  _validated = false;

  _handleOnChange = event => {
    const { id } = this.props;
    const { value } = event.currentTarget;
    this.props.onChange(id, value);
  };

  _handleOnFocus = () => {
    this._validated = false;
  };

  _handleOnBlur = () => {
    const { id, onValidate, value } = this.props;
    if (!onValidate) return;
    onValidate(id, value);
    this._validated = true;
  };

  edit = () => {
    const { categoryId } = this.props;
    if (this.state.showEditor) this.updateCroppedImage();
    else {
      if (categoryId) this.props.dispatch(attemptGetCategoryImages(categoryId));
    }
    this.setState({ showEditor: !this.state.showEditor });
  };

  renderButton = () => {
    return (
      <IconButton
        title="Open Modal"
        className="show-editor-text"
        onClick={this.edit}
      >
        <OpenInNew />
      </IconButton>
    );
  };

  updateCroppedImage = () => {
    const {
      newImage: { canvasImage, scale = 1 },
      dispatch,
      entityKey,
    } = this.props;
    if (!canvasImage || (canvasImage && !canvasImage.resource)) {
      return;
    }
    let croppedImage = null;
    // calculate cropped image

    const canvas = getImageScaledToCanvas({
      ...imageDimensions[entityKey],
      image: canvasImage,
      rotate: 0,
      scale,
    });

    croppedImage = canvas.toDataURL();
    dispatch(updateEditImage(entityKey, { croppedImage }));
  };

  onClose = () => {
    this.updateCroppedImage();
    this.setState({ showEditor: false });
  };

  renderCloseAction = () => {
    return (
      <IconButton
        title="Done"
        className="modal-header-close"
        onClick={this.onClose}
      >
        <DoneIcon />
      </IconButton>
    );
  };

  updateFormData = data => {
    const { updateFormData } = this.props;
    updateFormData({ data });
  };

  render() {
    const {
      images = [],
      newImage,
      dispatch,
      value,
      vertical,
      entityKey,
      ImageCropper,
    } = this.props;

    return (
      <React.Fragment>
        {this.renderButton()}
        <div className={classNames('row image', { vertical })}>
          {this.state.showEditor &&
            <ImageUploadModal
              ImageCropper={ImageCropper}
              onClose={this.onClose}
              images={images}
              newImage={newImage}
              data={value}
              open={true}
              dispatch={dispatch}
              entityKey={entityKey}
              showCheckboxes={false}
              isDirty={newImage.imageHasChanged}
              CloseAction={this.renderCloseAction}
              updateFormData={this.updateFormData}
            />}
        </div>
      </React.Fragment>
    );
  }
}

export const ImageField = connect(selectImageField)(_ImageField);
