import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function Action({ type, icon, onClick, iconSize }) {
  return (
    <button
      className={classNames('button', 'btn', 'btn-action', {
        [`btn-${type}`]: type,
      })}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} size={iconSize} />
    </button>
  );
}

Action.propTypes = {
  iconSize: PropTypes.oneOf([
    'lg',
    'xs',
    'sm',
    '1x',
    '2x',
    '3x',
    '4x',
    '5x',
    '6x',
    '7x',
    '8x',
    '9x',
    '10x',
  ]),
  type: PropTypes.oneOf([
    'primary',
    'default',
    'link',
    'success',
    'error',
    'st-primary',
    'st-outline',
  ]),

  onClick: PropTypes.func.isRequired,
  icon: PropTypes.object.isRequired,
};

Action.defaultProps = {};

export default { Action };
