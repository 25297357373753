import { combineReducers } from "redux";
import { reducer as notifications } from "react-notification-system-redux";
import { connectRouter } from "connected-react-router";

import collections from "./collections";
import collectionTypes from "./collectionTypes";
import user from "./user";
import users from "./users";

import tileTemplates from "./tileTemplates";
import packTemplates from "./packTemplates";
import cardTemplates from "./cardTemplates";
import fieldTemplates from "./fieldTemplates";
import tileTemplatesTable from "./tileTemplatesTable";
import form from "./form";
import batch from "./batch";
import fetchStatus from "./fetchStatus";
import sectionView from "./sectionView";
import { editImage, categories } from "shared-code";

export default history =>
  combineReducers({
    notifications,
    collections,
    packTemplates,
    cardTemplates,
    fieldTemplates,
    tileTemplates,
    categories,
    user,
    users,
    collectionTypes,
    tileTemplatesTable,
    form,
    batch,
    fetchStatus,
    sectionView,
    editImage,
    router: connectRouter(history)
  });
