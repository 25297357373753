import update from "immutability-helper";
import * as R from "ramda";
import {
  SET_TILE_TEMPLATES,
  UPDATE_TILE_TEMPLATE,
  ADD_TILE_TEMPLATE,
  REMOVE_TILE_TEMPLATE
} from "_actions/tileTemplates";
import {
  ADD_TILE_TEMPLATE_PACK_TEMPLATE,
  REMOVE_PACK_TEMPLATE
} from "_actions/packTemplates";

export function addPackTemplate(state, index = -1, packTemplateId) {
  if (index === -1) return state;
  const action = state[index].packTemplates ? "$push" : "$set";
  return update(state, {
    [index]: {
      packTemplates: { [action]: [packTemplateId] }
    }
  });
}

function removePackTemplates(state, index = -1, { packTemplates }) {
  if (index === -1) return state;
  let newState = state;
  packTemplates.forEach(packTemplate => {
    newState = update(state, {
      [index]: {
        packTemplates: {
          $set: state[index].packTemplates.filter(x => x !== packTemplate)
        }
      }
    });
  });
  return newState;
}

export function tileTemplate(state = {}, action) {
  switch (action.type) {
    case ADD_TILE_TEMPLATE:
      return update(state, { $set: action.tileTemplate });
    case UPDATE_TILE_TEMPLATE:
      return update(state, { $merge: action.tileTemplate });
    default:
      return state;
  }
}

export default function tileTemplates(state = [], action) {
  const index = R.findIndex(R.propEq("id", action.id), state);
  const updatedAtIndex = {
    $splice: [[index, 1, tileTemplate(state[index], action)]]
  };

  const removeAtIndex = {
    $splice: [[index, 1]]
  };

  switch (action.type) {
    case SET_TILE_TEMPLATES:
      return update(state, { $set: action.tileTemplates });
    case ADD_TILE_TEMPLATE:
      return update(state, { $push: [tileTemplate(undefined, action)] });
    case UPDATE_TILE_TEMPLATE:
      return update(state, updatedAtIndex);
    case REMOVE_TILE_TEMPLATE:
      return update(state, removeAtIndex);
    case ADD_TILE_TEMPLATE_PACK_TEMPLATE:
      return addPackTemplate(state, index, action.packTemplateId);
    case REMOVE_PACK_TEMPLATE:
      return removePackTemplates(
        state,
        R.findIndex(R.propEq("id", action.tileTemplateId), state),
        {
          packTemplates: [action.id]
        }
      );
    default:
      return state;
  }
}
