import React, { PureComponent } from 'react';
import { Switch, Route } from 'react-router-dom';
import { EditSectionNavigationPrompt, Panel } from '_components';
import { componentMap } from './componentMap';
import { tileTemplateRoutes } from '../../router/routes';

export class TileTemplateSectionContainer extends PureComponent {
  state = {
    height: 400,
  };

  componentDidMount() {
    const { id, setPanel, panels } = this.props;

    if (id && !panels.secondary) {
      setPanel(this.props);
    }

    this.setState({
      height: this.elemHeight < 400 ? 400 : this.elemHeight,
    });
  }

  componentDidUpdate(prevProps) {
    const { id, setPanel, clearItemPanel } = prevProps;

    if (this.props.id !== id && this.addClicked === 'tileTemplate') {
      this.addClicked = null;
      return this.props.confirmAddClick('tileTemplate');
    }
    if (this.props.id && this.props.id !== id) {
      return setPanel(this.props);
    }
    if (!this.props.id && this.props.id !== id) {
      return clearItemPanel();
    }

    this.addClicked = null;
  }

  onHeightChange = newHeight => {
    this.setState({
      height: newHeight < 400 ? 400 : newHeight,
    });
  };

  handleItemClick = (rowData, rowMeta) => {
    let index;
    // table item
    if (rowMeta) {
      index = rowMeta.dataIndex;
    } else {
      // card
      index = rowData.currentTarget.dataset.index;
    }

    const activeItem = this.props.items[index].id;

    this.props.getData(activeItem);

    this.props.history.push(`/tile-templates/${activeItem}`);
  };

  handleAddClick = () => {
    // if (!params.id) {
    //   return this.props.confirmAddClick('tileTemplate');
    // }
    this.props.confirmAddClick('tileTemplate');
    this.props.history.push('/tile-templates');
    this.addClicked = 'tileTemplate';
  };

  getAdditionalProps = ({ type, level }) => {
    switch (type) {
      case 'table':
        return {
          height: this.state.height,
          handleRowClick: this.handleItemClick,
          handleAddClick: this.handleAddClick,
          match: this.props.match,
          history: this.props.history,
        };
      case 'form':
        return {
          isPanel: true,
        };
      default:
        return {};
    }
  };

  renderPanel = panel => {
    const { component: componentName, ...panelProps } = panel;
    const { history } = this.props;

    const PanelComponent = componentMap[componentName];
    const additionalProps = this.getAdditionalProps(panelProps);

    return (
      <PanelComponent {...panelProps} {...additionalProps} history={history} />
    );
  };

  renderForm = () => {
    const { panels, active } = this.props;
    const panel = panels['ternary'];
    if (!panel) return null;

    return (
      <Panel {...panel} hasCollasped={!!active}>
        {this.renderPanel(panel)}
      </Panel>
    );
  };

  render() {
    const { renderPrompt, panels, match, active } = this.props;
    const panel = panels['primary'];

    return (
      <React.Fragment>
        <EditSectionNavigationPrompt
          id="tileTemplate"
          message="Are you sure you want to leave?"
        />
        <div className="section sixtiles-section">
          <div
            className="container"
            id="section-container"
            ref={elem => (this.elemHeight = elem && elem.clientHeight)}
          >
            <div className="sixtiles-panels">
              <Panel key={'primary'} {...panel} hasCollasped={!!active}>
                {this.renderPanel(panel)}
              </Panel>
              <Switch>
                {tileTemplateRoutes(match).map((route, i) =>
                  <Route key={i} exact {...route} />,
                )}
              </Switch>
              {renderPrompt && renderPrompt()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default { TileTemplateSectionContainer };
