import React from 'react';
import PropTypes from 'prop-types';
import { BaseTable } from '_components';
import options from './options';
import columns from './columns';
import { EmptyUsersMessage } from '_components';

export function UserTable(props) {
  const { users, ...rest } = props;
  if (!users || users.length === 0) return <EmptyUsersMessage />;
  const columnKeys = columns.map(col => col.key);
  const cleanedData = users.map(collection => {
    return columnKeys.map(key => collection[key]);
  });
  const cleanedColumns = columns.map(({ key, ...clean }) => clean);

  return (
    <BaseTable
      title={props.title}
      rows={cleanedData}
      columns={cleanedColumns}
      options={options()}
      {...rest}
    />
  );
}

UserTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
};

UserTable.defaultProps = {
  title: null,
  users: [],
};

export default { UserTable };
