import { request } from '_utils/api';

export const getCategories = () => request('get', '/category');

export const getCategory = id => request('get', `/category/${id}`);

export const createCategory = data => {
  const body = {
    ...data,
  };
  return request('post', '/category', { body });
};

export const updateCategory = data =>
  request('put', `/category/${data.id}`, { body: data });

export const deleteCategory = id => request('del', `/category/${id}`);
