import React, { PureComponent } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { attemptUpdateFormData } from '_thunks/views';
import { FormLabel, Action, SelectInput } from '_components';
import { FieldTemplateField } from './fieldTemplate';
import { presetFieldTemplates } from '../../../selectors';
let count = 1;

class _FieldTemplatesField extends PureComponent {
  state = {
    collapsedStatus: this.props.fieldTemplates,
    removedFieldTemplates: [],
    selectedTemplate: null,
  };

  add = () => {
    const { fieldTemplates } = this.props;

    const updateFieldTemplates = [
      ...fieldTemplates,
      {
        id: count++,
        isTemp: true,
        position: fieldTemplates.length + 1,
        fieldType: 'varchar',
      },
    ];

    let collapsedStatus = {};

    fieldTemplates.forEach(element => {
      collapsedStatus[element.id] = true;
    });

    this.setState({ collapsedStatus });

    this.onChange(updateFieldTemplates);
  };

  handleRemove = item => {
    const { fieldTemplates } = this.props;
    const updateFieldTemplates = fieldTemplates.filter(
      template => template.id !== item.id,
    );
    this.onChange(updateFieldTemplates);
  };

  toggleCollapse = ({ id }) => {
    this.setState({
      collapsedStatus: {
        ...this.state.collapsedStatus,
        [id]: !this.state.collapsedStatus[id],
      },
    });
  };

  onChange = fieldTemplates => {
    this.props.onChange('fieldTemplates', fieldTemplates);
  };

  handleMarkAsRemove = item => {
    const isIncluded = this.state.removedFieldTemplates.includes(item.id);
    this.setState(
      {
        removedFieldTemplates: isIncluded
          ? this.state.removedFieldTemplates.filter(x => x !== item.id)
          : this.state.removedFieldTemplates.concat([item.id]),
      },
      this.onChangeCurrent,
    );
  };

  onChangeCurrent = () => {
    this.props.onChange(
      'removedFieldTemplates',
      this.state.removedFieldTemplates,
    );
  };

  getCurrentItemClassName = item => {
    return this.state.removedFieldTemplates.includes(item.id)
      ? 'delete-item'
      : '';
  };

  handleFieldChange = data => {
    const templates = this.props.fieldTemplates.slice();
    const index = templates.findIndex(templates => templates.id === data.id);
    templates[index] = data;

    this.onChange(templates);
  };

  handleSelectTemplate = data => {
    count = 1;
    const hasValue = data && data.value;
    if (!hasValue) {
      return this.setState({ selectedTemplate: null });
    }

    const templateFields = presetFieldTemplates.fields[data.value];
    const fieldTemplates = templateFields.map(templateFields => ({
      ...templateFields,
      id: count++,
      isTemp: true,
    }));

    const collapsedStatus = [];

    fieldTemplates.forEach(({ id }) => {
      collapsedStatus[id] = true;
    });

    this.setState({
      selectedTemplate: data,
      collapsedStatus,
    });
    this.onChange(fieldTemplates);
  };

  renderAllFields = () => {
    const { collapsedStatus } = this.state;

    const { fieldTemplates = [] } = this.props;
    return fieldTemplates.map(data =>
      <FieldTemplateField
        data={data}
        key={data.id}
        isNew={!this.props.fieldTemplateIds.includes[data.id]}
        isRemoved={
          !data.isTemp && this.state.removedFieldTemplates.includes(data.id)
        }
        handleRemove={this.handleRemove}
        handleMarkAsRemove={this.handleMarkAsRemove}
        toggleCollapse={this.toggleCollapse}
        isCollapased={
          !data.isTemp
            ? collapsedStatus[data.id] !== undefined
              ? collapsedStatus[data.id]
              : true
            : !!collapsedStatus[data.id]
        }
        handleSelectChange={this.handleFieldChange}
        handleFieldChange={this.handleFieldChange}
      />,
    );
  };

  render() {
    const { fieldTemplateIds } = this.props;
    const { selectedTemplate } = this.state;
    return (
      <React.Fragment>
        <div
          className={classNames('box-header', { hide: this.state.isCreating })}
        >
          <FormLabel text="Fields" />
        </div>

        {fieldTemplateIds.length === 0
          ? <SelectInput
              className="form-input-select"
              name={'templates'}
              onChange={this.handleSelectTemplate}
              options={presetFieldTemplates.options}
              placeholder="Please select"
              value={selectedTemplate}
            />
          : null}
        <div className="lists">
          {this.renderAllFields()}
        </div>
        <div className="field-templates-field-form-add">
          <Action
            type="st-outline"
            icon={faPlus}
            iconSize="1x"
            onClick={this.add}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToDispatch = dispatch => ({
  resetFormData: (data, props) =>
    dispatch(
      attemptUpdateFormData('fieldTemplate', {
        ...data,
        reset: true,
        activeItem: {
          ...data.activeItem,
          cardTemplateId: props.cardTemplateId,
        },
      }),
    ),
});

const mapStateToProps = (state, props) => {
  return {
    fieldTemplateIds: props.value.fieldTemplateIds,
    fieldTemplates: props.value.fieldTemplates || [],
  };
};

export const FieldTemplatesField = connect(mapStateToProps, mapStateToDispatch)(
  _FieldTemplatesField,
);

export default { FieldTemplatesField };
