import update from 'immutability-helper';

import {
  UPDATE_BATCH_DATA,
  BATCH_JOB_STARTED,
  BATCH_JOB_FINISHED,
  BATCH_JOB_CLEARED,
  UPDATE_BATCH_JOB_PROGRESS,
  UPDATE_BATCH_DUPLICATES,
  UPDATE_BATCH_NOUNS,
} from '_actions/batch';

const common = {
  imgurl: null,
  hexColour: '#d71f30',
  nounHexColour: '#ffffff',
  textHexColour: undefined,
  text: null,
  typeId: null,
  textShow: true,
  nounShow: true,
  active: true,
};

const initialState = {
  common,
  nouns: [],
  displayNouns: [],
  duplicateNouns: [],
  file: null,
  isDirty: true,
  isImporting: false,
  progress: 0,
  total: 0,
  failures: [],
  count: {
    completed: 0,
    success: 0,
    failure: 0,
  },
};

export default function batch(state = initialState, action) {
  switch (action.type) {
    case UPDATE_BATCH_DATA:
      return update(state, {
        [action.key]: {
          $set: action.data,
        },
        isDirty: { $set: true },
      });
    case BATCH_JOB_STARTED:
      return update(state, {
        isImporting: { $set: true },
        total: { $set: state.displayNouns.length },
      });
    case BATCH_JOB_FINISHED: {
      return update(state, {
        isImporting: { $set: false },
      });
    }
    case UPDATE_BATCH_JOB_PROGRESS:
      const count = state.count;
      const completed = count.completed + 1;

      return update(state, {
        failures: { $push: action.key === 'failure' ? [action.noun] : [] },
        progress: {
          $set: Number.parseFloat(completed / state.total * 100).toFixed(),
        },
        count: {
          completed: { $set: completed },
          [action.key]: { $set: count[action.key] + 1 },
        },
      });
    case BATCH_JOB_CLEARED:
      return initialState;
    case UPDATE_BATCH_DUPLICATES:
      return update(state, {
        displayNouns: { $set: action.displayNouns },
        duplicateNouns: { $set: action.duplicateNouns },
      });
    case UPDATE_BATCH_NOUNS:
      return update(state, {
        displayNouns: { $set: action.displayNouns },
        nouns: { $set: action.nouns },
        duplicateNouns: { $set: action.duplicateNouns },
      });
    default:
      return state;
  }
}
