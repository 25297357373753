import { Text, TextInput } from '_components';

export const fields = ({ data, handleFieldChange, handleOnValidate }) => [
  {
    component: Text,
    textComponent: TextInput,
    name: 'Name',
    id: 'name',
    domain: 'collection-type',
    value: data.name,
    onValidate: handleOnValidate,
    onChange: handleFieldChange,
  },
  {
    component: Text,
    name: 'Text',
    id: 'text',
    domain: 'collection-type',
    value: data.text,
    onValidate: handleOnValidate,
    onChange: handleFieldChange,
  },
];

export const formChildren = () => {};
