import React, { Component } from 'react';
import { convertHex } from '../utils';

export class TileContainer extends Component {
  render() {
    const { data = {} } = this.props;

    const style = {
   
    };

    if (data.hexColour) {
      style['--hex-color'] = data.hexColour;
      style['--rgb-color'] = convertHex(data.hexColour);
    }

    const nounStyle = {};

    if (data.nounHexColour) {
      nounStyle['--noun-hex-color'] = data.nounHexColour;
    }

    return (
      <div className={'tile-container'} style={style}>
        <div className={'tile-inner'}>
          {data.imgurlDefault ? (
            <div className="tile-image-container">
              <img className="tile-image" alt="" src={data.imgurlDefault} />
            </div>
          ) : null}
          {data.noun ? (
            <span className="tile-text" style={nounStyle}>
              {data.noun}
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}
