import React, { PureComponent } from 'react';
import validate from 'validate.js';

export const addHOC = (
  WrappedComponent,
  { fields, title, headerProps, constraints, formChildren, ...otherParams },
) => {
  class HOC extends PureComponent {
    title = isEditing => (isEditing ? `Update ${title}` : `Create ${title}`);

    constructor(props) {
      super(props);

      props.resetFormData(
        {
          reset: true,
          status: props.status,
          activeItem: props.activeItem,
        },
        props,
      );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (
        nextProps.id !== this.props.id ||
        this.props.isEditing !== nextProps.isEditing
      ) {
        nextProps.resetFormData(
          {
            reset: true,
            activeItem: nextProps.activeItem,
          },
          nextProps,
        );
      }
    }

    handleFieldChange = (key, value) => {
      const values = {
        data: { ...this.props.data, [key]: value === '' ? null : value },
        isDirty: true,
      };

      this.props.updateFormData(values);
      this.handleOnValidate(key, value, values.data);
    };

    handleSelectChange = (key, value) => {
      const data = { ...this.props.data, [key]: value };
      const values = { data, isDirty: true };

      this.props.updateFormData(values);
      this.handleOnValidate(key, value, data);
    };

    handleColourChange = (key, { hex: value }) => {
      const values = {
        data: { ...this.props.data, [key]: value },
        isDirty: true,
      };
      this.props.updateFormData(values);
    };

    handleAddCloseClick = () => {
      this.props.handleAddCloseClick();
    };

    handleValidateAll = () => {
      const validationAll = validate(this.props.data, constraints);

      const initialValues = {
        validation: {
          ...validationAll,
        },
        isValid: !validationAll,
        validated: true,
      };
      this.props.updateFormData(initialValues);
    };

    handleOnValidate = (key, value, data = this.props.data) => {
      const validationResponse = validate.single(value, constraints[key]);
      const validationAll = validate(data, constraints);

      const values = {
        validation: {
          ...this.props.validation,
          [key]: validationResponse,
        },
        isValid: !validationAll,
      };
      if (this.props.isEditing) {
        values.validated = true;
      }
      this.props.updateFormData(values);
    };

    formGroups = data =>
      fields({
        ...this.props,
        handleFieldChange: this.handleFieldChange,
        handleOnValidate: this.handleOnValidate,
        handleSelectChange: this.handleSelectChange,
        handleColourChange: this.handleColourChange,
      });

    getFormChildren = data =>
      formChildren &&
      formChildren({
        ...this.props,
        handleFieldChange: this.handleFieldChange,
        handleOnValidate: this.handleOnValidate,
        handleSelectChange: this.handleSelectChange,
        handleColourChange: this.handleColourChange,
        ...otherParams,
      });

    render() {
      const { data, isValid, validated } = this.props;

      return (
        <WrappedComponent
          {...this.props}
          handleSaveClick={this.handleSaveClick}
          hasHeader={this.props.isNested}
          data={data}
          handleCloseClick={this.props.handleAddCloseClick}
          isValid={isValid}
          validated={validated}
          formGroups={this.formGroups(data)}
          formChildren={this.getFormChildren(data)}
          title={this.title(this.props.isEditing)}
          handleFieldChange={this.handleFieldChange}
          handleOnValidate={this.handleOnValidate}
          handleValidateAll={this.handleValidateAll}
          headerProps={
            headerProps &&
            headerProps(
              this.props,
              this.handleFieldChange,
              this.handleOnValidate,
            )
          }
        />
      );
    }
  }
  return HOC;
};

export default { addHOC };
