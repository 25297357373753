import React from 'react';
import { Button } from '_components';
import PropTypes from 'prop-types';

export class ConfirmButton extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onOpen: PropTypes.func,
    onCancel: PropTypes.func,
    isFlat: PropTypes.bool,
    icon: PropTypes.object,
    label: PropTypes.string,
    cancelIcon: PropTypes.element,
    cancelMessage: PropTypes.string,
    confirmIcon: PropTypes.element,
    confirmMessage: PropTypes.string,
    confirmClassName: PropTypes.string,
    cancelClassName: PropTypes.string,
    submitClassName: PropTypes.string,
  };

  constructor(props) {
    super(props);
    const {
      onClick,
      onOpen,
      onCancel,
      isOpen,
      icon,
      label,
      cancelIcon,
      cancelMessage,
      confirmIcon,
      confirmMessage,
      confirmClassName,
      cancelClassName,
      submitClassName,
      className,
      ...other
    } = props;
    this.onClick = onClick;
    this.onCancel = onCancel;
    this.onOpen = onOpen;
    this.isOpen = isOpen;
    this.other = other;
    this.icon = icon;
    this.label = label;
    this.cancelIcon = cancelIcon;
    this.confirmIcon = confirmIcon;
    this.cancelClassName = cancelClassName;
    this.confirmClassName = confirmClassName;
    this.submitClassName = submitClassName;
    this.className = className;
    this.confirmMessage =
      !confirmIcon && !confirmMessage ? 'Confirm' : confirmMessage;
    this.cancelMessage =
      !cancelIcon && !cancelMessage ? 'Cancel' : cancelMessage;
    this.state = { isOpen };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ isOpen: props.isOpen });
  }

  handleOpen = () => {
    if (this.onOpen) {
      this.onOpen();
    } else {
      this.setState({ isOpen: true });
    }
  };

  handleCancel = () => {
    if (this.onCancel) {
      this.onCancel();
    } else {
      this.setState({ isOpen: false });
    }
  };

  handleSubmit = () => {
    this.onSubmit();
    this.setState({ isOpen: false });
  };

  render() {
    return (
      <div className="confirm-button">
        {this.state.isOpen ? (
          <div className="confirm-button open">
            <Button
              label={this.cancelMessage}
              onClick={this.handleCancel}
              className={`confirm-cancel ${this.cancelClassName}`}
            />
            <Button
              label={this.confirmMessage}
              onClick={this.onClick}
              className={`confirm-cancel ${this.confirmClassName}`}
            />
          </div>
        ) : (
          <Button
            icon={this.icon}
            label={this.label}
            onClick={this.handleOpen}
            className={`confirm-cancel ${this.className} ${
              this.submitClassName
            }`}
          />
        )}
      </div>
    );
  }
}

export default { ConfirmButton };
