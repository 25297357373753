import React, { PureComponent } from 'react';
import { SelectInput } from '_components';

export class SelectField extends PureComponent {
  handleSelect = (selected = {}) => {
    const { id, onChange } = this.props;
    onChange(id, selected ? selected.value : null);
  };

  render() {
    const { value, values, id } = this.props;

    const options = values.map(v => ({
      value: v,
      label: v,
    }));

    const selected = options.find(option => option.value === value);

    return (
      <SelectInput
        key={1}
        className="form-input-select"
        name={id}
        onChange={this.handleSelect}
        options={options}
        placeholder="Please select"
        value={selected}
      />
    );
  }
}
export default { SelectField };
