import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { SummaryRow, CardTemplates } from '_components';
import { Pack } from 'shared-code';

export class PackTemplateActiveItemContainer extends PureComponent {
  renderImage = () => {
    const {
      newImage,
      imgurl,
      tileTemplate,
      id,
      imgurlCustom,
      name,
    } = this.props;

    return (
      <Pack
        imgurl={newImage.croppedImage || imgurlCustom || imgurl}
        id={id}
        isActive
        name={name}
        bgColor={tileTemplate.hexColour}
        nounColor={tileTemplate.nounHexColour}
      />
    );
  };
  render() {
    const {
      name,
      imgurl,
      newImage,
      imgurlCustom,
      isActive,
      cardTemplates,
      actions,
      itemSelector,
      renderPrompt,
      isAdding,
    } = this.props;

    return (
      <div className={classNames('pack-template', { active: isActive })}>
        {renderPrompt && renderPrompt()}
        <SummaryRow
          {...this.props}
          data={{
            name,
            imgurl,
            imgurlCustom,
            croppedImage: newImage.croppedImage,
            cardTemplates,
          }}
          dataKey="packTemplates"
          formKey="packTemplate"
          itemSelector={itemSelector}
          actions={actions}
          iconOnly={true}
          isValid={true}
          formTitle={'Pack'}
          renderImage={this.renderImage}
        />
        {!(isAdding && isActive) &&
          <CardTemplates
            {...this.props}
            tileTemplateName={'a name'}
            dataKey="cardTemplates"
            itemSelector={itemSelector}
          />}
      </div>
    );
  }
}
