import validate from 'validate.js';
import { hexColorFormat } from '_utils/validation';

export const tileTemplateDefault = {
  hexColour: {
    presence: false,
    format: hexColorFormat,
    length: {
      maximum: 7,
    },
  },

  active: validate.isBoolean,
  collectionId: {
    presence: true,
    length: {
      maximum: 36,
    },
  },
  noun: {
    presence: true,
    length: {
      maximum: 45,
    },
  },
  nounShow: validate.isBoolean,
  nounHexColour: {
    presence: false,
    format: hexColorFormat,
    length: {
      maximum: 7,
    },
  },
  textShow: validate.isBoolean,
  textHexColour: {
    presence: false,
    format: hexColorFormat,
    length: {
      maximum: 7,
    },
  },
  s3Url: validate.isBoolean,
  imgurlDefault: {
    presence: false,
    url: true,
    length: {
      maximum: 255,
    },
  },
  apiUrl: {
    presence: false,
    length: {
      maximum: 45,
    },
  },
  imgurlAlternate: {
    presence: false,
    url: true,
    length: {
      maximum: 255,
    },
  },
  text: {
    presence: false,
    length: {
      maximum: 255,
    },
  },
  mouseoverText: {
    presence: false,
    length: {
      maximum: 255,
    },
  },
  categoryId: {
    presence: false,
    length: {
      maximum: 36,
    },
  },
  levelSchemaId: {
    length: {
      maximum: 36,
    },
  },
  levelAssetBezel: validate.isNumber,
};

export const tileTemplateConstraints = {
  POST: {
    ...tileTemplateDefault,
  },
  PUT: {
    ...tileTemplateDefault,
    id: {
      presence: true,
      length: {
        maximum: 36,
      },
    },
  },
};

export default {
  tileTemplateConstraints,
  tileTemplateDefault,
};
