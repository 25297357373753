import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import R from '_utils/ramda';
import { AdminsPage } from './adminsPage';

export class AdminsPageContainer extends Component {
  static propTypes = {
    // getCollections: PropTypes.func.isRequired,
  };

  state = {
    loading: {
      // collections: false, // true
    },
  };

  componentDidMount() {
    // const { getCollections } = this.props;
    // getCollections().then(() =>
    //   this.setState({
    //     loading: { ...this.state.loading, collections: false },
    //   }),
    // );
  }

  render() {
    return (
      Object.values(this.state.loading).every(item => item === false) && (
        <AdminsPage />
      )
    );
  }
}

export default { AdminsPageContainer };
