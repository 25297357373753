import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";

import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";

const styles = {
  root: {
    color: green[600]
  },
  unCheckedRoot: {
    color: red[600]
  },
  unChecked: {
    color: red[600]
  }
};

class _Radio extends React.Component {
  render() {
    if (this.props.disabled) {
      return (
        <div className={classNames("radio-disabled-container")}>
          <div
            className={classNames(
              `radio-disabled ${this.props.checked ? "checked" : ""}`
            )}
            checked={this.props.checked}
          />
        </div>
      );
    }
    return (
      <div
        onClick={this.onClick}
        className={classNames("radio-container", this.props.className)}
      >
        <RadioButtonCheckedIcon
          checked={this.props.checked}
          disabled={this.props.disabled}
          name="radio-button-active"
          onClick={this.onChange}
          classes={{
            root: !this.props.checked
              ? this.props.classes.unCheckedRoot
              : this.props.classes.root
          }}
        />
      </div>
    );
  }
}

_Radio.propTypes = {
  classes: PropTypes.object.isRequired,
  checked: PropTypes.any.isRequired,
  disabled: PropTypes.bool
};

_Radio.defaultProps = {
  disabled: false
};

export const Radio = withStyles(styles)(_Radio);
