import React from 'react';
import PropTypes from 'prop-types';
import { BaseTable, EmptyCollectionTypesMessage } from '_components';
import options from './options';
import columns from './columns';

export function CollectionTypesTable(props) {
  const { collectionTypes, handleRowClick, handleAddClick, ...rest } = props;
  if (!collectionTypes || collectionTypes.length === 0) {
    return <EmptyCollectionTypesMessage />;
  }
  const columnKeys = columns.map(col => col.key);
  const cleanedData = collectionTypes.map(collection => {
    return columnKeys.map(key => collection[key]);
  });
  const cleanedColumns = columns.map(({ key, ...clean }) => clean);

  return (
    <BaseTable
      title={props.title}
      rows={cleanedData}
      columns={cleanedColumns}
      options={options(handleRowClick, handleAddClick)}
      {...rest}
    />
  );
}

CollectionTypesTable.propTypes = {
  collectionTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
};

CollectionTypesTable.defaultProps = {
  title: null,
  collectionTypes: [],
};

export default { CollectionTypesTable };
