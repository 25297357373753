import { request } from "_utils/api";

export const getTileTemplates = () => request("get", "/tileTemplate");

export const getTileTemplate = id => request("get", `/tileTemplate/${id}`);

export const createTileTemplate = data => {
  return request("post", "/tileTemplate", { body: data });
};

export const updateTileTemplate = (selected = {}) => {
  if (!selected.id) return Promise.reject({ data: { message: "missing ids" } });

  return request("put", `/tileTemplate/${selected.id}`, { body: selected });
};

export const deleteTileTemplate = id => request("del", `/tileTemplate/${id}`);
