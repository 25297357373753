import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UsersPage } from './usersPage';
import LinearProgress from '@material-ui/core/LinearProgress';

export class UsersPageContainer extends Component {
  static propTypes = {
    getUsers: PropTypes.func.isRequired,
  };

  state = {
    loading: {
      users: true,
    },
  };

  componentDidMount() {
    const { getUsers } = this.props;
    getUsers()
      .then(() =>
        this.setState({
          loading: { ...this.state.loading, users: false },
        }),
      )
      .catch(() => {
        this.setState({
          loading: { ...this.state.loading, users: false },
        });
      });
  }

  render() {
    return Object.values(this.state.loading).every(item => item === false) ? (
      <UsersPage />
    ) : (
      <LinearProgress />
    );
  }
}

export default { UsersPageContainer };
