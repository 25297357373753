import { Text, ImageField, TextInput, FieldTemplatesField } from '_components';
import { CardImageCropper } from 'shared-code';

export const fields = ({
  validation,
  data,
  validated,
  handleFieldChange,
  handleOnValidate,
  handleSelectChange,
  handleColourChange,
  hideCollection,
  isNested,
  handleFieldTemplatesChange,
  tileTemplateId,
}) => [
  {
    component: Text,
    textComponent: TextInput,
    name: 'Name',
    id: 'name',
    value: data.name,
    onChange: handleFieldChange,
    onValidate: handleOnValidate,
    validation: validation.name,
    placeholder: 'Enter name',
    validated: validated,
  },
  {
    component: Text,
    textComponent: TextInput,
    name: 'Step',
    id: 'step',
    value: data.step,
    onChange: handleFieldChange,
    onValidate: handleOnValidate,
    validation: validation.step,
    placeholder: 'Enter step',
    validated: validated,
  },
  {
    type: 'row',
    id: 'imageRow',
    formGroups: [
      {
        type: 'column',
        component: ImageField,
        name: 'Image',
        id: 'imgurl',
        showPreview: false,
        value: { tileTemplateId, ...data },
        onChange: handleFieldChange,
        width: !isNested ? 6 : 12,
        vertical: !isNested,
        entityKey: 'card',
        ImageCropper: CardImageCropper,
      },
    ],
  },
  {
    component: Text,
    textComponent: TextInput,
    name: 'Copyright',
    id: 'copyright',
    value: data.copyright,
    onChange: handleFieldChange,
    onValidate: handleOnValidate,
    validation: validation.copyright,
    placeholder: 'Enter copyright',
    validated: validated,
  },
  {
    component: Text,
    textComponent: TextInput,
    name: 'Sequence',
    id: 'sequence',
    value: data.sequence,
    onChange: handleFieldChange,
    onValidate: handleOnValidate,
    validation: validation.sequence,
    placeholder: 'Enter sequence',
    validated: validated,
    inputType: 'number',
    min: 1,
    step: 1,
  },
  {
    component: Text,
    textComponent: TextInput,
    name: 'Priorty',
    id: 'priority',
    value: data.priority,
    onChange: handleFieldChange,
    onValidate: handleOnValidate,
    validation: validation.priority,
    placeholder: 'Enter priority',
    validated: validated,
    inputType: 'number',
    min: 1,
    max: 3,
    step: 1,
  },

  {
    component: FieldTemplatesField,
    id: 'fields',
    value: {
      fieldTemplateIds: data.fieldTemplateIds || [],
      fieldTemplates: data.fieldTemplates || [],
      parentId: data.id,
    },
    onChange: handleFieldTemplatesChange,
    onValidate: handleOnValidate,
    validation: validation.fields || {},
    validated: validated,
  },
];
