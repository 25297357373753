import { connect } from "react-redux";
import { AddFieldTemplateInlineFormContainer } from "./container";
import { attemptUpdateFormData } from "_thunks/views";
import { fields } from "../fields";
import { fieldTemplateDefault as constraints } from "_api/constraints";

const mapStateToProps = (state, props) => {
  return {
    activeItem: state.fieldTemplates.byId[props.id],
    fields,
    title: "Fields",
    constraints,
    hasHeader: false
  };
};

const mapStateToDispatch = dispatch => ({
  updateFormData: data =>
    dispatch(attemptUpdateFormData("fieldTemplate", data)),
  handleFieldTemplatesChange: (key, fieldTemplates) =>
    dispatch(
      attemptUpdateFormData("fieldTemplate", {
        children: true,
        [key]: fieldTemplates
      })
    ),
  resetFormData: (data, props) =>
    dispatch(
      attemptUpdateFormData("fieldTemplate", {
        ...data,
        activeItem: {
          fieldTemplateId: props.fieldTemplateId,
          ...data.activeItem
        }
      })
    )
});

export const AddFieldTemplateInlineForm = connect(
  mapStateToProps,
  mapStateToDispatch
)(AddFieldTemplateInlineFormContainer);
export default { AddFieldTemplateInlineForm };
