export const presetFieldTemplates = {
  options: [
    { value: 'winCount', label: 'Win Count' },
    { value: 'match', label: 'Match' },
    { value: 'activity', label: 'Activity' },
    { value: 'achievement', label: 'Achievement' },
    { value: 'test', label: 'Test' },
  ],
  fields: {
    test: [{}],
    winCount: [
      {
        editable: true,
        name: 'Wins',
        fieldType: 'int',
        minValue: null,
        maxValue: null,
        position: 1,
        defaultValue: 1,
      },
    ],
    match: [
      {
        editable: true,
        defaultValue: null,
        name: 'Competition',
        fieldType: 'varchar',
        minValue: null,
        maxValue: null,
        position: 1,
      },
      {
        editable: true,
        defaultValue: null,
        name: 'Date',
        fieldType: 'date',
        minValue: null,
        maxValue: null,
        position: 2,
      },
      {
        editable: true,
        defaultValue: null,
        position: 3,
        name: 'Time',
        fieldType: 'time',
        minValue: null,
        maxValue: null,
      },
      {
        editable: true,
        defaultValue: null,
        name: 'Location',
        fieldType: 'location',
        minValue: null,
        maxValue: null,
        position: 4,
      },
      {
        editable: false,
        defaultValue: null,
        name: 'Score/Result',
        fieldType: 'varchar',
        minValue: null,
        maxValue: null,
        position: 5,
      },
      {
        editable: false,
        defaultValue: null,
        name: 'Watched/Attended',
        fieldType: 'dropdown',
        options: 'watched, attended',
        minValue: null,
        maxValue: null,
        position: 6,
      },
    ],
    activity: [
      {
        editable: false,
        name: 'Interested/Done',
        defaultValue: 'Interested',
        options: 'Interested,Done',
        fieldType: 'dropdown',
        minValue: null,
        maxValue: null,
        position: 1,
      },
      {
        editable: true,
        defaultValue: null,
        name: 'Description',
        fieldType: 'varchar',
        minValue: null,
        maxValue: null,
        position: 2,
      },
    ],
    achievement: [
      {
        editable: false,
        defaultValue: null,
        name: 'Interested/Done',
        fieldType: 'dropdown',
        options: 'Interested,Done',
        minValue: null,
        maxValue: null,
        position: 1,
      },
      {
        editable: false,
        defaultValue: null,
        name: 'Challenge Text',
        fieldType: 'varchar',
        minValue: null,
        maxValue: null,
        position: 2,
      },
      {
        editable: true,
        defaultValue: null,
        name: 'Comment',
        fieldType: 'comment',
        minValue: null,
        maxValue: null,
        position: 3,
      },
    ],
  },
};

export default { presetFieldTemplates };
