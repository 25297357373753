import { connect } from "react-redux";
import { attemptCreateTileTemplate } from "_thunks/tileTemplates";
import { CollectionItemContainer } from "./container";
import { attemptUpdateFormData } from "../../../store/thunks/views";

const mapDispatchToProps = dispatch => ({
  createChildItem: id => dispatch(attemptCreateTileTemplate(id)),
  handleTileTemplatesChange: (key, tileTemplates) =>
    dispatch(
      attemptUpdateFormData("collection", {
        children: true,
        [key]: tileTemplates
      })
    ),
  resetFormData: (data, props) =>
    dispatch(
      attemptUpdateFormData("tileTemplate", {
        ...data,
        activeItem: { collectionId: props.collectionId, ...data.activeItem }
      })
    )
});

const mapStateToProps = (state, props) => ({
  editData: state.form.collection.data,
  tileTemplates:
    (state.collections.find(x => x.id === props.id) &&
      state.collections.find(x => x.id === props.id).tileTemplates) ||
    []
});

export const CollectionItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectionItemContainer);

export default { CollectionItem };
