import R from '_utils/ramda';

/**
 * String helper functions go here.
 */

// export const capitalize = string =>
//   R.concat(R.toUpper(R.head(string)), R.tail(string));

export const capitalize = R.converge(R.concat(), [
  R.compose(
    R.toUpper,
    R.head,
  ),
  R.tail,
]);

export const titleize = R.compose(
  R.join(' '),
  R.map(capitalize),
  R.split(' '),
);

export const placeholder = () => {};
