import { connect } from 'react-redux';
import { CardTemplateItemContainer } from './container';
import { attemptUpdateFormData } from '_thunks/views';
import {
  attemptCreateCardTemplate,
  attemptEditCardTemplate,
} from '_thunks/cardTemplates';

const mapDispatchToProps = dispatch => ({
  createChildItem: id => dispatch(attemptCreateCardTemplate(id)),
  editChildItem: id => dispatch(attemptEditCardTemplate(id)),
  resetCardTemplateFormData: (data, props) =>
    dispatch(
      attemptUpdateFormData('cardTemplate', {
        ...data,
        activeItem: {
          packTemplateId: props.id,
          tileTemplateId: props.tileTemplateId,
          ...data.activeItem,
        },
      }),
    ),
});

function mapStateToProps(state, props) {
  const data = state.cardTemplates.byId[props.id] || {};
  return data;
}

export const CardTemplateItem = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CardTemplateItemContainer);

export default { CardTemplateItem };
