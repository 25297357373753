import React from 'react';
import { Radio } from '_components';

export default [
  {
    name: 'ID',
    key: 'id',
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: 'Name',
    key: 'name',
    options: {
      display: true,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'Show',
    key: 'show',
    options: {
      display: true,
      filter: true,
      sort: true,
      customBodyRender: (value, meta, updateValue) => {
        return <Radio checked={value} disabled name="radio-button-active" />;
      },
      setCellProps: (cellValue, rowIndex, columnIndex) => ({
        className: 'cell-radio',
      }),
    },
  },
];
