import React from 'react';
import { ListItem } from './item';

function renderObjectEntries(items, props) {
  const elements = [];
  for (const id in items) {
    let item = items[id];

    elements.push(<ListItem item={item} key={id} {...props} />);
  }
  return elements;
}

function renderArrayItems(items, props) {
  return items.map((item, i) => <ListItem item={item} key={i} {...props} />);
}

export function List(props) {
  const { items, ...otherProps } = props;
  return (
    <ul className="list">
      {typeof items === Array
        ? renderArrayItems(items, otherProps)
        : renderObjectEntries(items, otherProps)}
    </ul>
  );
}

export default { List };
