import { request } from '_utils/api';

export const getCollectionTypes = () => request('get', '/collectionType');

export const getCollectionType = id => request('get', `/collectionType/${id}`);

export const createCollectionType = data => {
  return request('post', '/collectionType', { body: data });
};

export const updateCollectionType = collectionType => {
  const body = collectionType;
  return request('put', `/collectionType/${collectionType.id}`, { body });
};

export const deleteCollectionType = id =>
  request('del', `/collectionType/${id}`);
