import { connect } from 'react-redux';
import { addHOC } from '../../form';
import { attemptUpdateFormData } from '_thunks/views';
import { fields, formChildren } from './fields';
import { AddCategoryFormContainer } from './addContainer';
import { categoryDefault as constraints } from '_api/constraints';

const mapStateToProps = (state, props) => {
  return {
    ...state.form.category,
    activeItem: state.categories.find(x => x.id === props.id),
  };
};

const mapStateToDispatch = dispatch => ({
  updateFormData: data => dispatch(attemptUpdateFormData('category', data)),
  resetFormData: data => dispatch(attemptUpdateFormData('category', data)),
});

export const AddCategoryForm = connect(
  mapStateToProps,
  mapStateToDispatch,
)(
  addHOC(AddCategoryFormContainer, {
    fields,
    formChildren,
    title: 'Category',
    constraints,
  }),
);

export default { AddCategoryForm };
