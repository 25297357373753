import { connect } from 'react-redux';
import { attemptGetTileTemplates } from '_thunks/tileTemplates';
import { attemptGetCollections } from '_thunks/collections';
import { attemptGetCategories } from '_thunks/categories';
import { TileTemplatesPageContainer } from './tileTemplatesPageContainer';

const mapDispatchToProps = dispatch => ({
  getTileTemplates: () => dispatch(attemptGetTileTemplates()),
  getCollections: () => dispatch(attemptGetCollections()),
  getCategories: () => dispatch(attemptGetCategories()),
});

export const TileTemplatesPage = connect(null, mapDispatchToProps)(
  TileTemplatesPageContainer,
);

export default { TileTemplatesPage };
export * from './tileTemplatesPage';
