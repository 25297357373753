import React from "react";
import { Toolbar } from "_components";
import { toolbarActions, selectToolbarActions } from "./actions";

function renderCustomToolbar(handleAddClick, handleBatchAddClick) {
  return (
    <Toolbar actions={toolbarActions(handleAddClick, handleBatchAddClick)} />
  );
}

function renderCustomToolbarSelect(
  props,
  handleClearAllSelectedClick,
  handleActivateClick
) {
  return (
    <Toolbar
      actions={selectToolbarActions(
        handleActivateClick,
        handleClearAllSelectedClick,
        props
      )}
    />
  );
}

export default (
  hasSelectableRows,
  handleAddClick,
  handleActivateClick,
  handleClearAllSelectedClick,
  onCellClick,
  handleTableStateChange,
  tableState,
  handleBatchAddClick
) => ({
  ...tableState,
  filterList: undefined,
  sortDirection: tableState.sortDirection,
  responsive: "scrollMaxHeight", // Enable/disable responsive table views. Options: 'stacked', 'scroll'
  rowsPerPage: tableState.rowsPerPage,
  rowsSelected: tableState.rowsSelected, // selected rows
  pagination: true,
  selectableRows: hasSelectableRows,
  resizableColumns: false,
  expandableRows: false,
  customToolbar: () => {
    return renderCustomToolbar(handleAddClick, handleBatchAddClick);
  },
  customToolbarSelect: (...props) => {
    return renderCustomToolbarSelect(
      props,
      handleClearAllSelectedClick,
      handleActivateClick
    );
  },
  jsonMode: true,
  caseSensitive: false, // search case sensitive
  rowHover: false,
  sortFilterList: true, // enable disable sorting of filter lists
  sort: true, // enable/disable sort on all columns
  filter: false, // Show/hide filter icon from toolbar
  search: true, // Show/hide search icon from toolbar (we use custom one in navbar)
  print: false, // Show/hide print	icon from toolbar
  download: false, // Show/hide download icon from toolbar
  viewColumns: false, //	Show/hide viewColumns icon from toolbar
  onTableChange: handleTableStateChange,
  onCellClick: onCellClick
});
