import React from 'react';
import { TileTemplatesSection, JobProgressBar } from '_components';
// import { _ImageUpload, PackImageCropper } from "shared-code";

export function TileTemplatesPageInner({ id, match, history }) {
  return (
    <div className="page-wrapper tiles">
      <JobProgressBar />
      <div className="container grid-lg" id="page-container">
        <div className="columns col-gapless">
          <TileTemplatesSection
            {...(match && match.params) || {}}
            history={history}
          />
        </div>
      </div>
    </div>
  );
}

export default { TileTemplatesPageInner };
