import React, { PureComponent } from 'react';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { attemptUpdateFormData } from '_thunks/views';
import {
  FormLabel,
  Action,
  Popout,
  AddTileTemplateForm,
  List,
} from '_components';

class _TileTemplatesField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      addedTileTemplates: [],
      removedTileTemplates: [],
    };
  }

  toggleCreating = () => {
    if (!this.state.isCreating) {
      this.props.resetFormData(
        {
          reset: true,
          activeItem: {},
        },
        { collectionId: this.props.parentId || 'temp' },
      );
    }
    this.setState({
      isCreating: !this.state.isCreating,
    });
  };

  handleAdd = tileTemplate => {
    this.setState(
      {
        isCreating: false,
        addedTileTemplates: [
          ...this.state.addedTileTemplates,
          {
            tempId: this.state.addedTileTemplates.length,
            isTemp: true,
            ...tileTemplate,
          },
        ],
      },
      this.onChange,
    );
  };

  handleRemove = item => {
    this.setState(
      {
        addedTileTemplates: this.state.addedTileTemplates.filter(
          template => template.tempId !== item.tempId,
        ),
      },
      this.onChange,
    );
  };

  onChange = () => {
    this.props.onChange('addedTileTemplates', this.state.addedTileTemplates);
  };

  handleMarkAsRemove = item => {
    const isIncluded = this.state.removedTileTemplates.includes(item.id);
    this.setState(
      {
        removedTileTemplates: isIncluded
          ? this.state.removedTileTemplates.filter(x => x !== item.id)
          : this.state.removedTileTemplates.concat([item.id]),
      },
      this.onChangeCurrent,
    );
  };

  onChangeCurrent = () => {
    this.props.onChange(
      'removedTileTemplates',
      this.state.removedTileTemplates,
    );
  };

  getCurrentItemClassName = item => {
    return this.state.removedTileTemplates.includes(item.id)
      ? 'delete-item'
      : '';
  };

  render() {
    return (
      <React.Fragment>
        <div
          className={classNames('box-header', { hide: this.state.isCreating })}
        >
          <FormLabel text="Tile Templates" />
          <Action
            type="st-outline"
            icon={faPlus}
            iconSize="1x"
            onClick={this.toggleCreating}
          />
          <Popout show={this.state.isCreating}>
            <AddTileTemplateForm
              isNested
              collectionId={this.props.parentId || 'temp'}
              hideCollection={!this.props.parentId}
              handleAddSaveClick={this.handleAdd}
              saveLabel="Add"
              onCancel={this.toggleCreating}
            />
          </Popout>
        </div>
        <div className="lists">
          <List
            items={this.state.addedTileTemplates}
            itemClassName={'list-new-tile-templates'}
            nameAccessor="noun"
            idAccessor="tempId"
            onItemRemove={this.handleRemove}
          />
          <List
            items={this.props.currentTileTemplates}
            itemClassName={'list-current-tile-templates'}
            getItemClassName={this.getCurrentItemClassName}
            nameAccessor="noun"
            idAccessor="id"
            removeIcon={faTrash}
            onItemRemove={this.handleMarkAsRemove}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToDispatch = dispatch => ({
  resetFormData: (data, props) =>
    dispatch(
      attemptUpdateFormData('tileTemplate', {
        ...data,
        reset: true,
        activeItem: { ...data.activeItem, collectionId: props.collectionId },
      }),
    ),
});

export const TileTemplatesField = connect(
  null,
  mapStateToDispatch,
)(_TileTemplatesField);

export default { TileTemplatesField };
