import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '_components';

export function Prompt({
  show,
  title,
  content,
  onCancel,
  onOk,
  cancelText,
  okText,
}) {
  return (
    <div className={classNames('prompt', { 'is-visible': show })}>
      <div className="prompt-content">
        <div className="prompt-title">{title}</div>
        <div className="prompt-message">{content}</div>
        <div className="prompt-actions">
          {onCancel && (
            <Button
              onClick={onCancel}
              className="btn-transparent secondary"
              label={cancelText}
            />
          )}
          <Button onClick={onOk} className="btn-transparent" label={okText} />
        </div>
      </div>
    </div>
  );
}

Prompt.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onOk: PropTypes.func.isRequired,
  cancelText: PropTypes.string,
  okText: PropTypes.string,
};

Prompt.defaultProps = {
  show: false,
  onCancel: undefined,
  cancelText: 'Cancel',
  okText: 'Ok',
};

export default { Prompt };
