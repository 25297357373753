import { connect } from 'react-redux';
import { UserTable as _UserTable } from './table';

const mapStateToProps = (state, props) => {
  const { selectorKey } = props;
  switch (selectorKey) {
    default:
      return { users: state.users };
  }
};

export const UserTable = connect(mapStateToProps)(_UserTable);

export default { UserTable };
