import { connect } from "react-redux";
import { addHOC } from "../../form";
import { AddPackTemplateFormContainer } from "./addContainer";
import { attemptUpdateFormData } from "_thunks/views";
import { fields } from "./fields";
import { packTemplateDefault as constraints } from "_api/constraints";
import { resetEditImage } from "shared-code";

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { id, packTemplateId }
    }
  } = props;

  return {
    tileTemplateId: id,
    id: packTemplateId,
    packTemplates: state.packTemplates.all,
    ...state.form.packTemplate,
    activeItem: packTemplateId ? state.packTemplates.byId[packTemplateId] : {}
  };
};

const mapStateToDispatch = dispatch => ({
  updateFormData: data => dispatch(attemptUpdateFormData("packTemplate", data)),

  resetFormData: (data, props) => {
    dispatch(resetEditImage("pack"));

    dispatch(
      attemptUpdateFormData("packTemplate", {
        ...data,
        activeItem: {
          packTemplateId: props.packTemplateId,
          tileTemplateId: props.tileTemplateId,
          ...data.activeItem
        }
      })
    );
  }
});

export const AddPackTemplateForm = connect(
  mapStateToProps,
  mapStateToDispatch
)(
  addHOC(AddPackTemplateFormContainer, {
    fields,
    title: "Pack",
    constraints
  })
);
export default { AddPackTemplateForm };
