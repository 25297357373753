import React, { Component } from "react";

export default class Container extends Component {
  render() {
    const { percent } = this.props;
    return (
      <div className="progress-bar-container">
        <div className="progress-bar">
          <div className="completed-fill">
            <div className="progress-fill" style={{ width: `${percent}%` }} />
            <div className="percentage-text">{`${percent}% Complete`}</div>
          </div>
        </div>
      </div>
    );
  }
}
