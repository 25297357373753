import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { InvalidMessagePopover } from '_components';

export function TextArea({
  onChange,
  isValid,
  value,
  validated,
  placeholder,
  id,
  rows,
  onBlur,
  onFocus,
  validation
}) {
  const classes = classNames({
    'form-input text-area': true,
    // 'is-success': validated && value && isValid,
    'is-danger': validated && value && !isValid,
  });

  return (
    <React.Fragment>
    <textarea
      className={classes}
      id={id}
      rows={rows}
      value={value || ''}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
    />
    <InvalidMessagePopover validation={validation} />
    </React.Fragment>
  );
}

TextArea.propTypes = {
  onChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  validated: PropTypes.bool,
};

TextArea.defaultProps = {
  value: '',
  placeholder: '',
  rows: 3,
  onBlur: undefined,
  onFocus: undefined,
  isValid: true,
  validated: false,
};

export default { TextArea };
