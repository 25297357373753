import React, { Component } from 'react';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { PackTemplateItem } from '../item';
import { PackTemplateActiveItem } from '../activeItem';

const NUM_COLUMNS = 4;

export class PackTemplatesContainer extends Component {
  createPackTemplate = () => {
    const { confirmAddClick } = this.props;
    confirmAddClick();
  };

  render() {
    const {
      history,
      packTemplates = [],
      id,
      tileTemplateName,
      isAdding,
      packTemplateId,
    } = this.props;
    const remainder = NUM_COLUMNS - packTemplates.length % NUM_COLUMNS;

    const dummyItems = remainder !== NUM_COLUMNS ? [...Array(remainder)] : [];

    const activePackTemplate = packTemplateId;
    return (
      <React.Fragment>
        {!(activePackTemplate || isAdding) &&
          <div className="pack-templates-header">
            <span className="pack-templates-header-title">Packs</span>
            <div className="pack-templates-actions">
              <Tooltip title={'Create Pack'}>
                <IconButton
                  onClick={this.createPackTemplate}
                  className={'toolbar-selected-button'}
                  disabled={false}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>}
        {(activePackTemplate || isAdding) &&
          <PackTemplateActiveItem
            id={activePackTemplate}
            match={this.props.match}
            isActive
            tileTemplateName={tileTemplateName}
            history={history}
            tileTemplateId={id}
            dispatch={this.props.dispatch}
            onEditClick={this.props.onEditClick}
          />}
        {!(activePackTemplate || isAdding) &&
          <div className={'pack-templates'}>
            {packTemplates &&
              packTemplates.map(x =>
                <PackTemplateItem
                  id={x}
                  key={x}
                  activeId={this.props.match.params.packTemplateId}
                  history={history}
                  tileTemplateId={id}
                  onEditClick={this.props.onEditClick}
                />,
              )}
            {dummyItems.map((_, i) =>
              <div key={i} className="pack-template dummy" />,
            )}
          </div>}
      </React.Fragment>
    );
  }
}
