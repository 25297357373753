// import R from '_utils/ramda';

/**
 * Form validations go here. Example:
 *
 export const validateUsername = username => {
  let valid = true;
  let message = 'Username Valid';

  if (!R.match(/^[a-zA-Z0-9_]+$/, username).length) {
    message = 'Invalid character used';
    valid = false;
  } else if (username.length < 4) {
    message = 'Username must be at least four characters';
    valid = false;
  } else if (username.length > 20) {
    message = 'Username must be 20 characters or less';
    valid = false;
  } else if (R.match(/[a-zA-Z]/g, username).length < 4) {
    message = 'Username must include at least four letters';
    valid = false;
  }
  return { valid, message };
};
 */

const hexColorPattern = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i;
export const hexColorFormat = {
  pattern: hexColorPattern,
  message: 'not a valid Hex Color',
};
// export function isHexColour(value, attributes, attributeName, options, constraints) {
//   if (/(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i).test(attributes.creditCardNumber)) return null;

// }
