import {
  getCollectionTypes,
  deleteCollectionType,
  getCollectionType,
  updateCollectionType as editCollectionType,
  createCollectionType,
} from '_api/collectionTypes';
import {
  setCollectionTypes,
  removeCollectionType,
  updateCollectionType,
  addCollectionType,
} from '_actions/collectionTypes';

import { dispatchError } from '_utils/api';

export const attemptGetCollectionTypes = () => (dispatch, getState) => {
  if (getState().fetchStatus.collectionTypes) return Promise.resolve({});
  return getCollectionTypes()
    .then(data => {
      dispatch(setCollectionTypes(data));
      return data;
    })
    .catch(dispatchError(dispatch));
};

export const attemptGetCollectionType = () => dispatch =>
  getCollectionType()
    .then(data => {
      dispatch(setCollectionTypes([data]));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptCreateCollectionType = data => dispatch =>
  createCollectionType(data)
    .then(data => {
      dispatch(addCollectionType(data));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptDeleteCollectionType = id => dispatch =>
  deleteCollectionType(id)
    .then(data => {
      dispatch(removeCollectionType(id));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptUpdateCollectionType = collectionType => dispatch =>
  editCollectionType(collectionType)
    .then(data => {
      dispatch(updateCollectionType(data));
      return data;
    })
    .catch(dispatchError(dispatch));
