import validate from 'validate.js';

export const fieldTemplateDefault = {
  editable: validate.isBoolean,
  cardTemplateId: {
    presence: true,
    length: {
      maximum: 36,
    },
  },
  imgurl: {
    presence: false,
    url: true,
    length: {
      maximum: 255,
    },
  },
  name: {
    presence: true,
    length: {
      maximum: 255,
    },
  },
};

export const fieldTemplateConstraints = {
  POST: {
    ...fieldTemplateDefault,
  },
  PUT: {
    ...fieldTemplateDefault,
    id: {
      presence: true,
      length: {
        maximum: 36,
      },
    },
  },
};

export default {
  fieldTemplateConstraints,
  fieldTemplateDefault,
};
