import { connect } from 'react-redux';
import { CategoryTable as _CategoryTable } from './table';

const mapStateToProps = (state, props) => {
  const { selectorKey, id } = props;
  switch (selectorKey) {
    case 'COLLECTION':
      const { categories } =
        state.collections.find(item => item.id === id) || {};
      return {
        categories,
      };
    default:
      return { categories: state.categories };
  }
};

export const CategoryTable = connect(mapStateToProps)(_CategoryTable);

export default { CategoryTable };
