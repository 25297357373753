import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Card } from 'shared-code';

class _CardPreview extends PureComponent {
  renderFieldValue = ({ fieldType, name, defaultValue }) => {
    switch (fieldType) {
      case 'dropdown': {
        return defaultValue;
      }
      default:
        return (
          <span className="field-value">
            {defaultValue}
          </span>
        );
    }
  };

  renderField = field => {
    const { fieldType, name, id } = field;
    const { isActive, data: { removedFieldTemplates = [] } } = this.props;

    if (isActive && !field.isTemp && removedFieldTemplates.includes(field.id)) {
      return null;
    }
    let displayText = name;
    if (displayText && fieldType !== 'int') displayText = `${displayText}: `;

    return (
      <div key={id} className={classNames('field', fieldType)}>
        <div className="field-text">
          {name &&
            <span className="field-name">
              {displayText}
            </span>}
          {this.renderFieldValue(field)}
        </div>
      </div>
    );
  };

  render() {
    const { data: { id, priority }, tileTemplate } = this.props;

    return (
      <div className="card-template-preview">
        <Card
          data={this.props.data}
          id={id}
          isPriority={1 === parseInt(priority)}
          bgColor={tileTemplate.hexColour}
          nounColor={tileTemplate.nounHexColour}
        />
      </div>
    );
  }
}

function mapStateToProps(
  state,
  { newImage = {}, isActive, fieldTemplates = [], editData, data },
) {
  const item = isActive ? editData : data;
  return {
    tileTemplate:
      state.tileTemplates.find(x => x.id === item.tileTemplateId) || {},
    data: {
      ...item,
      name: item.name,
      imgurl: newImage.croppedImage || item.imgurlCustom,
      fieldTemplates:
        item.id && !isActive
          ? fieldTemplates.map(id => state.fieldTemplates.byId[id])
          : item.fieldTemplates,
    },
  };
}

export const CardPreview = connect(mapStateToProps)(_CardPreview);
