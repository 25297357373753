import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Action } from "_components";
import { withFieldActions } from "../../form";

function _SummaryRow({
  item,
  data,
  titleSelector,
  subtitleSelector,
  imageSelector,
  actions,
  iconOnly,
  formTitle,
  isValid,
  metaActions,
  handleFieldChange,
  handleOnValidate,
  isEditing,
  isAdding,
  renderImage,
  hasBody,
  isActive
}) {
  return (
    <div className="summary-row">
      <div className="summary-header">
        <div className="title">
          <h1>{formTitle}</h1>
          {metaActions ? (
            <div className="form-header-meta-actions">
              {metaActions.map(
                ({ component: Component, id, name, ...childProps }) => {
                  return (
                    <Component
                      id={id}
                      name={name}
                      value={data[id]}
                      {...childProps}
                      onChange={handleFieldChange}
                      onValidate={handleOnValidate}
                      disabled={!isActive}
                    />
                  );
                }
              )}
            </div>
          ) : null}
        </div>
        <div className="summary-actions">
          {!isValid ? (
            <div className="sixtile-badge invalid">Invalid</div>
          ) : null}
          {actions.map((action, i) =>
            action.withoutSideEffects ? (
              <Action
                key={i}
                type="st-outline"
                icon={action.icon}
                iconSize="1x"
                onClick={action.onClick}
              />
            ) : (
              <Button key={i} iconOnly={iconOnly} {...action} />
            )
          )}
        </div>
      </div>
      {hasBody && (
        <div className="summary-body">
          {renderImage ? (
            renderImage({ data })
          ) : item.image ? (
            <div className="image-container">
              <img className="image-inner" src={item.image} alt="" />
            </div>
          ) : null}
          <div className="text">
            <h5 className="title">{item.title}</h5>
            <h6 className="subtitle">{item.subtitle}</h6>
          </div>
        </div>
      )}
    </div>
  );
}

_SummaryRow.propTypes = {
  data: PropTypes.object,
  hasBody: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.object,
      onClick: PropTypes.func
    })
  )
};

_SummaryRow.defaultProps = {
  title: "",
  options: {},
  hasBody: true
};

function mapStateToProps(state, props) {
  if (props.formKey && (props.isEditing || props.isAdding)) {
    return {
      data: state.form[props.formKey].data,
      item: props.itemSelector(state.form[props.formKey].data)
    };
  }
  if (!props.dataKey) return {};
  if (props.data) {
    return { item: props.itemSelector(props.data) };
  }
  const data = state[props.dataKey].find(x => x.id === props.id) || {};
  return {
    data,
    item: props.itemSelector(
      state[props.dataKey].find(x => x.id === props.id) || {}
    )
  };
}

export const SummaryRow = connect(mapStateToProps)(
  withFieldActions(_SummaryRow, "tileTemplate")
);

export default { SummaryRow };
