import { normalize, denormalize } from "normalizr";
import {
  tileTemplatesSchema,
  tileTemplateSchema,
  tileTemplateBasicSchema,
  packTemplateSchema,
  cardTemplateSchema,
  fieldTemplatesSchema
} from "./schema/tileTemplates";

export const normalizeTileTemplates = data => {
  const normalizedData = normalize(data, tileTemplatesSchema);

  const denormalizedData = denormalize(
    normalizedData.result,
    tileTemplateBasicSchema,
    normalizedData.entities
  );

  normalizedData.entities.tileTemplates = denormalizedData;
  const {
    tileTemplates,
    packTemplates,
    cardTemplates,
    fieldTemplates
  } = normalizedData.entities;
  return { tileTemplates, packTemplates, cardTemplates, fieldTemplates };
};

export const normalizeTileTemplate = data => {
  const normalizedData = normalize(data, tileTemplateSchema);

  const { tileTemplates } = normalizedData.entities;
  const id = normalizedData.result;
  return tileTemplates[id];
};

export const normalizePackTemplate = data => {
  const normalizedData = normalize(data, packTemplateSchema);

  const { packTemplates } = normalizedData.entities;
  const id = normalizedData.result;
  return packTemplates[id];
};

export const normalizeCardTemplate = data => {
  const normalizedData = normalize(data, cardTemplateSchema);

  const { cardTemplates } = normalizedData.entities;
  const id = normalizedData.result;
  return cardTemplates[id];
};

export const normalizeFieldTemplates = data => {
  const normalizedData = normalize(data, fieldTemplatesSchema);

  const { fieldTemplates } = normalizedData.entities;
  const fieldTemplateAll = normalizedData.result;
  return { fieldTemplates, fieldTemplateAll };
};
