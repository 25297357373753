import React, { PureComponent } from 'react';
import classNames from 'classnames';

export class Panel extends PureComponent {
  getCustomClass = () => {
    const { type } = this.props;

    switch (type) {
      case 'table':
        return 'table menu';
      case 'form':
        return 'edit-form';
      default:
        return '';
    }
  };

  getCustomItemClass = () => {
    const { type } = this.props;
    switch (type) {
      case 'form':
      case 'item':
        return 'item-wrapper item-wrapper-panel';
      default:
        return null;
    }
  };

  renderChildren = () => {
    const { children } = this.props;
    return children;
  };

  render() {
    const { domain, hasCollasped } = this.props;

    const customClass = this.getCustomClass();
    return (
      <div
        key={domain}
        className={classNames('section-panel', customClass, {
          collaspe: hasCollasped,
        })}
      >
        <div
          className={classNames(
            'section-panel-inner',
            this.getCustomItemClass(),
          )}
        >
          {this.renderChildren()}
        </div>
      </div>
    );
  }
}

export default { Panel };
