import { request } from "_utils/api";

export const getPackTemplates = tileTemplateId =>
  request("get", `/tileTemplate/${tileTemplateId}/packtemplate`);

export const createPackTemplate = data => {
  const body = {
    ...data
  };
  return request("post", `/tileTemplate/${data.tileTemplateId}/packtemplate`, {
    body
  });
};

export const updatePackTemplate = data => {
  if (!data.tileTemplateId || !data.id)
    return Promise.reject({ data: { message: "missing ids" } });

  return request(
    "put",
    `/tileTemplate/${data.tileTemplateId}/packtemplate/${data.id}`,
    {
      body: data
    }
  );
};

export const deletePackTemplate = ({ tileTemplateId, id }) =>
  request("del", `/tiletemplate/${tileTemplateId}/packtemplate/${id}`);
