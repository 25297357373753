import update from 'immutability-helper';

import {
  UPDATE_FIELD_TEMPLATES,
  ADD_CARD_TEMPLATE_FIELD_TEMPLATES,
} from '_actions/fieldTemplates';
import { SET_TILE_TEMPLATES } from '_actions/tileTemplates';
import { SET_PACK_TEMPLATES } from '_actions/packTemplates';

function updateFieldTemplates(state, action) {
  let newState = state;

  action.fieldTemplateAll.forEach(id => {
    const field = action.fieldTemplates[id];
    newState = update(newState, {
      [id]: { $set: field },
    });
  });
  return newState;
}

function updateIds(state, ids = []) {
  if (ids.length === 0) return state;
  return [...new Set(state.concat(ids))];
}

export default function fieldTemplates(state = { all: [], byId: {} }, action) {
  switch (action.type) {
    case SET_TILE_TEMPLATES:
    case SET_PACK_TEMPLATES:
      return update(state, {
        all: { $set: action.fieldTemplatesAll },
        byId: { $set: action.fieldTemplates || {} },
      });
    case ADD_CARD_TEMPLATE_FIELD_TEMPLATES:
      return update(state, {
        all: { $set: updateIds(state.all, action.fieldTemplateAll) },
        byId: { $set: updateFieldTemplates(state.byId, action) },
      });
    case UPDATE_FIELD_TEMPLATES:
      return update(state, {
        byId: { $set: updateFieldTemplates(state.byId, action) },
      });
    default:
      return state;
  }
}
