import React from 'react';
import { logo } from '_assets';
import { Tabs } from '_components';
import NavbarUserMenu from './userMenu';

const navKeys = ['collections', 'tile-templates', 'users'];
const subNavKeys = ['collection-types', 'categories'];

export function Navbar({ switchRoute, activeRoute, onMoreClick }) {
  return (
    <header className="navbar">
      <section className="navbar-section">
        <div className="container">
          <div className="columns">
            <div className="column col-3">
              <a href="/" className="navbar-brand">
                <img src={logo} alt="sixtiles" />
              </a>
            </div>
            <div className="column col-6">
              <Tabs
                active={activeRoute}
                keys={navKeys}
                onChange={switchRoute}
                onMoreClick={onMoreClick}
                subNavKeys={subNavKeys}
              />
            </div>
            <div className="column col-3 right-align user">
              <NavbarUserMenu />
            </div>
          </div>
        </div>
      </section>
    </header>
  );
}

export default { Navbar };
