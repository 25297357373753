import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { createBrowserHistory } from 'history';
import '_assets/styles/index.scss';
import 'shared-code/lib/assets/styles/index.scss';
import '_utils/polyfill';
import {
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles';
import { Router } from '_routes';
import configureStore from '_store/configureStore';

const ignoreWarningsContaining = /(Route|componentWillReceiveProps|componentWillMount|Invalid|Function components cannot be given refs.|A component is changing a controlled input|A component is changing an uncontrolled input)/;

var _log = console.error;

console.error = function() {
  if (!arguments[0].match(ignoreWarningsContaining)) {
    return _log.apply(console, arguments);
  }
};

_log = console.warn;

console.warn = function() {
  if (!arguments[0].match(ignoreWarningsContaining)) {
    return _log.apply(console, arguments);
  }
};
const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
});

const history = createBrowserHistory();
const store = configureStore(history);

render(
  <Provider store={store}>
    <StylesProvider generateClassName={generateClassName}>
      <Router history={history} />
    </StylesProvider>
  </Provider>,
  document.getElementById('app'),
);
