export const {
  REACT_APP_API_HOST: API_HOST,
  REACT_APP_PUBLIC_API_HOST: PUBLIC_API_HOST,
  REACT_APP_AUTH_USER_POOL_ID: AUTH_USER_POOL_ID,
  REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID: AUTH_USER_POOL_WEB_CLIENT_ID,
} = process.env;

export default {
  API_HOST,
  PUBLIC_API_HOST,
  AUTH_USER_POOL_ID,
  AUTH_USER_POOL_WEB_CLIENT_ID,



};
