import { connect } from 'react-redux';
import { attemptGetCollections } from '_thunks/collections';
import { attemptGetCategories } from '_thunks/categories';
import { CollectionsPageContainer } from './collectionsPageContainer';
import { attemptGetCollectionTypes } from '_thunks/collectionTypes';

// const mapStateToProps = R.pick(['collections']);

const mapDispatchToProps = dispatch => ({
  getCollections: () => dispatch(attemptGetCollections()),
  getCategories: () => dispatch(attemptGetCategories()),
  getCollectionTypes: () => dispatch(attemptGetCollectionTypes()),
});

export const CollectionsPage = connect(
  null,
  mapDispatchToProps,
)(CollectionsPageContainer);

export default { CollectionsPage };
