import validate from 'validate.js';

export const categoryDefault = {
  name: {
    presence: true,
  },
  show: validate.isBoolean,
};

export const categoryConstraints = {
  POST: {
    ...categoryDefault,
  },
  PUT: {
    ...categoryDefault,
    id: {
      presence: true,
    },
  },
};

export default {
  categoryConstraints,
  categoryDefault,
};
