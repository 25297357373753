import { Text, TextInput, BooleanField } from '_components';

export const fields = ({
  validation,
  data,
  validated,
  handleFieldChange,
  handleOnValidate,
  handleSelectChange,
  handleColourChange,
  isNested,
}) => [
  {
    type: 'row',
    id: 'textRow',
    formGroups: [
      {
        type: 'column',
        component: Text,
        textComponent: TextInput,
        name: 'Name',
        id: 'name',
        value: data.name,
        onChange: handleFieldChange,
        placeholder: 'Enter name',
        width: isNested ? 6 : 12,
        metaActions: [
          {
            component: BooleanField,
            id: 'nounShow',
            value: data.nounShow,
            onChange: handleSelectChange,
            onValidate: handleOnValidate,
            validation: validation.nounShow,
          },
        ],
      },
    ],
  },
];

export const formChildren = () => {};
