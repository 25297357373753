import { setTileTemplatesTableState, setFormData } from '../actions/views';
import { setEditImage } from 'shared-code';

export const attemptToSetTileTemplatesTableState = state => dispatch =>
  dispatch(setTileTemplatesTableState(state));

export const attemptUpdateFormData = (key, data) => dispatch => {
  dispatch(setFormData(key, data));
  // try to clear image uploader
  if (data.reset) {
    const imageKey = key.replace('Template', '');
    dispatch(setEditImage(imageKey, (data && data.activeItem) || {}));
  }
};
