import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Button } from '_components';
import { withStyles } from '@material-ui/core/styles';

const defaultToolbarStyles = {};

function _Toolbar({ actions, classes }) {
  return (
    <React.Fragment>
      {actions.map((action, i) =>
        <Tooltip key={i} title={action.title}>
          {action.icon
            ? <IconButton
                onClick={() =>
                  action.onClick ? action.onClick(action.props) : null}
                className={
                  action.disabled
                    ? 'toolbar-selected-button disabled'
                    : 'toolbar-selected-button'
                }
                disabled={action.disabled}
              >
                {React.cloneElement(action.icon, {
                  className: classes.deleteIcon,
                })}
              </IconButton>
            : action.ownComponent
              ? <action._component action={action} classes={classes} />
              : <div
                  style={{
                    justifyContent: 'flex-end',
                    flex: 1,
                    display: 'flex',
                  }}
                >
                  <Button
                    key={i}
                    onClick={() => action.onClick(action.props)}
                    className={
                      action.disabled
                        ? 'toolbar-selected-button disabled'
                        : 'toolbar-selected-button'
                    }
                    label={action.label}
                  />
                </div>}
        </Tooltip>,
      )}
    </React.Fragment>
  );
}

export const Toolbar = withStyles(defaultToolbarStyles, { name: 'Toolbar' })(
  _Toolbar,
);

export default { Toolbar };
