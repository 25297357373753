export default [
  {
    name: 'ID',
    key: 'id',
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: 'Name',
    key: 'name',
  },
  {
    name: 'Text',
    key: 'text',
  },
];
