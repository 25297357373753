import {
  getCollections,
  deleteCollection,
  getCollection,
  updateCollection as editCollection,
  getCollectionTileTemplates,
  createCollection,
} from '_api/collections';
import {
  setCollections,
  removeCollection,
  setCollectionData,
  updateCollection,
  addCollection,
  removeCollectionTileTemplate,
} from '_actions/collections';
import {
  attemptCreateTileTemplate,
  attemptDeleteTileTemplate,
} from '_thunks/tileTemplates';
import { dispatchError, handleErrorRes } from '_utils/api';

export const attemptGetCollections = () => (dispatch, getState) => {
  if (getState().fetchStatus.collections) return Promise.resolve({});
  return getCollections()
    .then(data => {
      dispatch(setCollections(data));
      return data;
    })
    .catch(dispatchError(dispatch));
};

export const attemptGetCollection = () => dispatch =>
  getCollection()
    .then(data => {
      dispatch(setCollections([data]));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptCreateCollection = ({
  addedTileTemplates,
  ...item
}) => dispatch =>
  createCollection(item)
    .then(data => {
      dispatch(addCollection(data));
      return data;
    })
    .then(data => {
      // create tile templates with collection id
      addedTileTemplates.forEach(element => {
        element.collectionId = data.id;
        dispatch(attemptCreateTileTemplate(element));
      });
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptDeleteCollection = id => dispatch =>
  deleteCollection(id)
    .then(data => {
      dispatch(removeCollection(id));
      return data;
    })
    .catch(error => {
      handleErrorRes(dispatch, error);
      return Promise.reject();
    });

export const attemptUpdateCollection = ({
  addedTileTemplates,
  removedTileTemplates,
  ...item
}) => dispatch =>
  editCollection(item)
    .then(data => {
      dispatch(updateCollection(data));
      return data;
    })
    .then(data => {
      // create tile templates with collection id
      addedTileTemplates.forEach(element => {
        dispatch(attemptCreateTileTemplate(element));
      });
      removedTileTemplates.forEach(element => {
        dispatch(attemptDeleteTileTemplate(element)).then(() => {
          dispatch(removeCollectionTileTemplate(data.id, element));
        });
      });
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptGetCollectionData = id => async dispatch => {
  const tileTemplateResponse = await getCollectionTileTemplates(id);
  if (tileTemplateResponse.error) dispatchError(dispatch);
  else {
    dispatch(
      setCollectionData({
        id,
        tileTemplates: tileTemplateResponse,
      }),
    );
  }
};
