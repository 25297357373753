import React from 'react';
import { InvalidMessagePopover } from '_components';


export function FormLabel({ text,  validation, invalidMessageLocation = 'LABEL' }) {
  return  ( 
    <React.Fragment>
      <div className="form-label">{text}</div>
      {invalidMessageLocation === 'LABEL' ?  <InvalidMessagePopover validation={validation} /> : null}
    </React.Fragment>
  )
}

export default { FormLabel };
