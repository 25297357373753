import { hexColorFormat } from '_utils/validation';

export const collectionDefault = {
  name: {
    presence: true,
    length: {
      maximum: 45,
    },
  },
  hexColour: {
    presence: false,
    format: hexColorFormat,
    length: {
      maximum: 7,
    },
  },
  nameHexColour: {
    presence: false,
    format: hexColorFormat,
    length: {
      maximum: 7,
    },
  },
  text: {
    presence: false,
  },
  textHexColour: {
    presence: false,
    format: hexColorFormat,
    length: {
      maximum: 7,
    },
  },
  imgurl: {
    presence: false,
    url: true,
    length: {
      maximum: 255,
    },
  },
  typeId: {
    presence: true,
    length: {
      maximum: 36,
    },
  },
};

export const collectionConstraints = {
  POST: {
    ...collectionDefault,
  },
  PUT: {
    ...collectionDefault,
    id: {
      presence: true,
      length: {
        maximum: 36,
      },
    },
  },
};

export default {
  collectionConstraints,
  collectionDefault,
};
