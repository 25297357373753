import { Text, SelectInput, TextInput } from '_components';
import { fieldTemplateTypes } from '../../selectors';

export const fields = ({
  validation,
  data,
  validated,
  handleFieldChange,
  handleOnValidate,
  handleSelectChange,
  handleColourChange,
  hideCollection,
  isNested,
}) => [
  {
    component: Text,
    textComponent: TextInput,
    name: 'Name',
    id: 'name',
    value: data.name,
    onChange: handleFieldChange,
    onValidate: handleOnValidate,
    validation: validation.name,
    placeholder: 'Enter name',
    validated: validated,
  },
  {
    component: Text,
    textComponent: TextInput,
    name: 'Default Value',
    id: 'defaultValue',
    value: data.defaultValue,
    onChange: handleFieldChange,
    onValidate: handleOnValidate,
    validation: validation.defaultValue,
    placeholder: 'Enter Default Value',
    validated: validated,
  },
  {
    component: Text,
    textComponent: TextInput,
    name: 'Position',
    id: 'position',
    value: data.position,
    onChange: handleFieldChange,
    onValidate: handleOnValidate,
    validation: validation.position,
    placeholder: 'Enter position',
    validated: validated,
    inputType: 'number',
    min: 1,
    step: 1,
  },
  {
    component: SelectInput,
    name: 'Field Type',
    id: 'fieldType',
    value: data.fieldType
      ? { value: data.fieldType, label: data.fieldType }
      : null,
    onChange: handleSelectChange,
    onValidate: handleOnValidate,
    validation: validation.fieldType,
    placeholder: 'Enter Field Type',
    validated: validated,
    options: fieldTemplateTypes.map(type => ({
      value: type,
      label: type,
    })),
  },
  {
    component: Text,
    textComponent: TextInput,
    isHidden: data.fieldType !== 'dropdown',
    name: 'Options',
    id: 'options',
    value: data.options,
    onChange: handleFieldChange,
    onValidate: handleOnValidate,
    validation: validation.options,
    placeholder: 'Enter Options',
    validated: validated,
  },
  {
    component: Text,
    textComponent: TextInput,
    isHidden: data.fieldType !== 'int',
    name: 'Min Value',
    id: 'minValue',
    placeholder: 'Enter Min',
    value: data.minValue,
    onChange: handleFieldChange,
    onValidate: handleOnValidate,
    validation: validation.minValue,
    validated: validated,
    inputType: 'number',
  },
  {
    component: Text,
    textComponent: TextInput,
    isHidden: data.fieldType !== 'int',
    name: 'Max Value',
    id: 'maxValue',
    placeholder: 'Enter Max',
    value: data.maxValue,
    onChange: handleFieldChange,
    onValidate: handleOnValidate,
    validation: validation.maxValue,
    validated: validated,
    inputType: 'number',
  },
];
