import { connect } from 'react-redux';
import R from '_utils/ramda';
import { CollectionTypesTable as _CollectionTypesTable } from './table';

const mapStateToProps = R.pick(['collectionTypes']);

export const CollectionTypesTable = connect(mapStateToProps)(
  _CollectionTypesTable,
);

export default { CollectionTypesTable };
