import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { SelectInput } from '_components';

class _Collection extends PureComponent {
  _validated = false;
  handleSelect = (selected = {}) => {
    const { id, onChange } = this.props;
    onChange(id, selected ? selected.value.id : null);
    this._validated = true;
  };

  render() {
    const {
      collections,
      selected,
      hide,
      isDisabled,
      validation,
      validated,
    } = this.props;
    if (hide) return null;
    const invalid = validation && (validated || this._validated);

    return (
      <SelectInput
        key={1}
        className={classnames('form-input-select', { 'is-danger': invalid })}
        name="collection"
        onChange={this.handleSelect}
        options={collections.map(collection => ({
          value: collection,
          label: collection.name,
        }))}
        placeholder="Choose collection"
        value={selected}
        isDisabled={isDisabled}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const selected = props.value
    ? state.collections.find(x => x.id === props.value)
    : null;
  return {
    collections: state.collections,
    selected: selected
      ? {
          label: selected.name,
          value: selected,
        }
      : null,
  };
};

export const CollectionField = connect(mapStateToProps)(_Collection);

export default { CollectionField };
