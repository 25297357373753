export const UPDATE_BATCH_DATA = 'UPDATE_BATCH_DATA';
export const BATCH_JOB_STARTED = 'BATCH_JOB_STARTED';
export const BATCH_JOB_FINISHED = 'BATCH_JOB_FINISHED';
export const BATCH_JOB_CLEARED = 'BATCH_JOB_CLEARED';
export const UPDATE_BATCH_JOB_PROGRESS = 'UPDATE_BATCH_JOB_PROGRESS';
export const UPDATE_BATCH_DUPLICATES = 'UPDATE_BATCH_DUPLICATES';
export const UPDATE_BATCH_NOUNS = 'UPDATE_BATCH_NOUNS';
export function updateBatchData(key, data) {
  return {
    type: UPDATE_BATCH_DATA,
    key,
    data,
  };
}

export function startBatchJob() {
  return {
    type: BATCH_JOB_STARTED,
  };
}

export function endBatchJob() {
  return {
    type: BATCH_JOB_FINISHED,
  };
}

export function clearBatchJob() {
  return {
    type: BATCH_JOB_CLEARED,
  };
}

export function updateBatchJobProgress(key, noun) {
  return {
    type: UPDATE_BATCH_JOB_PROGRESS,
    key,
    noun,
  };
}

export function updateBatchDuplicates(displayNouns, duplicateNouns) {
  return {
    type: UPDATE_BATCH_DUPLICATES,
    displayNouns,
    duplicateNouns,
  };
}

export function updateBatchNouns({ nouns, displayNouns, duplicateNouns }) {
  return {
    type: UPDATE_BATCH_NOUNS,
    nouns,
    displayNouns,
    duplicateNouns,
  };
}
