import { connect } from 'react-redux';
import { CardTemplatesContainer } from './container';
import {
  setPanel,
  updateSectionView,
} from '../../../store/actions/sectionView';
export { withRouter } from 'react-router';

function mapStateToProps(state, props) {
  const { cardTemplates } = state.packTemplates.byId[props.id] || {};
  const { id, packTemplateId, cardTemplateId } = props.match.params;

  return {
    tileTemplateId: id,
    packTemplateId,
    cardTemplates,
    cardTemplateId,
    isAdding:
      state.sectionView.tileTemplate.active === 'cardTemplate' &&
      state.sectionView.tileTemplate.method === 'add',
  };
}

const mapDispatchToProps = dispatch => ({
  confirmAddClick: () => {
    dispatch(
      updateSectionView('tileTemplate', {
        active: 'cardTemplate',
        method: 'add',
      }),
    );
    dispatch(
      setPanel('tileTemplate', {
        domain: 'tileTemplate',
        type: 'form',
        level: 'ternary',
        component: 'addCardTemplateForm',
      }),
    );
  },
});

export const CardTemplates = connect(mapStateToProps, mapDispatchToProps)(
  CardTemplatesContainer,
);

export default { CardTemplates };
