import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function LabelToggle({ activeToggle, switchToggle, labels }) {
  return (
    <div className="label-toggle">
      <div
        data-key={labels[0].key}
        className={classNames('label', {
          active: activeToggle === labels[0].key,
        })}
        onClick={switchToggle}
      >
        <FontAwesomeIcon icon={labels[0].icon} size="1x" />
        {labels[0].label}
      </div>
      <div
        data-key={labels[1].key}
        className={classNames('label', {
          active: activeToggle === labels[1].key,
        })}
        onClick={switchToggle}
      >
        <FontAwesomeIcon icon={labels[1].icon} size="1x" />
        {labels[1].label}
      </div>
    </div>
  );
}

export default { LabelToggle };
