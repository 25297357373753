// Images
import avatar from './images/default-profile.png';
import logo from './images/logo.png';

export { avatar, logo };

export default {
  avatar,
  logo,
};
