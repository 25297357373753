export const selectImageField = (state, props) => {
  
  let {value, images} = props;

  let category = {};
  const tileTemplate = props.entityKey === 'tile' ? props.value  : state.tileTemplates.find(
    x => x.id === value.tileTemplateId
  ) || {};
  if (!images) {
    category = state.categories.find(x => x.id === tileTemplate.categoryId) || {};
  }

  return {
    images: category.images || [],
    categoryId: tileTemplate.categoryId,
    newImage: state.editImage[props.entityKey],
  };
};

export default { selectImageField };
