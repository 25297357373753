import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { MuiThemeProvider } from '@material-ui/core/styles';
import classnames from 'classnames';
import theme from './theme';

export class BaseTable extends PureComponent {
  // componentWillUnmount() {
  //   console.log('unmount basetable');
  // }
  render() {
    const {
      isNested,
      columns,
      rows,
      title,
      options,
      activeItem,
      height,
    } = this.props;
    if (!rows || rows.length === 0) return null;
    let heightTable = height - 138 > 483 ? height - 138 : 484; // height of container - header - padding;
    if (isNested) heightTable = rows.length > 3 ? 'calc(100% - 267px)' : 'auto';
    else heightTable = `${heightTable}px`;

    return (
      <div className={classnames('data-table', { isNested })}>
        <MuiThemeProvider theme={theme(`${heightTable}`, isNested)}>
          <MUIDataTable
            title={title}
            data={rows}
            handleChange={() => {}}
            columns={columns}
            options={{
              ...{
                setRowProps: row => {
                  return {
                    className: classnames({
                      ActiveItemRow: row[0] === activeItem,
                    }),
                  };
                },
              },
              ...options,
            }}
          />
        </MuiThemeProvider>
      </div>
    );
  }
}

BaseTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  options: PropTypes.object,
};

BaseTable.defaultProps = {
  title: '',
  options: {},
};

export default { BaseTable };
