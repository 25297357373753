import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProgressBar from './bar';
import { JobDetails } from './details';
import { clearBatchJob } from '../../store/actions/batch';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';

class Container extends Component {
  state = {
    open: false,
  };

  handleClose = () => this.setState({ open: false });

  handleOpen = () => this.setState({ open: true });

  clearJob = () => this.props.dispatch(clearBatchJob());

  render() {
    const { isImporting, progress } = this.props;
    if (!isImporting) return null;
    return (
      <div className="job-progress-bar">
        <div className="progresss-bar-label">
          <span className="text">Import</span>
          <div
            className="view-details progress-bar-cancel-icon"
            onClick={this.handleOpen}
          >
            <InfoIcon />
          </div>
        </div>
        <ProgressBar percent={progress} />
        <JobDetails
          open={this.state.open}
          handleOpen={this.handleOpen}
          handleClose={this.handleClose}
        />
        <div
          className="view-details progress-bar-cancel-icon"
          onClick={this.clearJob}
        >
          <CloseIcon />
        </div>
      </div>
    );
  }
}

function select(state, props) {
  return {
    isImporting: state.batch.isImporting,
    progress: state.batch.progress,
  };
}

export const JobProgressBar = connect(select)(Container);
