import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { EditNavigationPrompt } from "./edit";
import { selectEditSectionNavigationPrompt } from "_selectors";
import { closeSectionForm, updateSectionView } from "_actions/sectionView";
import { attemptUpdateFormData } from "_thunks/views";

class _EditSectionNavigationPrompt extends PureComponent {
  afterConfirm = () => {
    const { closeForm, resetForm, active } = this.props;

    closeForm(this.props);
    resetForm(active);
  };

  toggleAttemptToClose = () => {
    const { setAttemptToCloseToFalse } = this.props;
    setAttemptToCloseToFalse(this.props);
  };

  render() {
    const { isDirty, attemptToClose } = this.props;

    return (
      <EditNavigationPrompt
        when={isDirty}
        attemptToClose={attemptToClose}
        afterConfirm={this.afterConfirm}
        toggleAttemptToClose={this.toggleAttemptToClose}
      />
    );
  }
}

const mapDispatchToProps = {
  closeForm: props => closeSectionForm(props.id),
  setAttemptToCloseToFalse: props =>
    updateSectionView(props.id, { attemptToClose: false }),
  resetForm: active => attemptUpdateFormData(active, { reset: true })
};

export const EditSectionNavigationPrompt = connect(
  selectEditSectionNavigationPrompt,
  mapDispatchToProps
)(_EditSectionNavigationPrompt);
