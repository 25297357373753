import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form, FormGroup, FormLabel } from '_components';

function renderFormComponent({
  component: Component,
  id,
  name,
  ...childProps
}) {
  return <Component key={id} id={id} name={name} {...childProps} />;
}

function renderFormGroupComponent({
  component: Component,
  id,
  name,
  hide,
  isCustom,
  metaActions,
  ...childProps
}) {
  if (hide) return null;
  return (
    <FormGroup key={id} id={id}>
      {!isCustom && name
        ? <div className="form-label-wrapper">
            <FormLabel {...childProps} text={name} />
            {metaActions
              ? metaActions.map(({ type, ...props }) =>
                  renderFormComponent(props),
                )
              : null}
          </div>
        : null}
      <Component id={id} name={name} {...childProps} />
    </FormGroup>
  );
}

function renderFormGroupColumn(props) {
  return (
    <div
      key={props.id}
      className={classNames('column', `col-${props.width || 4}`, 'col-xs-12')}
    >
      {renderFormGroupComponent(props)}
    </div>
  );
}

function renderFormGroupRow(props) {
  return (
    <div key={props.id} className="container" style={{ padding: 0 }}>
      <div className="columns">
        {renderFormGroups(props)}
      </div>
    </div>
  );
}

function renderFormGroups({ formGroups }) {
  return formGroups.map(({ type, ...props }) => {
    switch (type) {
      case 'row':
        return renderFormGroupRow(props);
      case 'column':
        return renderFormGroupColumn(props);
      default:
        return renderFormGroupComponent(props);
    }
  });
}

function renderFormChildren({ formChildren }) {
  return formChildren.map(({ component: Component, ...childProps }) => {
    return <Component key={{ ...childProps.id }} {...childProps} />;
  });
}

export function FormBody(props) {
  return (
    <div className={classNames('container', { 'dialog-body': props.isDialog })}>
      <div className="columns">
        <div
          className={classNames('column', 'col-xs-12', {
            'col-8': props.formChildren,
          })}
        >
          <Form>
            {renderFormGroups(props)}
          </Form>
        </div>
        {props.formChildren
          ? <div className="column col-4 col-xs-12">
              <div className="box">
                {renderFormChildren(props)}
              </div>
            </div>
          : null}
      </div>
    </div>
  );
}

FormBody.propTypes = {
  formChildren: PropTypes.array,
  formGroups: PropTypes.array,
};

FormBody.defaultProps = {
  formChildren: undefined,
  formGroups: [],
};
