import { connect } from 'react-redux';
import { PackTemplatesContainer } from './container';
import {
  setPanel,
  updateSectionView,
} from '../../../store/actions/sectionView';

function mapStateToProps(state, props) {
  const { packTemplates } =
    state.tileTemplates.find(item => item.id === props.id) || {};
  const { packTemplateId } = (props.match && props.match.params) || {};
  return {
    packTemplates,
    packTemplateId,
    isAdding:
      state.sectionView.tileTemplate.active === 'packTemplate' &&
      state.sectionView.tileTemplate.method === 'add',
  };
}

const mapDispatchToProps = dispatch => ({
  confirmAddClick: () => {
    dispatch(
      updateSectionView('tileTemplate', {
        active: 'packTemplate',
        method: 'add',
      }),
    );
    dispatch(
      setPanel('tileTemplate', {
        domain: 'tileTemplate',
        type: 'form',
        level: 'ternary',
        component: 'addPackTemplateForm',
      }),
    );
  },
});

export const PackTemplates = connect(mapStateToProps, mapDispatchToProps)(
  PackTemplatesContainer,
);

export default { PackTemplates };
