import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { TileContainer } from '../../tile/container';
import { FormBody, CSVFileReader } from '_components';
import { fields } from './commonFields';

const BlueCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#42a5f5',
    },
  },
  '&$checked': {},
})(props => <Checkbox color="default" {...props} />);

class CustomizedDialogs extends React.Component {
  state = {
    selected: '',
    withHeaders: false,
    titleCase: true,
  };

  handleClose = () => {
    this.props.handleBatchAddClick();
  };

  handleSave = () => {
    const { displayNouns, common } = this.props;
    this.props.batchCreate({ nouns: displayNouns, common });
    this.props.handleBatchAddClick(false);
  };

  handleFileLoaded = props => {
    this.updateNouns(props[0]);
  };

  handleSelectChange = (key, value) => {
    const values = { ...this.props.common, [key]: value };
    this.props.updateBatchData('common', values);
  };

  handleColourChange = (key, { hex: value }) => {
    const values = { ...this.props.common, [key]: value };
    this.props.updateBatchData('common', values);
  };

  formGroups = () =>
    fields({
      data: this.props.common,
      validation: {},
      handleFieldChange: this.handleFieldChange,
      handleOnValidate: this.handleOnValidate,
      handleSelectChange: this.handleSelectChange,
      handleColourChange: this.handleColourChange,
    });

  toggleCheckbox = event => {
    const key = event.target.value;
    this.setState({ [key]: !this.state[key] });
  };

  onBlur = () => {
    this.handleDuplicates();
  };

  handleDuplicates = () => {
    this.props.updateBatchDuplicates(this.state.titleCase);
  };

  onChange = event => {
    let nouns = event.target.value === '' ? [] : event.target.value.split(',');

    this.props.updateBatchData('displayNouns', nouns);
  };

  updateNouns = nouns => {
    this.props.updateBatchNouns(nouns, this.state.titleCase);
    this.setState({ selected: nouns[0] });
  };

  render() {
    const { displayNouns, duplicateNouns, open } = this.props;
    return (
      <div className="modal-parent">
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <div
            id="customized-dialog-title"
            className="modal-header"
            onClose={this.handleClose}
          >
            <span className="modal-header-title">Batch Import</span>
            <IconButton
              aria-label="Close"
              className={'modal-header-close'}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal-content batch-modal-content">
            <div className="batch-modal-lhs">
              <CSVFileReader
                cssClass="csv-reader-input"
                onFileLoaded={this.handleFileLoaded}
                onError={this.handleError}
                inputId="batchCreate"
                inputStyle={{ color: 'black' }}
                parseOptions={{ withHeaders: this.state.withHeaders }}
              />
              <div className="batch-modal-option">
                <span className="h4">Options</span>
                <div className="batch-modal-checkbox">
                  <BlueCheckbox
                    checked={this.state.withHeaders}
                    onChange={this.toggleCheckbox}
                    value="withHeaders"
                    color="primary"
                  />
                  <span>Contains Headers</span>
                </div>

                <div className="batch-modal-checkbox">
                  <BlueCheckbox
                    checked={this.state.titleCase}
                    onChange={this.toggleCheckbox}
                    value="titleCase"
                    color="primary"
                  />
                  <span>Title Case</span>
                </div>
              </div>
              <FormBody formGroups={this.formGroups()} />
            </div>
            <div className="batch-modal-rhs">
              <textarea
                placeholder={'Preview nouns for import'}
                type="text"
                value={this.props.displayNouns.join(',') || ''}
                onChange={this.onChange}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
              />
              <div className="tile-preview">
                <TileContainer
                  data={{ noun: displayNouns[0], ...this.props.common }}
                />
              </div>
            </div>
          </div>
          <div className="modal-actions">
            {duplicateNouns.length > 0 ? (
              <span className="duplicates-label">{`${
                duplicateNouns.length
              } Duplicate${(duplicateNouns.length > 1 && 's ') ||
                ' '}Removed`}</span>
            ) : null}
            <div
              className={
                displayNouns.length === 0
                  ? 'button btn btn-st-primary disabled'
                  : 'button btn btn-st-primary'
              }
              onClick={this.handleSave}
            >
              {displayNouns.length > 0
                ? `Start Import (${displayNouns.length})`
                : 'Start Import'}
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default CustomizedDialogs;
