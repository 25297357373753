export const selectEditSectionNavigationPrompt = (state, props) => {
  const section = state.sectionView.tileTemplate;
  const status = section.active && state.form[section.active];
  return {
    ...section,
    ...(status || {}),
  };
};

export default { selectEditSectionNavigationPrompt };
