import { Text, ImageField, TextInput } from "_components";
import { PackImageCropper } from "shared-code";

export const fields = ({
  validation,
  data,
  validated,
  handleFieldChange,
  handleOnValidate,
  handleSelectChange,
  handleColourChange,
  hideCollection,
  isNested
}) => [
  {
    component: Text,
    textComponent: TextInput,
    name: "Name",
    id: "name",
    value: data.name,
    onChange: handleFieldChange,
    onValidate: handleOnValidate,
    validation: validation.name,
    placeholder: "Enter name",
    validated: validated
  },
  {
    type: "row",
    id: "imageRow",
    formGroups: [
      {
        type: "column",
        component: ImageField,
        name: "Image",
        id: "imgurl",
        showPreview: false,
        value: data,
        onChange: handleFieldChange,
        width: !isNested ? 6 : 12,
        vertical: !isNested,
        entityKey: "pack",
        ImageCropper: PackImageCropper
      }
    ]
  }
];
