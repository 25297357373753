import React from 'react';

export function AdminsPage() {
  return (
    <div className="admins">
      <div className="container grid-lg">
        <div className="columns col-gapless">
          <div className="column">Admins Content Goes Here</div>
        </div>
      </div>
    </div>
  );
}

export default { AdminsPage };
