import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { AddForm } from '_components';

const styles = {
  backdrop: {
    backgroundColor: '#FAFAFA',
  },
  paper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    paddingTop: '50px',
    paddingBottom: '50px',
  },
  flex: {
    flex: 1,
  },
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function _AddDialogForm({ handleDialogCloseClick, classes, ...props }) {
  return (
    <Dialog
      fullScreen
      open
      onClose={handleDialogCloseClick}
      TransitionComponent={Transition}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{
        classes: {
          root: classes.paper,
        },
      }}
    >
      <AddForm
        className="dialog"
        handleCloseClick={handleDialogCloseClick}
        {...props}
      />
    </Dialog>
  );
}

_AddDialogForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

_AddDialogForm.defaultProps = {};

export const AddDialogForm = withStyles(styles)(_AddDialogForm);

export default { AddDialogForm };
