import { connect } from 'react-redux';
import { CardTemplateActiveItemContainer } from './container';
import { withActiveItem } from '../../templates';
import {
  attemptUpdateCardTemplate,
  attemptDeleteCardTemplate,
} from '_thunks/cardTemplates';
import { attemptUpdateFormData } from '../../../store/thunks/views';
import {
  setPanel,
  clearPanel,
  updateSectionView,
  closeSectionForm,
} from '../../../store/actions/sectionView';

const mapDispatchToProps = dispatch => ({
  setPanel: panel => dispatch(setPanel('tileTemplate', panel)),
  createItem: item => dispatch(attemptUpdateCardTemplate(item, true)),
  updateItem: item => dispatch(attemptUpdateCardTemplate(item)),
  deleteItem: item => dispatch(attemptDeleteCardTemplate(item)),

  clearPanel: level => {
    dispatch(clearPanel('tileTemplate', level));
  },

  clone: id => {
    dispatch(
      updateSectionView('tileTemplate', {
        active: 'cardTemplate',
        method: 'add',
        cloneId: id,
      }),
    );
    dispatch(
      setPanel('tileTemplate', {
        domain: 'tileTemplate',
        type: 'form',
        level: 'ternary',
        id: id,
        component: 'addCardTemplateForm',
      }),
    );
  },

  clearForm: () => {
    dispatch(closeSectionForm('tileTemplate'));
  },
  updateSectionView: sectionView =>
    dispatch(updateSectionView('tileTemplate', sectionView)),
  setFormStatus: ({ status }) => {
    return dispatch(
      attemptUpdateFormData('packTemplate', {
        status,
      }),
    );
  },
  // resetFormData: (data, props) => {
  //   dispatch(resetEditImage("pack"));

  //   dispatch(
  //     attemptUpdateFormData("packTemplate", {
  //       ...data,
  //       activeItem: {
  //         tileTemplateId: props.tileTemplateId,
  //         ...data.activeItem
  //       }
  //     })
  //   );
  // }
});

const mapStateToProps = (state, props) => {
  const { match: { params: { id, packTemplateId, cardTemplateId } } } = props;

  return {
    dispatch: props.dispatch,
    formKey: 'cardTemplate',
    addComponent: 'addCardTemplateForm',
    itemCloseUrl: `/tile-templates/${id}/packs/${packTemplateId}`,
    tileTemplateId: id,
    packTemplateId: packTemplateId,
    id: cardTemplateId,
    deleteLabel: 'Card',
    cardTemplateId,
    ...state.sectionView.tileTemplate,
    formData: state.form.cardTemplate.data,
    editData: state.form.cardTemplate.data,
    ...(cardTemplateId ? state.cardTemplates.byId[cardTemplateId] : {}),
    ...state.form.cardTemplate,
    isActive: state.sectionView.tileTemplate.active === 'cardTemplate',
    method: state.sectionView.tileTemplate.method,
    isAdding: state.sectionView.tileTemplate.method === 'add',
    isEditing: state.sectionView.tileTemplate.method === 'edit',
    newImage: state.editImage.card,
    showActions:
      !state.sectionView.tileTemplate.active ||
      state.sectionView.tileTemplate.active === 'cardTemplate',
  };
};

export const CardTemplateActiveItem = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withActiveItem(CardTemplateActiveItemContainer));

export default { CardTemplateActiveItem };
