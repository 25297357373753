import React, { PureComponent } from "react";
import classNames from "classnames";

export class PackTemplateItemContainer extends PureComponent {
  state = {
    isAdding: false,
    isEditing: false,
    activeItem: null
  };

  viewPack = () => {
    this.props.history.push(
      `/tile-templates/${this.props.tileTemplateId}/packs/${this.props.id}`
    );
  };
  editPack = () => {
    this.props.onEditClick(this.props.id);
  };

  addCardTemplate = () => {
    this.props.resetCardTemplateFormData(
      {
        reset: true,
        activeItem: {}
      },
      {
        tileTemplateId: this.props.tileTemplateId,
        id: this.props.id
      }
    );
    this.setState({ isAdding: true });
  };

  handleAddCloseClick = () => {
    this.setState({ isAdding: false, isEditing: false, activeItem: null });
  };

  handleAddSaveClick = data => {
    this.setState({ isAdding: false, isEditing: false, activeItem: null });
    if (this.state.isEditing) {
      this.props.editChildItem(data);
    } else {
      this.props.createChildItem(data);
    }
  };

  render() {
    const { name, imgurlCustom, isActive } = this.props;

    return (
      <div
        className={classNames("pack-template", { active: isActive })}
        onClick={this.viewPack}
      >
        <div
          className="pack-template-img"
          style={{ backgroundImage: `url(${imgurlCustom})` }}
          alt=""
        >
          <div className="pack-template-img-overlay" />
        </div>
        <div className="pack-template-name">{name}</div>

        {/* <Popout show={this.state.isAdding || this.state.isEditing}>
          <AddCardTemplateForm
            handleAddSaveClick={this.handleAddSaveClick}
            saveLabel={this.state.isAdding ? 'Add' : 'Edit'}
            isNested
            hideCollection={!this.props.id}
            packTemplate={this.props.id}
            id={this.state.activeItem}
            onCancel={this.handleAddCloseClick}
          />
        </Popout> */}
      </div>
    );
  }
}
