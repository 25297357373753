import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '_components';
import classNames from 'classnames';

export class ImageUrl extends PureComponent {
  static propTypes = {
    validation: PropTypes.string,
    showPreview: PropTypes.bool,
  };

  static defaultProps = {
    validation: undefined,
    showPreview: true,
  };

  _validated = false;

  _handleOnChange = event => {
    const { id } = this.props;
    const { value } = event.currentTarget;
    this.props.onChange(id, value);
  };

  _handleOnFocus = () => {
    this._validated = false;
  };

  _handleOnBlur = () => {
    const { id, onValidate, value } = this.props;
    if (!onValidate) return;
    onValidate(id, value);
    this._validated = true;
  };

  render() {
    const { value, validation, vertical, showPreview } = this.props;

    return (
      <div className={classNames('row', { vertical })}>
        {showPreview ? (
          <div className="image-container">
            <img className="image-body" src={value} alt="" />
          </div>
        ) : null}
        <TextInput
          id="imgurl"
          value={value}
          placeholder="https://link-to-image.com"
          onChange={this._handleOnChange}
          onBlur={this._handleOnBlur}
          onFocus={this._handleOnFocus}
          isValid={!validation}
          validated={this._validated}
          validation={validation}
          showError={!vertical}
        />
      </div>
    );
  }
}
