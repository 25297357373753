import update from 'immutability-helper';
import { UPDATE_TILE_TEMPLATE_TABLE_STATE } from '_actions/views';

const INITIAL_STATE = { rowsSelected: [] };

export default function tileTemplatesTable(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_TILE_TEMPLATE_TABLE_STATE:
      return update(state, { $set: action.state });
    case '@@router/LOCATION_CHANGE':
      return INITIAL_STATE;
    default:
      return state;
  }
}
