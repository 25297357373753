import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TextArea } from '_components/form';

export class Text extends PureComponent {
  static propTypes = {
    textComponent: PropTypes.any,
    validation: PropTypes.array,
  };

  static defaultProps = {
    textComponent: TextArea,
    validation: undefined,
  };

  _validated = false;

  _handleOnChange = event => {
    const { id, inputType } = this.props;
    const { value } = event.currentTarget;

    this.props.onChange(id, inputType === 'number' ? parseInt(value) : value);
    this._validated = true;
  };

  _handleOnFocus = () => {
    this._validated = false;
  };

  _handleOnBlur = () => {
    const { id, onValidate, value } = this.props;
    if (!onValidate) return;
    onValidate(id, value);
    this._validated = true;
  };

  render() {
    const {
      value,
      textComponent: TextComponent,
      placeholder,
      validation,
      validated,
      ...props
    } = this.props;

    return (
      <TextComponent
        {...props}
        value={value}
        placeholder={placeholder}
        onChange={this._handleOnChange}
        onBlur={this._handleOnBlur}
        onFocus={this._handleOnFocus}
        isValid={!validation}
        validated={this._validated || validated}
      />
    );
  }
}
