import { connect } from 'react-redux';
import { addHOC } from '../../form';
import { AddTileTemplateFormContainer } from './addContainer';
import { attemptUpdateFormData } from '_thunks/views';
import { fields } from './fields';
import { tileTemplateDefault as constraints } from '_api/constraints';

const mapStateToProps = (state, props) => {
  const {
    params: { id },
  } = props.match || { params: {} };
  return {
    tileTemplates: state.tileTemplates,
    ...state.form.tileTemplate,
        newImage: state.editImage.tile,
    activeItem: state.tileTemplates.find(x => x.id === id),
  };
};

const mapStateToDispatch = dispatch => ({
  updateFormData: data => dispatch(attemptUpdateFormData('tileTemplate', data)),
  resetFormData: (data, props) =>
    dispatch(
      attemptUpdateFormData('tileTemplate', {
        ...data,
        activeItem: { collectionId: props.collectionId, ...data.activeItem },
      }),
    ),
});

export const AddTileTemplateForm = connect(
  mapStateToProps,
  mapStateToDispatch,
)(
  addHOC(AddTileTemplateFormContainer, {
    fields,
    title: 'Tile Template',
    constraints,
  }),
);
export default { AddTileTemplateForm };
