import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import { Text, TextInput } from '_components';

const styles = {
  paper: {
    height: 'auto',
  },
};

class _ColourPicker extends PureComponent {
  static propTypes = {
    value: PropTypes.string,
    code: PropTypes.string,
  };

  static defaultProps = {
    value: '#000000',
    code: null,
  };

  state = {
    anchorEl: null,
  };
  _handleOnChange = color => {
    const { id, onChange } = this.props;
    onChange(id, color);
  };

  _handleOnTextChange = (id, hex) => {
    const { onChange } = this.props;
    onChange(id, { hex });
  };

  _handleOnBlur = () => {
    const { id, onValidate, value } = this.props;
    if (!onValidate) return;
    onValidate(id, value);
  };

  togglePopout = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { value, id, validation, code, domain, classes } = this.props;

    const { anchorEl } = this.state;
    return (
      <div className="color-picker-wrapper" onBlur={this._handleOnBlur}>
        {code
          ? <span>
              {code}
            </span>
          : null}
        <div className="top">
          <div
            aria-owns={anchorEl ? 'popover-color-picker' : undefined}
            aria-haspopup="true"
            onClick={this.togglePopout}
            className="color-picker color-picker-preview"
            variant="contained"
            style={{ backgroundColor: value }}
          />
          <Text
            id={id}
            domain={domain}
            textComponent={TextInput}
            value={value || ''}
            onChange={this._handleOnTextChange}
            validation={validation}
          />
        </div>

        <Popover
          id={'popover-color-picker'}
          style={{ height: 'auto' }}
          classes={{ paper: classes.paper }}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <SketchPicker
            color={value || ''}
            onChangeComplete={this._handleOnChange}
          />
        </Popover>
      </div>
    );
  }
}

_ColourPicker.propTypes = {
  classes: PropTypes.object.isRequired,
};

_ColourPicker.defaultProps = {};

export const ColourPicker = withStyles(styles)(_ColourPicker);

export default { ColourPicker };
