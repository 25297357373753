import React, { Component } from 'react';
import {
  SummaryRow,
  AddTileTemplateForm,
  Popout,
  TileTemplateTable,
  TileTemplatesField,
} from '_components';

export class CollectionItemContainer extends Component {
  state = {
    isAdding: false,
  };
  handleAddCloseClick = () => {
    this.setState({ isAdding: false });
  };

  handleAddClick = () => {
    this.props.resetFormData(
      {
        reset: true,
        activeItem: {},
      },
      { collectionId: this.props.id },
    );
    this.setState({ isAdding: true });
  };

  handleAddSaveClick = data => {
    this.setState({ isAdding: false });
    this.props.createChildItem(data);
  };

  handleItemClick = (rowData, rowMeta, tileTemplates) => {
    let index;
    // table item
    if (rowMeta) {
      index = rowMeta.dataIndex;
    } else {
      // card
      index = rowData.currentTarget.dataset.index;
    }

    const activeItem = tileTemplates[index];

    this.props.history.push(`/tile-templates/${activeItem.id}`);
  };

  itemSelector = data => {
    return {
      title: data.name,
      subtitle: data.text,
      image: data.imgurl,
    };
  };

  render() {
    const { isAdding } = this.state;

    return (
      <React.Fragment>
        <SummaryRow
          {...this.props}
          data={
            this.props.isEditing || this.props.isAdding
              ? this.props.editData
              : null
          }
          dataKey="collections"
          itemSelector={this.itemSelector}
          actions={this.props.actions}
          iconOnly={this.props.iconOnly}
          isValid={this.props.isValid}
        />
        <Popout show={this.state.isAdding}>
          <AddTileTemplateForm
            handleAddSaveClick={this.handleAddSaveClick}
            saveLabel="Add"
            isNested
            hideCollection={!this.props.id}
            collectionId={this.props.id}
            onCancel={this.handleAddCloseClick}
          />
        </Popout>
        {this.props.isEditing || this.props.isAdding
          ? <div className="tile-templates-field">
              <TileTemplatesField
                onChange={this.props.handleTileTemplatesChange}
                currentTileTemplates={this.props.tileTemplates}
                value={this.props.editData.tileTemplates}
                parentId={this.props.editData.id}
                id={'TileTemplates'}
              />
            </div>
          : <TileTemplateTable
              title="Tile Templates"
              id={this.props.id}
              isNested
              selectorKey={'COLLECTION'}
              handleRowClick={this.handleItemClick}
              isAdding={isAdding}
              activeItem={this.props.id}
              handleAddClick={this.handleAddClick}
              disableSelectableRows
            />}
      </React.Fragment>
    );
  }
}

export default { CollectionItemContainer };
