import React, { PureComponent } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { attemptUpdateFormData } from "_thunks/views";
import { AddFieldTemplateInlineForm } from "_components";

class _FieldTemplateField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false
    };
  }

  toggleCollapse = () => {
    this.props.toggleCollapse(this.props.data);
  };

  handleRemove = () => {
    this.props.handleRemove(this.props.data);
  };

  handleMarkAsRemove = () => {
    this.props.handleMarkAsRemove(this.props.data);
  };

  getCurrentItemClassName = item => {
    return this.state.removedFieldTemplates.includes(item.id)
      ? "delete-item"
      : "";
  };

  handleFieldChange = (data, val) => {
    this.props.handleFieldChange({ ...this.props.data, [data]: val });
  };

  handleSelectChange = (data, { name }) => {
    const value = data === null ? null : data.value;

    this.props.handleFieldChange({
      ...this.props.data,
      [name === "Field Type" ? "fieldType" : null]: value
    });
  };

  renderForm = (data, isCollapased) => (
    <AddFieldTemplateInlineForm
      isNested
      data={data}
      validation={{}}
      isCollapased={isCollapased}
      handleFieldChange={this.handleFieldChange}
      handleSelectChange={this.handleSelectChange}
      handleRemove={this.handleRemove}
      handleMarkAsRemove={this.handleMarkAsRemove}
      toggleCollapse={this.toggleCollapse}
      isRemoved={this.props.isRemoved}
      handleAddSaveClick={this.handleAdd}
      saveLabel="Add"
      onCancel={this.toggleCreating}
    />
  );

  render() {
    const { data, isCollapased } = this.props;

    return (
      <div
        className={classNames("box-header", { hide: this.state.isCreating })}
      >
        {this.renderForm(data, isCollapased)}
      </div>
    );
  }
}

const mapStateToDispatch = dispatch => ({
  resetFormData: (data, props) =>
    dispatch(
      attemptUpdateFormData("fieldTemplate", {
        ...data,
        reset: true,
        activeItem: {
          ...data.activeItem,
          cardTemplateId: props.cardTemplateId
        }
      })
    )
});

export const FieldTemplateField = connect(
  null,
  mapStateToDispatch
)(_FieldTemplateField);

export default { FieldTemplateField };
