import { connect } from "react-redux";
import { TileTemplateTable as _TileTemplateTable } from "./table";
import { attemptUpdateTileTemplates } from "_thunks/tileTemplates";
import { attemptToSetTileTemplatesTableState } from "_thunks/views";
import { clearBatchJob } from "_thunks/batch";
import { attemptGetCollectionData } from "../../../store/thunks/collections";

const mapStateToProps = (state, props) => {
  const { selectorKey, id } = props;
  switch (selectorKey) {
    case "COLLECTION":
      const { tileTemplates } =
        state.collections.find(item => item.id === id) || {};
      return {
        tileTemplates
      };
    default:
      return {
        tileTemplates: state.tileTemplates.filter(x => ({
          ...x,
          packTemplates: undefined
        })),
        isImporting: state.batch.isImporting
      };
  }
};

const mapDisptachToProps = (dispatch, props) => ({
  activateTileTemplates: selected =>
    dispatch(attemptUpdateTileTemplates(selected)),
  setTileTemplatesTableState: state =>
    dispatch(attemptToSetTileTemplatesTableState(state)),
  getCollectionData: () => dispatch(attemptGetCollectionData(props.id)),
  clearBatchJob: () => dispatch(clearBatchJob())
});

export const TileTemplateTable = connect(
  mapStateToProps,
  mapDisptachToProps
)(_TileTemplateTable);

export default { TileTemplateTable };
