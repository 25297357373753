import {
  getFieldTemplates,
  updateFieldTemplates as editFieldTemplates,
  deleteFieldTemplates,
  createFieldTemplates
} from "_api/fieldTemplates";
import {
  setFieldTemplates,
  updateFieldTemplates,
  addCardTemplateFieldTemplates,
  removeCardTemplateFieldTemplates
} from "_actions/fieldTemplates";
import { dispatchError } from "_utils/api";

export const attemptGetFieldTemplates = () => (dispatch, getState) => {
  if (getState().fetchStatus.tileTemplates) return Promise.resolve({});
  return getFieldTemplates()
    .then(data => {
      dispatch(setFieldTemplates(data));
      return data;
    })
    .catch(dispatchError(dispatch));
};

export const attemptEditFieldTemplates = items => dispatch =>
  editFieldTemplates(items)
    .then(() => {
      dispatch(updateFieldTemplates(items));
      return items;
    })
    .catch(dispatchError(dispatch));

export const attemptCreateFieldTemplates = props => dispatch =>
  createFieldTemplates(props)
    .then(data => {
      const cleanedData = Array.isArray(data) ? data : [data];
      dispatch(
        addCardTemplateFieldTemplates(props.cardTemplateId, cleanedData)
      );
      return cleanedData;
    })
    .catch(dispatchError(dispatch));

export const attemptDeleteFieldTemplates = params => dispatch =>
  deleteFieldTemplates(params)
    .then(data => {
      dispatch(
        removeCardTemplateFieldTemplates(
          params.cardTemplateId,
          params.removedFieldTemplates
        )
      );
      return params;
    })
    .catch(dispatchError(dispatch));
