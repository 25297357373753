import assoc from 'ramda/src/assoc';
import concat from 'ramda/src/concat';
import compose from 'ramda/src/compose';
import converge from 'ramda/src/converge';
import equals from 'ramda/src/equals';
import head from 'ramda/src/head';
import identity from 'ramda/src/identity';
import isEmpty from 'ramda/src/isEmpty';
import join from 'ramda/src/join';
import map from 'ramda/src/map';
import match from 'ramda/src/match';
import omit from 'ramda/src/omit';
import pick from 'ramda/src/pick';
import slice from 'ramda/src/slice';
import split from 'ramda/src/split';
import tail from 'ramda/src/tail';
import toUpper from 'ramda/src/toUpper';
import pickBy from 'ramda/src/pickBy';
import propEq from 'ramda/src/propEq';
import find from 'ramda/src/find';

export default {
  assoc,
  concat,
  compose,
  converge,
  equals,
  head,
  identity,
  isEmpty,
  join,
  map,
  match,
  omit,
  pick,
  pickBy,
  slice,
  split,
  tail,
  toUpper,
  propEq,
  find,
};
