import React from 'react';
import AddIcon from '@material-ui/icons/Add';

export default handleAddClick => [
  {
    title: 'Create category',
    onClick: handleAddClick,
    icon: <AddIcon />,
  },
];
