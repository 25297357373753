import React, { PureComponent } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
  SelectInput,
  FormLabel,
  Action,
  Popout,
  AddCollectionTypeForm,
} from '_components';
import { attemptCreateCollectionType } from '../../../store/thunks/collectionTypes';

class _CollectionType extends PureComponent {
  state = {
    isCreating: false,
  };

  _validated = false;

  handleSelect = (selected = {}) => {
    const { id, onChange } = this.props;
    const value = selected ? selected.value.id : null;
    onChange(id, value);
    this._validated = true;
  };

  toggleCreating = () => {
    this.setState({
      isCreating: !this.state.isCreating,
    });
    this._validated = true;
  };

  handleSave = value => {
    this.handleSelect({ value });
    this._validated = true;
  };

  _handleOnFocus = () => {
    this._validated = false;
  };

  render() {
    const {
      collectionTypes,
      selected,
      name,
      validation,
      validated,
    } = this.props;

    const invalid = validation && (validated || this._validated);

    return [
      <div
        key={0}
        className="box-header"
        onBlur={this._handleOnBlur}
        onFocus={this._handleOnFocus}
      >
        {name ? <FormLabel text="Collection Type" /> : null}
        {selected && name
          ? null
          : <Action
              type="st-outline"
              icon={faPlus}
              iconSize="1x"
              onClick={this.toggleCreating}
            />}
        <Popout show={this.state.isCreating}>
          <AddCollectionTypeForm
            isNested
            handleSave={this.handleSave}
            onCancel={this.toggleCreating}
            createItem={this.props.createItem}
          />
        </Popout>
      </div>,
      <SelectInput
        key={1}
        className={classnames('form-input-select', { 'is-danger': invalid })}
        name="collection-type"
        onChange={this.handleSelect}
        options={collectionTypes.map(type => ({
          value: type,
          label: type.name,
        }))}
        placeholder="Choose type"
        value={selected}
      />,
    ];
  }
}

const mapStateToProps = (state, props) => {
  const selected = props.value
    ? state.collectionTypes.find(x => x.id === props.value)
    : null;
  return {
    collectionTypes: state.collectionTypes,
    selected: selected
      ? {
          label: selected.name,
          value: selected,
        }
      : null,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    createItem: item => dispatch(attemptCreateCollectionType(item)),
  };
};

export const CollectionTypeField = connect(mapStateToProps, mapDispatchToProps)(
  _CollectionType,
);

export default { CollectionTypeField };
