import React, { Component } from 'react';
import { AddForm, AddDialogForm } from '_components';

export class AddCollectionTypeFormContainer extends Component {
  handleDialogCloseClick = () => {
    const { onCancel } = this.props;
    this.props.resetFormData(
      { reset: true, activeItem: this.props.activeItem },
      this.props,
    );

    onCancel();
  };
  nestedSave = () => {
    const { createItem } = this.props;

    createItem(this.props.data).then(col => {
      this.props.handleSave(col);
      this.handleDialogCloseClick();
    });
  };

  render() {
    const { isDialog, isNested } = this.props;
    const AddFormComponent = isDialog ? AddDialogForm : AddForm;

    return (
      <AddFormComponent
        {...this.props}
        handleCloseClick={
          isNested ? this.handleDialogCloseClick : this.props.handleCloseClick
        }
        handleAddSaveClick={isNested ? this.nestedSave : null}
      />
    );
  }
}
