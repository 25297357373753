import React, { Component } from 'react';
import { AddForm, AddDialogForm, BooleanField } from '_components';

export class AddFieldTemplateFormContainer extends Component {
  headerProps = () => ({
    metaActions: [
      {
        component: BooleanField,
        name: 'Active',
        id: 'active',
        label: 'active',
        value: this.props.data.active,
        onChange: this.props.handleFieldChange,
        onValidate: this.props.handleOnValidate,
      },
    ],
  });

  handleDialogCloseClick = () => {
    const { onCancel } = this.props;
    this.props.resetFormData(
      { reset: true, activeItem: this.props.activeItem },
      this.props,
    );

    onCancel();
  };

  render() {
    const { isDialog, isNested } = this.props;
    const AddFormComponent = isDialog ? AddDialogForm : AddForm;

    return (
      <AddFormComponent
        {...this.props}
        headerProps={this.headerProps()}
        handleCloseClick={
          isNested ? this.handleDialogCloseClick : this.props.handleCloseClick
        }
      />
    );
  }
}
