import React, { PureComponent } from "react";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { SelectInput } from "_components";
import classnames from "classnames";

const theme = createMuiTheme({
  overrides: {
    MuiSwitch: {
      colorPrimary: {
        color: `${red[300]} !important`,
        "&$checked": {
          color: `${green[500]} !important`
        },
        "&$checked + $track": {
          backgroundColor: green[500]
        }
      },
      track: { backgroundColor: red[500] }
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0
      }
    }
  }
});

export class BooleanField extends PureComponent {
  handleSelect = (selected = {}) => {
    const { id, onChange } = this.props;
    onChange(id, selected ? selected.value : null);
  };

  handleChange = name => event => {
    const { id, onChange, disabled } = this.props;
    if (disabled) return;
    onChange(id, event.target.checked);
  };

  render() {
    const { value, id, selector, label, disabled } = this.props;

    const options = [
      {
        value: true,
        label: "Yes"
      },
      {
        value: false,
        label: "No"
      }
    ];

    const selected = options.find(option => option.value === value);

    if (selector) {
      return (
        <SelectInput
          key={1}
          className="form-input-select"
          name={id}
          onChange={this.handleSelect}
          options={options}
          placeholder="Please select"
          value={selected}
          disabled={disabled}
        />
      );
    }

    return (
      <FormControlLabel
        control={
          <MuiThemeProvider theme={theme}>
            <Switch
              className={classnames("mui-switch", { disabled })}
              checked={value}
              onChange={this.handleChange("checked")}
              value="checked"
              color="primary"
              disabled={disabled}
            />
          </MuiThemeProvider>
        }
        label={label ? label.toUpperCase() : null}
      />
    );
  }
}

export default { BooleanField };
