import validate from 'validate.js';

export const cardTemplateDefault = {
  active: validate.isBoolean,
  editable: validate.isBoolean,
  packTemplateId: {
    presence: true,
    length: {
      maximum: 36,
    },
  },
  imgurl: {
    presence: false,
    url: true,
    length: {
      maximum: 255,
    },
  },
  name: {
    presence: true,
    length: {
      maximum: 255,
    },
  },
};

export const cardTemplateConstraints = {
  POST: {
    ...cardTemplateDefault,
  },
  PUT: {
    ...cardTemplateDefault,
    id: {
      presence: true,
      length: {
        maximum: 36,
      },
    },
  },
};

export default {
  cardTemplateConstraints,
  cardTemplateDefault,
};
