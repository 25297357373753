import { TileTemplateTable } from '../table';
import { TileItem } from '../item';
import { AddTileTemplateForm } from '../form';
import { AddPackTemplateForm } from '../../packTemplate/form';
import { AddCardTemplateForm } from '../../cardTemplate/form';

export const componentMap = {
  tileTemplateTable: TileTemplateTable,
  tileTemplateItem: TileItem,
  addTileTemplateForm: AddTileTemplateForm,
  addPackTemplateForm: AddPackTemplateForm,
  addCardTemplateForm: AddCardTemplateForm,
};
