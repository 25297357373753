import { connect } from 'react-redux';
import R from '_utils/ramda';
import { attemptGetCollectionTypes } from '_thunks/collectionTypes';
import { CollectionTypesPageContainer } from './collectionTypesPageContainer';

const mapStateToProps = R.pick(['collectionTypes']);

const mapDispatchToProps = dispatch => ({
  getCollectionTypes: () => dispatch(attemptGetCollectionTypes()),
});

export const CollectionTypesPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollectionTypesPageContainer);

export default { CollectionTypesPage };
