import {
  CollectionsPage,
  TileTemplatesPage,
  CategoriesPage,
  CollectionTypesPage,
  UsersPage,
} from '_pages';
import { RoutePanel } from '_components';

export const tileTemplateRoutes = match => [
  {
    path: `${match.url}/:id/packs/:packTemplateId/cards/:cardTemplateId?`,
    tab: 'tile-templates',
    component: RoutePanel,
    title: 'Tile Templates',
  },
  {
    path: `${match.url}/:id/packs/:packTemplateId?`,
    tab: 'tile-templates',
    component: RoutePanel,
    title: 'Tile Templates',
    key: 'pack',
  },
  {
    path: `${match.url}/:id?`,
    tab: 'tile-templates',
    component: RoutePanel,
    title: 'Tile Templates',
    key: 'tile',
  },
];

export const homeRoutes = [
  {
    exact: true,
    path: '/',
    tab: 'collections',
    component: CollectionsPage,
    title: 'Collections',
  },
  {
    path: '/collections/:id?',
    tab: 'collections',
    component: CollectionsPage,
    title: 'Collection',
  },

  {
    path: '/collection-types/:id?',
    tab: 'collection-types',
    component: CollectionTypesPage,
    title: 'Collection Types',
  },
  // {
  //   path: '/tile-templates/:id/packs/:packTemplateId/cards/:cardTemplateId?',
  //   tab: 'tile-templates',
  //   component: TileTemplatesPage,
  //   title: 'Tile Templates',
  // },
  // {
  //   path: '/tile-templates/:id/packs/:packTemplateId?',
  //   tab: 'tile-templates',
  //   component: TileTemplatesPage,
  //   title: 'Tile Templates',
  // },
  // {
  //   path: '/tile-templates/:id',
  //   tab: 'tile-templates',
  //   component: TileTemplatesSection,
  //   title: 'Tile Templates',
  // },
  {
    path: '/tile-templates',
    tab: 'tile-templates',
    component: TileTemplatesPage,
    title: 'Tile Templates',
    children: tileTemplateRoutes,
  },
  {
    path: '/categories/:id?',
    tab: 'categories',
    component: CategoriesPage,
    title: 'Categoriess',
  },
  {
    path: '/users',
    tab: 'users',
    component: UsersPage,
    title: 'Users',
  },
];

// export default [
//   {
//     exact: true,
//     path: "/",
//     tab: "collections",
//     component: CollectionsPage,
//     title: "Collections"
//   },
//   {
//     path: "/collections/:id?",
//     tab: "collections",
//     component: CollectionsPage,
//     title: "Collection"
//   },

//   {
//     path: "/collection-types/:id?",
//     tab: "collection-types",
//     component: CollectionTypesPage,
//     title: "Collection Types"
//   },
//   {
//     path: "/tile-templates/:id/packs/:packTemplateId/cards/:cardTemplateId?",
//     tab: "tile-templates",
//     component: TileTemplatesPage,
//     title: "Tile Templates"
//   },
//   {
//     path: "/tile-templates/:id/packs/:packTemplateId?",
//     tab: "tile-templates",
//     component: TileTemplatesPage,
//     title: "Tile Templates"
//   },
//   {
//     path: "/tile-templates/:id?",
//     tab: "tile-templates",
//     component: TileTemplatesPage,
//     title: "Tile Templates"
//   },
//   {
//     path: "/categories/:id?",
//     tab: "categories",
//     component: CategoriesPage,
//     title: "Categoriess"
//   },
//   {
//     path: "/users",
//     tab: "users",
//     component: UsersPage,
//     title: "Users"
//   }
// ];
