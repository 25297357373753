export const SET_USERS = 'SET_USERS';
export const UPDATE_USER = 'UPDATE_USER';

export function setUsers(users) {
  return {
    type: SET_USERS,
    users,
  };
}

export function updateUser(user) {
  return {
    type: UPDATE_USER,
    id: user.id,
    user,
  };
}
