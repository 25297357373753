import update from 'immutability-helper';

import {
  SET_CARD_TEMPLATES,
  UPDATE_CARD_TEMPLATE,
  ADD_CARD_TEMPLATE,
  REMOVE_CARD_TEMPLATE,
} from '_actions/cardTemplates';
import { SET_TILE_TEMPLATES } from '_actions/tileTemplates';
import { SET_PACK_TEMPLATES } from '_actions/packTemplates';
import {
  ADD_CARD_TEMPLATE_FIELD_TEMPLATES,
  REMOVE_CARD_TEMPLATE_FIELD_TEMPLATES,
} from '_actions/fieldTemplates';

function updateIds(state, ids = []) {
  if (ids.length === 0) return state;
  return [...new Set(state.concat(ids))];
}

function removeFieldTemplates(state, { id, fieldTemplates }) {
  let newState = state;
  fieldTemplates.forEach(fieldTemplate => {
    newState = update(newState, {
      byId: {
        [id]: {
          fieldTemplates: {
            $set: state.byId[id].fieldTemplates.filter(
              x => x !== fieldTemplate,
            ),
          },
        },
      },
    });
  });
  return newState;
}

export default function cardTemplates(state = { all: [], byId: {} }, action) {
  switch (action.type) {
    case SET_TILE_TEMPLATES:
    case SET_PACK_TEMPLATES:
    case SET_CARD_TEMPLATES:
      return update(state, {
        all: { $set: action.cardTemplatesAll },
        byId: { $set: action.cardTemplates || {} },
      });
    case REMOVE_CARD_TEMPLATE:
      return update(state, {
        all: { $set: state.all.filter(x => x !== action.id) },
        byId: { [action.id]: { $set: undefined } },
      });
    case ADD_CARD_TEMPLATE:
      return update(state, {
        all: { $push: [action.id] },
        byId: {
          [action.id]: {
            $set: action.cardTemplate,
          },
        },
      });
    case ADD_CARD_TEMPLATE_FIELD_TEMPLATES:
      return update(state, {
        byId: {
          [action.id]: {
            fieldTemplates: {
              $set: updateIds(
                state.byId[action.id].fieldTemplates || [],
                action.fieldTemplateAll,
              ),
            },
          },
        },
      });
    case REMOVE_CARD_TEMPLATE_FIELD_TEMPLATES:
      return removeFieldTemplates(state, action);

    case UPDATE_CARD_TEMPLATE:
      return update(state, {
        byId: {
          [action.id]: {
            $set: {
              ...action.cardTemplate,
              fieldTemplates: state.byId[action.id].fieldTemplates,
            },
          },
        },
      });
    default:
      return state;
  }
}
