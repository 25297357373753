import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { SelectInput, AddCategoryForm, Action, Popout } from "_components";
import { attemptCreateCategory } from "_thunks/categories";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

class _Category extends PureComponent {
  state = {
    isCreating: false
  };

  _validated = false;

  handleSelect = (selected = {}) => {
    const { id, onChange } = this.props;
    const value = selected ? selected.value.id : null;
    onChange(id, value);
    this._validated = true;
  };

  toggleCreating = () => {
    this.setState({
      isCreating: !this.state.isCreating
    });
    this._validated = true;
  };

  handleSave = value => {
    this.handleSelect({ value });
    this._validated = true;
  };

  _handleOnFocus = () => {
    this._validated = false;
  };

  render() {
    const { categories, selected, isNested } = this.props;

    return [
      <SelectInput
        key={1}
        className="form-input-select"
        name="category"
        onChange={this.handleSelect}
        options={categories.map(category => ({
          value: category,
          label: category.name
        }))}
        placeholder="Choose category"
        value={selected}
      />,
      <div
        key={0}
        className="box-header"
        onBlur={this._handleOnBlur}
        onFocus={this._handleOnFocus}
      >
        {selected || isNested ? null : (
          <Action
            type="st-outline"
            icon={faPlus}
            iconSize="1x"
            onClick={this.toggleCreating}
          />
        )}
        <Popout show={this.state.isCreating}>
          <AddCategoryForm
            isNested
            handleSave={this.handleSave}
            onCancel={this.toggleCreating}
            createItem={this.props.createItem}
          />
        </Popout>
      </div>
    ];
  }
}

const mapStateToProps = (state, props) => {
  const selected = props.value
    ? state.categories.find(x => x.id === props.value)
    : null;
  return {
    categories: state.categories,
    selected: selected
      ? {
          label: selected.name,
          value: selected
        }
      : null
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    createItem: item => dispatch(attemptCreateCategory(item))
  };
};

export const CategoryField = connect(
  mapStateToProps,
  mapDispatchToProps
)(_Category);

export default { CategoryField };
