import React, { PureComponent } from 'react';
import { EditNavigationPrompt } from '_components';

export class Section extends PureComponent {
  state = {
    height: 400,
  };
  handleItemClick = (...params) => {
    this.props.handleItemClick(...params);
  };

  componentDidMount() {
    this.setState({
      height: this.elemHeight < 400 ? 400 : this.elemHeight,
    });
  }

  // componentWillUnmount() {
  //   console.log('unmount section');
  // }

  onHeightChange = newHeight => {
    this.setState({
      height: newHeight < 400 ? 400 : newHeight,
    });
  };

  renderTable = props => {
    const { isDirty, handleAddClick, TableComponent, activeItem } = props;

    return (
      <TableComponent
        isPanel
        isDirty={isDirty}
        handleRowClick={this.handleItemClick}
        handleAddClick={handleAddClick}
        activeItem={activeItem}
        height={this.state.height}
      />
    );
  };

  renderForm = ({
    handleAddCloseClick,
    handleAddSaveClick,
    isEditing,
    AddFormComponent,
    activeItem,
    isAdding,
  }) => {
    return (
      <AddFormComponent
        // isDialog
        isPanel
        id={activeItem}
        isAdding={isAdding}
        isEditing={isEditing}
        handleAddCloseClick={handleAddCloseClick}
        handleAddSaveClick={handleAddSaveClick}
      />
    );
  };
  render() {
    const {
      isAdding,
      isEditing,
      renderPrompt,
      activeItem,
      renderActiveItem,
      isDirty,
      afterConfirm,
      attemptToClose,
      toggleAttemptToClose,
    } = this.props;

    return (
      <React.Fragment>
        <EditNavigationPrompt
          when={isDirty}
          attemptToClose={attemptToClose}
          afterConfirm={afterConfirm}
          toggleAttemptToClose={toggleAttemptToClose}
          message="Are you sure you want to leave?"
        />

        <div className="section sixtiles-section">
          <div
            className="container"
            id="section-container"
            ref={elem => (this.elemHeight = elem && elem.clientHeight)}
          >
            <div className="sixtiles-panels">
              <div
                className={`section-panel table menu${isEditing
                  ? ' collaspe'
                  : ''}`}
              >
                <div className="item-wrapper">
                  {this.renderTable(this.props)}
                </div>
              </div>
              {activeItem || isAdding
                ? <div className="section-panel">
                    <div className="active-item">
                      {renderActiveItem()}
                    </div>
                  </div>
                : <div className="section-panel placeholder" />}
              {isAdding || isEditing
                ? <div
                    className={`section-panel ${isAdding
                      ? 'add-form'
                      : 'edit-form'}`}
                  >
                    <div className="item-wrapper">
                      {this.renderForm(this.props)}
                    </div>
                  </div>
                : null}
              {renderPrompt && renderPrompt()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default { Section };
