import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import createFilter from "./createFilter";
import { TextInput } from "_components";

export class Search extends Component {
  static propTypes = {
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    onChange: PropTypes.func,
    caseSensitive: PropTypes.bool,
    fuzzy: PropTypes.bool,
    throttle: PropTypes.number,
    filterKeys: PropTypes.oneOf([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    value: PropTypes.string,
    shouldOpen: PropTypes.bool,
    closeOnBodyClick: PropTypes.bool
  };

  static defaultProps = {
    className: "",
    onChange: () => {},
    caseSensitive: false,
    fuzzy: false,
    throttle: 200,
    icon: faSearch,
    shouldOpen: false,
    closeOnBodyClick: true
  };

  constructor(props) {
    super(props);
    this.state = {
      searchTerm: props.value || "",
      isActive: props.shouldOpen || false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      typeof nextProps.value !== "undefined" &&
      nextProps.value !== this.props.value
    ) {
      const e = {
        target: {
          value: nextProps.value
        }
      };
      this.updateSearch(e);
    }

    if (nextProps.value === "") {
      this.setState({ searchTerm: "" });
    }
  }

  componentDidMount() {
    if (this.props.closeOnBodyClick) {
      document.addEventListener("click", this.handleBodyClick, false);
    }
  }

  componentWillUnmount() {
    if (this.props.closeOnBodyClick) {
      document.removeEventListener("click", this.handleBodyClick, false);
    }
  }

  render() {
    const {
      className,
      onChange,
      caseSensitive,
      throttle,
      filterKeys,
      value,
      fuzzy,
      inputClassName,
      icon,
      children,
      ...inputProps
    } = this.props;

    inputProps.type = inputProps.type || "search";
    inputProps.value = this.state.searchTerm;
    inputProps.onChange = this.updateSearch;
    inputProps.className = classNames("input-search", inputClassName);
    inputProps.placeholder = inputProps.placeholder || "Search";

    const containerClassName = classNames("inline-search", className, {
      active: this.state.isActive
    });
    return (
      <div ref="container" className={containerClassName}>
        {this.props.icon}
        <TextInput {...inputProps} />
        {children}
      </div>
    );
  }

  toggleSearch = e => {
    e.preventDefault();
    // if hiding the search form clear the input
    this.setState({ isActive: !this.state.isActive }, () => {
      if (!this.state.isActive) {
        this.updateSearch({
          target: {
            value: ""
          }
        });
      }
    });
  };

  handleBodyClick = event => {
    if (this.refs.container.contains(event.target)) {
      return;
    }
    this.setState({ isActive: false });
    this.updateSearch({
      target: {
        value: ""
      }
    });
  };

  updateSearch = e => {
    const searchTerm = e.target.value;
    this.setState(
      {
        searchTerm
      },
      () => {
        if (this._throttleTimeout) {
          clearTimeout(this._throttleTimeout);
        }

        this._throttleTimeout = setTimeout(
          () => this.props.onChange(searchTerm),
          this.props.throttle
        );
      }
    );
  };

  filter = keys => {
    return createFilter(
      this.state.searchTerm,
      keys || this.props.filterKeys,
      this.props.caseSensitive,
      this.props.fuzzy
    );
  };
}

export default { Search };
