import { connect } from 'react-redux';
import { TileItemContainer } from './container';
import { withActiveItem } from '../../templates';
import {
  attemptUpdateTileTemplate,
  attemptDeleteTileTemplate,
} from '_thunks/tileTemplates';
import { attemptUpdateFormData } from '../../../store/thunks/views';
import {
  setPanel,
  clearPanel,
  updateSectionView,
  closeSectionForm,
} from '../../../store/actions/sectionView';

import { tileTemplateDefault as constraints } from '_api/constraints';

const mapDispatchToProps = dispatch => ({
  setPanel: panel => dispatch(setPanel('tileTemplate', panel)),
  createItem: item => dispatch(attemptUpdateTileTemplate(item, true)),
  updateItem: item => dispatch(attemptUpdateTileTemplate(item)),
  deleteItem: item => dispatch(attemptDeleteTileTemplate(item)),

  clearPanel: level => {
    dispatch(clearPanel('tileTemplate', level));
  },
  clearForm: () => {
    dispatch(closeSectionForm('tileTemplate'));
  },

  updateFormData: data => dispatch(attemptUpdateFormData('tileTemplate', data)),
  // clearForm: history => {
  //   history.push(
  //     history.location.pathname.replace(history.location.search, "")
  //   );
  // },
  updateSectionView: sectionView =>
    dispatch(updateSectionView('tileTemplate', sectionView)),
  setFormStatus: ({ status }) => {
    return dispatch(
      attemptUpdateFormData('tileTemplate', {
        status,
      }),
    );
  },
  // resetFormData: (data, props) => {
  //   dispatch(resetEditImage("pack"));

  //   dispatch(
  //     attemptUpdateFormData("packTemplate", {
  //       ...data,
  //       activeItem: {
  //         tileTemplateId: props.tileTemplateId,
  //         ...data.activeItem
  //       }
  //     })
  //   );
  // }
});

const mapStateToProps = (state, props) => {
  const { match: { params: { id } } } = props;
  return {
    method: state.sectionView.tileTemplate.method,
    isAdding:
      state.sectionView.tileTemplate.active === 'tileTemplate' &&
      state.sectionView.tileTemplate.method === 'add',
    isEditing:
      state.sectionView.tileTemplate.active === 'tileTemplate' &&
      state.sectionView.tileTemplate.method === 'edit',
    ...state.form.tileTemplate,
    newImage: state.editImage.tile,
    showActions:
      !state.sectionView.tileTemplate.active ||
      state.sectionView.tileTemplate.active === 'tileTemplate',
    dispatch: props.dispatch,
    formKey: 'tileTemplate',
    addComponent: 'addTileTemplateForm',
    itemCloseUrl: `/tile-templates`,
    id,
    item: state.tileTemplates.find(x => x.id === id),
    constraints,
    tileTemplateId: id,
    formData: state.form.tileTemplate.data,
    editData: state.form.tileTemplate.data,
    isActive: state.sectionView.tileTemplate.active === 'tileTemplate',
    deleteLabel: 'Tile Template',
  };
};

export const TileItem = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withActiveItem(TileItemContainer), { constraints });

export default { TileItem };
