// import { validate } from 'validate.js';
import { normalizePackTemplate } from "../normalise/tileTemplates";

export const SET_PACK_TEMPLATES = "SET_PACK_TEMPLATES";
export const REMOVE_PACK_TEMPLATE = "REMOVE_PACK_TEMPLATE";
export const UPDATE_PACK_TEMPLATE = "UPDATE_PACK_TEMPLATE";
export const ADD_PACK_TEMPLATE = "ADD_PACK_TEMPLATE";
export const ADD_TILE_TEMPLATE_PACK_TEMPLATE =
  "ADD_TILE_TEMPLATE_PACK_TEMPLATE";

export function setPackTemplates(packTemplates) {
  return {
    type: SET_PACK_TEMPLATES,
    packTemplates
  };
}

export const addPackTemplate = packTemplate => ({
  type: ADD_PACK_TEMPLATE,
  id: packTemplate.id,
  packTemplate
});

export const addTileTemplatePackTemplate = (id, packTemplate = {}) => ({
  type: ADD_TILE_TEMPLATE_PACK_TEMPLATE,
  id,
  packTemplateId: packTemplate.id
});

export const removePackTemplate = item => ({
  type: REMOVE_PACK_TEMPLATE,
  ...item
});

export const updatePackTemplate = packTemplate => ({
  type: UPDATE_PACK_TEMPLATE,
  id: packTemplate.id,
  packTemplate: normalizePackTemplate(packTemplate)
});
