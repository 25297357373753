import { ColourPicker, CollectionField, CategoryField } from "_components";
export const fields = ({
  validation,
  data,
  validated,
  handleFieldChange,
  handleOnValidate,
  handleSelectChange,
  handleColourChange,
  hideCollection,
  isNested
}) => [
  {
    component: CollectionField,
    name: "Collection",
    id: "collectionId",
    value: data.collectionId,
    onChange: handleSelectChange,
    isDisabled: isNested,
    hide: hideCollection,
    validated: validated,
    validation: validation.collectionId
  },
  {
    component: CategoryField,
    name: "Category",
    id: "categoryId",
    value: data.categoryId,
    onChange: handleSelectChange,
    validated: validated,
    validation: validation.categoryId,
    isNested
  },
  {
    type: "row",
    id: "colourRow",
    formGroups: [
      {
        type: "column",
        component: ColourPicker,
        name: "Hex",
        id: "hexColour",
        value: data.hexColour,
        onChange: handleColourChange,
        onValidate: handleOnValidate,
        validated: validated,
        validation: validation.hexColour,
        width: 4
      },
      {
        type: "column",
        component: ColourPicker,
        name: "Noun",
        id: "nounHexColour",
        value: data.nounHexColour,
        onChange: handleColourChange,
        onValidate: handleOnValidate,
        validated: validated,
        validation: validation.nounHexColour,
        width: 4
      },
      {
        type: "column",
        component: ColourPicker,
        name: "Text",
        id: "textHexColour",
        value: data.textHexColour,
        onChange: handleColourChange,
        onValidate: handleOnValidate,
        validated: validated,
        validation: validation.textHexColour,
        width: 4
      }
    ]
  }
];
