import { createTileTemplate } from "./tileTemplates";
import { updateBatchData } from "_actions/batch";
import {
  startBatchJob,
  endBatchJob,
  updateBatchJobProgress,
  clearBatchJob as clearBatchJobAction,
  updateBatchDuplicates as updateBatchDuplicatesAction,
  updateBatchNouns
} from "../actions/batch";

export const attemptBatchCreate = ({ nouns, common }) => dispatch => {
  const actions = [];

  nouns.forEach(noun => {
    const element = {
      noun,
      ...common
    };
    actions.push(dispatch(createTileTemplate(element)));
  });

  actions.forEach((action, i) =>
    action
      .then(resp => {
        dispatch(updateBatchJobProgress("success", resp.noun));
      })
      .catch(e => {
        dispatch(updateBatchJobProgress("failure", nouns[i]));
      })
  );

  dispatch(startBatchJob());
  return Promise.all(actions)
    .catch(e => {})
    .finally(() => endBatchJob());
};

export const clearBatchJob = () => dispatch => dispatch(clearBatchJobAction());

export const attemptUpdateBatchData = (key, data) => dispatch => {
  dispatch(updateBatchData(key, data));
};

export const attemptUpdateBatchNouns = (nouns, titleCase) => (
  dispatch,
  getState
) => {
  const { tileTemplates } = getState();

  let { displayNouns, duplicateNouns } = removeDuplicates(
    [...new Set(nouns)],
    tileTemplates
  );

  displayNouns = titleCase ? titleCaseValues(displayNouns) : displayNouns;

  dispatch(updateBatchNouns({ nouns, displayNouns, duplicateNouns }));
};

export const updateBatchDuplicates = titleCase => (dispatch, getState) => {
  const { tileTemplates, batch } = getState();
  const nouns = [...new Set(batch.displayNouns)];

  const { displayNouns, duplicateNouns } = removeDuplicates(
    nouns,
    tileTemplates
  );
  dispatch(
    updateBatchDuplicatesAction(
      titleCase ? titleCaseValues(displayNouns) : displayNouns,
      duplicateNouns
    )
  );
};

const removeDuplicates = (nouns, tileTemplates) => {
  const duplicateNouns = [];

  const displayNouns = nouns.filter(
    noun =>
      !tileTemplates.find(x => {
        const found = x.noun.toLowerCase() === noun.toLowerCase();
        if (found) duplicateNouns.push(noun);
        return found;
      })
  );
  return { displayNouns, duplicateNouns };
};

const titleCaseValues = displayNouns => {
  return displayNouns.map(displayNoun => toTitleCase(displayNoun));
};

const toTitleCase = function(str) {
  str = str.toLowerCase().split(" ");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
};
