import moment from 'moment';

export const parseTimestamp = (timestamp, format) => {
  if (!timestamp) return null;
  let momentFormat;

  switch (format) {
    case 'year':
      momentFormat = 'DD-MM-YYYY';
      break;
    default:
      momentFormat = null;
      break;
  }

  const date = moment(timestamp);
  return date.isValid() ? date.format(momentFormat) : null;
};

export const getLastUpdatedTimestamp = () => {
  return moment.utc().valueOf();
};

export default { parseTimestamp, getLastUpdatedTimestamp };
