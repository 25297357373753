import { connect } from 'react-redux';
import { attemptGetUsers } from '_thunks/users';
import { UsersPageContainer } from './usersPageContainer';

const mapDispatchToProps = dispatch => ({
  getUsers: () => dispatch(attemptGetUsers()),
});

export const UsersPage = connect(
  null,
  mapDispatchToProps,
)(UsersPageContainer);

export default { UsersPage };
