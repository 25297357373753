import { connect } from 'react-redux';
import { CollectionCard as _CollectionCard } from './card';
import { attemptDeleteCollection } from '_thunks/collections';
export * from './card';

const mapDispatchToProps = (dispatch, { data }) => ({
  deleteCollection: () => dispatch(attemptDeleteCollection(data.id)),
});

export const CollectionCard = connect(
  null,
  mapDispatchToProps,
)(_CollectionCard);
