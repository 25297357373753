import validate from 'validate.js';
import { collectionConstraints } from './constraints';
import { request } from '_utils/api';

export const getCollections = () => request('get', '/collection');

export const getCollection = id => request('get', `/collection/${id}`);

export const createCollection = collection => {
  const validationErrors = validate(collection, collectionConstraints['POST']);
  if (validationErrors)
    return Promise.reject({ status: 100, data: validationErrors });

  return request('post', `/collection`, { body: collection });
};

export const updateCollection = collection => {
  const validationErrors = validate(collection, collectionConstraints['PUT']);
  if (validationErrors)
    return Promise.reject({ status: 100, data: validationErrors });

  return request('put', `/collection/${collection.id}`, { body: collection });
};

export const deleteCollection = id => request('del', `/collection/${id}`);

export const getCollectionTileTemplates = id =>
  request('get', `/collection/${id}/tileTemplate`);
