import { connect } from 'react-redux';
// import R from '_utils/ramda';
// import { attemptGetCollections } from '_thunks/collections';
import { AdminsPageContainer } from './adminsPageContainer';

// const mapStateToProps = R.pick(['collections']);

const mapDispatchToProps = dispatch => ({
  // getCollections: () => dispatch(attemptGetCollections()),
});

export const AdminsPage = connect(
  null,
  mapDispatchToProps,
)(AdminsPageContainer);

export default { AdminsPage };
