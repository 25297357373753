import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import BatchImportButton from './batchImportButton';

export const toolbarActions = (handleAddClick, handleBatchAddClick) => [
  {
    title: 'Create tile template',
    onClick: handleAddClick,
    icon: <AddIcon />,
  },
  {
    title: 'Batch Create tile template',
    onClick: handleBatchAddClick,
    ownComponent: true,
    _component: BatchImportButton,
  },
];

export const selectToolbarActions = (
  handleActivateClick,
  handleClearAllSelectedClick,
  props,
) => [
  {
    title: 'Clear All',
    onClick: handleClearAllSelectedClick,
    props: props,
    icon: <CloseIcon />,
  },
  {
    title: 'Update Status',
    onClick: handleActivateClick,
    props: props,
    label: 'Update Status',
  },
];

export default { toolbarActions, selectToolbarActions };
