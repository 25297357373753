import React from 'react';

export function CategoriesField({
  handleCollectionTypeChange,
  collectionTypes,
  data,
}) {
  return (
    <div className="box-header">
      <span className="box-subtitle">Categories</span>
      {/* <Action
                          type="st-outline"
                          icon={faPlus}
                          iconSize="1x"
                          onClick={toggleSearchingCategories}
                        /> */}
    </div>
  );
}
