import R from "_utils/ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withAuthenticator } from "aws-amplify-react/dist/Auth";
import Amplify from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import Api from "@aws-amplify/api";
import SharedApi from "shared-code/lib/utils/api";
import {
  API_HOST,
  AUTH_USER_POOL_ID,
  AUTH_USER_POOL_WEB_CLIENT_ID,
  PUBLIC_API_HOST
} from "_utils/env";
import { attemptSetCurrentUser } from "_thunks/user";

import { MainContainer } from "./mainContainer";

Amplify.configure({
  Auth: {
    userPoolId: AUTH_USER_POOL_ID,
    userPoolWebClientId: AUTH_USER_POOL_WEB_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "sixtilesAPI",
        endpoint: API_HOST
      },
      {
        name: "sixtilesPublicAPI",
        endpoint: PUBLIC_API_HOST
      }
    ]
  }
});

SharedApi.configure({ Auth, Api });

const mapStateToProps = R.pick(["notifications"]);

const mapDispatchToProps = dispatch => ({
  setCurrentUser: () => dispatch(attemptSetCurrentUser())
});

export const Main = connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthenticator(withRouter(MainContainer)));

export default { Main };
