import { connect } from 'react-redux';
import { addHOC, AddForm } from '../../form';
import { attemptUpdateFormData } from '_thunks/views';
import { fields, formChildren } from './fields';
import { collectionDefault as constraints } from '_api/constraints';

const mapStateToProps = (state, props) => {
  const activeItem = state.collections.find(x => x.id === props.id);
  return {
    domain: 'collection',
    collectionTypes: state.collectionTypes,
    ...state.form.collection,
    activeItem: { ...activeItem, typeId: activeItem.collectionTypeId },
  };
};

const mapStateToDispatch = dispatch => ({
  updateFormData: data => dispatch(attemptUpdateFormData('collection', data)),
  resetFormData: data => dispatch(attemptUpdateFormData('collection', data)),
  handleTileTemplatesChange: (key, tileTemplates) =>
    dispatch(
      attemptUpdateFormData('collection', {
        children: true,
        [key]: tileTemplates,
      }),
    ),
});

export const AddCollectionForm = connect(mapStateToProps, mapStateToDispatch)(
  addHOC(AddForm, { fields, formChildren, title: 'Collection', constraints }),
);

export default { AddCollectionForm };
