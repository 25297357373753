import React from "react";
import { Toolbar } from "_components";
import actions from "./actions";

function renderCustomToolbar(handleAddClick) {
  return <Toolbar actions={actions(handleAddClick)} />; // component
}

export default (handleRowClick, handleAddClick) => ({
  filterType: "checkbox", // Choice of filtering view. Options are "checkbox", "dropdown", "multiselect" or "textField"
  responsive: "scrollMaxHeight", // Enable/disable responsive table views. Options: 'stacked', 'scroll'
  page: 0, // starting page
  rowsSelected: [], // selected rows
  rowsPerPage: 100,
  pagination: true,
  selectableRows: "none",
  resizableColumns: false,
  expandableRows: false,
  customToolbar: () => {
    return renderCustomToolbar(handleAddClick);
  },
  caseSensitive: false, // search case sensitive
  rowHover: false,
  sortFilterList: true, // enable disable sorting of filter lists
  sort: true, // enable/disable sort on all columns
  filter: true, // Show/hide filter icon from toolbar
  search: true, // Show/hide search icon from toolbar (we use custom one in navbar)
  print: false, // Show/hide print	icon from toolbar
  download: false, // Show/hide download icon from toolbar
  viewColumns: true, //	Show/hide viewColumns icon from toolbar
  onRowClick: handleRowClick // Callback function that triggers when a row is clicked.
});
