import React from "react";
import { Toolbar } from "_components";
import actions from "./actions";

// function(dataIndex) => bool
function isRowSelectable(dataIndex) {
  return true;
}

function renderCustomToolbar() {
  return <Toolbar actions={actions} />; // component
}

// function(selectedRows, displayData, setSelectedRows) => void
// function renderCustomSelectedRowsToolbar(
//   selectedRows,
//   displayData,
//   setSelectedRows,
// ) {
//   return true;
// }

// function(count, page, rowsPerPage, changeRowsPerPage, changePage) => string| React Component
// function renderCustomFooter(
//   count,
//   page,
//   rowsPerPage,
//   changeRowsPerPage,
//   changePage,
// ) {
//   return null;
// }

// function(currentRowsSelected: array, allRowsSelected: array) => void
function onRowsSelect(currentRowsSelected, allRowsSelected) {
  return;
}

// function(rowsDeleted: array) => void
function onRowsDelete(rowsDeleted) {
  return;
}

// function(colData: any, cellMeta: { colIndex: number, rowIndex: number, dataIndex: number }) => void
function onCellClick(colData, cellMeta) {
  return;
}

// function(currentPage: number) => void
function onChangePage(currentPage) {
  return;
}

// function(numberOfRows: number) => void
function onChangeRowsPerPage(numberOfRows) {
  return;
}

// function(searchText: string) => void
function onSearchChange(searchText) {
  return;
}

// function(changedColumn: string, filterList: array) => void
function onFilterChange(changedColumn, filterList) {
  return;
}

// function(changedColumn: string, direction: string) => void
function onColumnSortChange(changedColumn, direction) {
  return;
}

// function(changedColumn: string, action: string) => void
function onColumnViewChange(changedColumn, action) {
  return;
}

// function(action: string, tableState: object) => void
function onTableChange(action, tableState) {
  return;
}

// function(row: array, rowIndex: number) => object
// function setRowProps(row, rowIndex) {
//   return null;
// }

export default () => ({
  filterType: "checkbox", // Choice of filtering view. Options are "checkbox", "dropdown", "multiselect" or "textField"
  responsive: "scrollMaxHeight", // Enable/disable responsive table views. Options: 'stacked', 'scroll'
  page: 0, // starting page
  rowsSelected: [], // selected rows
  rowsPerPage: 100,
  pagination: true,
  selectableRows: "none",
  isRowSelectable, // Enable/disable selection on certain rows with custom function. Returns true if not provided.
  resizableColumns: false,
  expandableRows: false,
  customToolbar: renderCustomToolbar,
  // customToolbarSelect: renderCustomSelectedRowsToolbar, // Render a custom selected rows toolbar
  // customFooter: renderCustomFooter,
  caseSensitive: false, // search case sensitive
  rowHover: false,
  sortFilterList: true, // enable disable sorting of filter lists
  sort: true, // enable/disable sort on all columns
  filter: true, // Show/hide filter icon from toolbar
  search: true, // Show/hide search icon from toolbar (we use custom one in navbar)
  print: false, // Show/hide print	icon from toolbar
  download: false, // Show/hide download icon from toolbar
  downloadOptions: { filename: "sixtiles-collections.csv", separator: "," }, // Options to change the output of the CSV file
  viewColumns: true, //	Show/hide viewColumns icon from toolbar
  onRowsSelect, // Callback function that triggers when row(s) are selected.
  onRowsDelete, // Callback function that triggers when row(s) are deleted.
  // onRowClick: handleRowClick, // Callback function that triggers when a row is clicked.
  onCellClick, // Callback function that triggers when a cell is clicked.
  onChangePage, // Callback function that triggers when a page has changed.
  onChangeRowsPerPage, // Callback function that triggers when the number of rows per page has changed.
  onSearchChange, // Callback function that triggers when the search text value has changed.
  // onSearchOpen,	// Callback function that triggers when the searchbox opens.
  onFilterChange, // Callback function that triggers when filters have changed.
  onColumnSortChange, // Callback function that triggers when a column has been sorted.
  onColumnViewChange, // Callback function that triggers when a column view has been changed.
  onTableChange // Callback function that triggers when table state has changed.
  // setRowProps, // called for each row and allows to return custom props for this row based on its data.
});
