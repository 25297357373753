import { request } from '_utils/api';
import { getLastUpdatedTimestamp } from '../utils/date';

export const getCardTemplates = ({ tileTemplateId, packTemplateId }) =>
  request(
    'get',
    `/tileTemplate/${tileTemplateId}/packtemplate/${packTemplateId}/cardtemplate`,
  );

export const createCardTemplate = data => {
  const { tileTemplateId, packTemplateId } = data;
  const body = {
    ...data,
    fieldTemplates: undefined,
    priority: parseInt(data.priority),
    sequence: parseInt(data.sequence),
    lastUpdated: getLastUpdatedTimestamp(),
  };
  return request(
    'post',
    `/tileTemplate/${tileTemplateId}/packtemplate/${packTemplateId}/cardtemplate`,
    { body },
  );
};

export const updateCardTemplate = data => {
  /* eslint-disable-next-line */
  const { tileTemplateId, packTemplateId, id, fieldTemplates, ...rest } = data;
  if (!tileTemplateId || !packTemplateId || !id)
    return Promise.reject({ data: { message: 'missing ids' } });
  return request(
    'put',
    `/tileTemplate/${tileTemplateId}/packtemplate/${packTemplateId}/cardtemplate/${id}`,
    {
      body: {
        ...data,
        fieldTemplates: undefined,
        priority: parseInt(rest.priority),
        sequence: parseInt(rest.sequence),
        lastUpdated: getLastUpdatedTimestamp(),
      },
    },
  );
};

export const deleteCardTemplate = ({ tileTemplateId, packTemplateId, id }) =>
  request(
    'del',
    `/tiletemplate/${tileTemplateId}/packtemplate/${packTemplateId}/cardtemplate/${id}`,
  );
