import R from '_utils/ramda';
import { connect } from 'react-redux';
import NavbarUserMenu from './userMenu';
import { attemptLogout } from '_thunks/user';

const mapStateToProps = R.pick(['user']);

const mapDispatchToProps = (dispatch, { data }) => ({
  logout: () => dispatch(attemptLogout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavbarUserMenu);
