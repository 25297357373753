import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { InvalidMessagePopover } from '_components';

export function TextInput({
  onChange,
  isValid,
  onBlur,
  onFocus,
  value,
  placeholder,
  id,
  validated,
  showError,
  validation,
  domain,
  inputType,
  min,
  max,
  step,
}) {
  const classes = classNames({
    'form-input': true,
    // 'is-success': validated && value && isValid,
    'is-danger': validated && !isValid,
  });

  return (
    <React.Fragment>
      <input
        className={classes}
        id={`${domain}-${id}`}
        placeholder={placeholder}
        type={inputType}
        value={inputType === 'text' && !value ? '' : value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        min={min}
        max={max}
        step={step}
      />
      {showError
        ? <InvalidMessagePopover validation={validated && validation} />
        : null}
    </React.Fragment>
  );
}

TextInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.any,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  isValid: PropTypes.bool,
  validated: PropTypes.bool,
  showError: PropTypes.bool,
  domain: PropTypes.string,
  inputType: PropTypes.string,
};

TextInput.defaultProps = {
  value: '',
  placeholder: '',
  onBlur: undefined,
  onFocus: undefined,
  isValid: false,
  validated: false,
  showError: true,
  domain: 'form',
  inputType: 'text',
};

export default { TextInput };
