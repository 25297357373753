import { connect } from "react-redux";
import {
  attemptDeleteCategory,
  attemptUpdateCategory,
  attemptCreateCategory
} from "_thunks/categories";
import { withRouter } from "react-router";
import { attemptUpdateFormData } from "_thunks/views";
import { categoryDefault as constraints } from "_api/constraints";
import { withFieldActions } from "../../form";
import { sectionHOC, Section } from "../../templates/section";
import { SummaryRow } from "../../table";
import { CategoryTable } from "../table";
import { AddCategoryForm } from "../form";

const mapStateToProps = state => ({
  isDirty: state.form.category.isDirty,
  items: state.categories,
  isValid: state.form.category.isValid,
  validated: state.form.category.validated,
  formData: state.form.category.data,
  getData: id => state.categories[id],
  editData: state.form.category.data,
  constraints
});

const mapDispatchToProps = dispatch => ({
  updateFormData: data => dispatch(attemptUpdateFormData("category", data)),
  deleteItem: id => dispatch(attemptDeleteCategory(id)),
  updateItem: data => dispatch(attemptUpdateCategory(data)),
  createItem: data => dispatch(attemptCreateCategory(data)),
  resetFormData: data =>
    dispatch(
      attemptUpdateFormData("category", {
        reset: true
      })
    )
});

const itemSelector = data => {
  return {
    title: data.name,
    subtitle: data.text
  };
};

export const CategoriesSection = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withFieldActions(
      sectionHOC(Section, {
        ItemComponent: SummaryRow,
        TableComponent: CategoryTable,
        AddFormComponent: AddCategoryForm,
        routePrefix: "/categories",
        key: "Category",
        dataKey: "categories",
        formKey: "category",
        // metaActions,
        constraints,
        itemSelector
      })
    )
  )
);

export default { CategoriesSection };
