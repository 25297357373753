import React from 'react';
import PropTypes from 'prop-types';
import { CollectionCard } from '_components';

export function CollectionGrid({ collections, handleCardClick }) {
  if (!collections || collections.length === 0) return null;

  return (
    <div className="container collection-grid">
      <div className="columns">
        {collections.map((collection, i) => (
          <div key={i} className="column col-4 col-xs-12">
            <CollectionCard
              handleCardClick={handleCardClick}
              data={collection}
              data-index={i}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

CollectionGrid.propTypes = {
  collections: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleCardClick: PropTypes.func.isRequired,
};

CollectionGrid.defaultProps = {
  collections: [],
};

export default { CollectionGrid };
