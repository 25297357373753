import React from 'react';
import { Prompt } from '_components';
import NavigationPrompt from 'react-router-navigation-prompt';

export function EditNavigationPrompt(props) {
  if (props.attemptToClose) {
    return (
      <Prompt
        show={props.attemptToClose}
        onOk={props.afterConfirm}
        onCancel={props.toggleAttemptToClose}
        okText="YES"
        title="Leave unsaved changes"
        content="You have unsaved changes, do you wish to leave."
      />
    );
  }
  return (
    <NavigationPrompt when={props.when} afterConfirm={props.afterConfirm}>
      {({ isActive, onConfirm, onCancel }) => (
        <Prompt
          show={isActive || props.attemptToClose}
          onOk={onConfirm}
          onCancel={onCancel}
          okText="YES"
          title="Leave unsaved changes"
          content="You have unsaved changes, do you wish to leave."
        />
      )}
    </NavigationPrompt>
  );
}
