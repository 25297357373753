import { connect } from "react-redux";
import { TileContainer } from "./container";

export { withRouter } from "react-router";

function mapStateToProps(state, props) {
  // if (props.formKey && (props.isEditing || props.isAdding)) {
  //   return {
  //     data: state.form[props.formKey].data,
  //   };
  // }
  if (props.isEditing || props.isAdding) return {};
  if (!props.dataKey) return {};

  const data = state[props.dataKey].find(x => x.id === props.id) || {};
  return {
    data
  };
}

const mapDispatchToProps = dispatch => ({});

export const Tile = connect(mapStateToProps, mapDispatchToProps)(TileContainer);

export default { Tile };
