import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '_components';

export function FormFooter({
  handleCloseClick,
  handleSaveClick,
  saveLabel,
  closeLabel,
  classes,
  isValid,
}) {
  return (
    <div className={classNames('form-footer', classes)}>
      <Button
        onClick={handleCloseClick}
        className="secondary"
        label={closeLabel}
      />
      <Button onClick={handleSaveClick} disabled={!isValid} label={saveLabel} />
    </div>
  );
}

FormFooter.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  hasActionButtons: PropTypes.bool,
  handleSaveClick: PropTypes.func,
  saveLabel: PropTypes.string,
  closeLabel: PropTypes.string,
  handleCloseClick: PropTypes.func,
  isValid: PropTypes.bool,
};

FormFooter.defaultProps = {
  classes: null,
  saveLabel: 'Save',
  closeLabel: 'Cancel',
  hasActionButtons: true,
  handleSaveClick: undefined,
  handleCloseClick: undefined,
  isValid: true,
};

export default { FormFooter };
