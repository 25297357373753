import React from "react";
import PropTypes from "prop-types";
import { FormBody, FormHeader, FormFooter } from "_components";

export class AddForm extends React.Component {
  handleSaveClick = () => {
    if (!this.props.isValid) return this.props.handleValidateAll();
    this.props.handleAddSaveClick(this.props.data);
  };

  render() {
    const {
      handleCloseClick,
      isPanel,
      hasFooter,
      hasHeader,
      className,
      headerProps,
      footerProps
    } = this.props;

    return (
      <div
        className={
          className
            ? `${className} add-form container grid-lg`
            : ` add-form container grid-lg ${isPanel ? "form-panel" : null}`
        }
      >
        <div className="columns col-gapless">
          <div className="column">
            {hasHeader ? (
              <FormHeader
                handleCloseClick={handleCloseClick}
                {...this.props}
                {...headerProps}
                handleSaveClick={this.handleSaveClick}
              />
            ) : null}
            <FormBody handleCloseClick={handleCloseClick} {...this.props} />
            {hasFooter ? (
              <FormFooter
                handleCloseClick={handleCloseClick}
                {...this.props}
                {...footerProps}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

AddForm.propTypes = {
  className: PropTypes.string,
  handleSaveClick: PropTypes.func,
  handleCloseClick: PropTypes.func,
  footerProps: PropTypes.object,
  headerProps: PropTypes.object,
  hasFooter: PropTypes.bool,
  hasHeader: PropTypes.bool
};

AddForm.defaultProps = {
  footerProps: {},
  headerProps: {},
  handleSaveClick: undefined,
  handleCloseClick: undefined,
  hasHeader: false,
  hasFooter: false,
  className: ""
};

export default { AddForm };
