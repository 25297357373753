import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TileTemplatesPageInner } from './tileTemplatesPage';
import LinearProgress from '@material-ui/core/LinearProgress';

export class TileTemplatesPageContainer extends PureComponent {
  static propTypes = {
    getTileTemplates: PropTypes.func.isRequired,
    getCollections: PropTypes.func.isRequired,
    getCategories: PropTypes.func.isRequired,
  };

  state = {
    loading: {
      tileTemplates: true,
      collections: true,
      categories: true,
    },
  };

  componentDidMount() {
    const { getTileTemplates, getCollections, getCategories } = this.props;

    getTileTemplates()
      .then(() =>
        this.setState({
          loading: { ...this.state.loading, tileTemplates: false },
        }),
      )
      .catch(() => {
        this.setState({
          loading: { ...this.state.loading, tileTemplates: false },
        });
      });

    getCollections()
      .then(() =>
        this.setState({
          loading: { ...this.state.loading, collections: false },
        }),
      )
      .catch(() => {
        this.setState({
          loading: { ...this.state.loading, collections: false },
        });
      });

    getCategories()
      .then(() =>
        this.setState({
          loading: { ...this.state.loading, categories: false },
        }),
      )
      .catch(() => {
        this.setState({
          loading: { ...this.state.loading, categories: false },
        });
      });
  }

  render() {
    let { match } = this.props;

    return (
      <React.Fragment>
        {!Object.values(this.state.loading).every(item => item === false)
          ? <LinearProgress />
          : <TileTemplatesPageInner
              tileTemplates={this.props.tileTemplates}
              id={this.props.match.params.id}
              match={match}
            />}
      </React.Fragment>
    );
  }
}

export default { TileTemplatesPageContainer };
