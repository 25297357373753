import update from 'immutability-helper';

import { SET_COLLECTIONS } from '_actions/collections';
import { SET_COLLECTION_TYPES } from '_actions/collectionTypes';
import { SET_TILE_TEMPLATES } from '_actions/tileTemplates';
import { SET_CATEGORIES } from '_actions/categories';
import { SET_USERS } from '_actions/users';

const initialState = {
  collections: false,
  tileTemplates: false,
  collectionTypes: false,
  users: false,
  categories: false,
};

export default function reducer(state = initialState, action) {
  let key = null;
  switch (action.type) {
    case SET_COLLECTIONS:
      key = 'collections';
      break;
    case SET_TILE_TEMPLATES:
      key = 'tileTemplates';
      break;
    case SET_COLLECTION_TYPES:
      key = 'collectionTypes';
      break;
    case SET_CATEGORIES:
      key = 'categories';
      break;
    case SET_USERS:
      key = 'users';
      break;
    default:
      return state;
  }
  return update(state, {
    [key]: {
      $set: true,
    },
  });
}
