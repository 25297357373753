import React from 'react';
import { CategoriesSection } from '_components/category';

export function CategoriesPage({ id }) {
  return (
    <div className="page-wrapper categories">
      <div className="container grid-lg" id="page-container">
        <div className="columns col-gapless">
          <CategoriesSection id={id} />
        </div>
      </div>
    </div>
  );
}

export default { CategoriesPage };
