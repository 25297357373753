import React, { PureComponent } from "react";
import classNames from "classnames";

export class CardTemplateItemContainer extends PureComponent {
  state = {
    isAdding: false,
    isEditing: false,
    activeItem: null
  };

  viewCard = () => {
    const { id, tileTemplateId, packTemplateId, history } = this.props;
    history.push(
      `/tile-templates/${tileTemplateId}/packs/${packTemplateId}/cards/${id}`
    );
  };
  editCard = () => {
    this.props.onEditClick(this.props.id);
  };

  render() {
    const { name, isActive, imgurlCustom } = this.props;

    return (
      <div
        className={classNames("pack-template", { active: isActive })}
        onClick={this.viewCard}
      >
        <div
          className="pack-template-img"
          style={{ backgroundImage: `url(${imgurlCustom})` }}
          alt=""
        >
          <div className="pack-template-img-overlay" />
        </div>
        <div className="pack-template-name">{name}</div>
      </div>
    );
  }
}
