import { validate } from 'validate.js';
import { categoryDefault as constraints } from '_api/constraints';

export const SET_CATEGORIES = 'SET_CATEGORIES';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const REMOVE_CATEGORY = 'REMOVE_CATEGORY';

export function setCategories(categories) {
  return {
    type: SET_CATEGORIES,
    categories: categories.filter(item => {
      return !validate(item, constraints);
    }),
  };
}

export const addCategory = data => ({
  type: ADD_CATEGORY,
  id: data.id,
  category: data,
});

export const updateCategory = data => ({
  type: UPDATE_CATEGORY,
  id: data.id,
  category: data,
});

export const removeCategory = id => ({
  type: REMOVE_CATEGORY,
  id,
});
