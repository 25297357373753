import React from 'react';
import { UsersSection } from '_components/user';

export function UsersPage() {
  return (
    <div className="users">
      <div className="container grid-lg">
        <div className="columns col-gapless">
          <UsersSection />
        </div>
      </div>
    </div>
  );
}

export default { UsersPage };
