import React from 'react';
import classNames from 'classnames';
import { Action } from '_components';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export function ListItem(props) {
  const {
    item,
    getItemClassName,
    idAccessor,
    nameAccessor,
    onItemClick,
    onItemRemove,
    itemClassName,
    removeIcon,
  } = props;
  const currentClassName = getItemClassName && getItemClassName(item);
  return (
    <li
      className={classNames(
        currentClassName,
        'list-item',
        { 'is-clickable': onItemClick },
        itemClassName,
      )}
      data-id={
        item[idAccessor] || (item[nameAccessor] ? item[nameAccessor][0] : null)
      }
      onClick={onItemClick}
    >
      <span className="list-icon">
        {item[nameAccessor] ? item[nameAccessor][0] : null}
      </span>
      <span className="list-label">{item[nameAccessor]}</span>
      {onItemRemove ? (
        <Action
          type="st-outline"
          icon={removeIcon || faTimes}
          iconSize="1x"
          onClick={() => {
            onItemRemove(item);
          }}
        />
      ) : null}
    </li>
  );
}

export default { ListItem };
