import { createMuiTheme } from '@material-ui/core/styles';

export default (height, isNested) =>
  createMuiTheme({
    overrides: {
      MUIDataTable: {
        responsiveScrollMaxHeight: {
          maxHeight: `${height} !important`,
          height: 'auto',
        },
      },
      MuiTableRow: {
        root: {
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#F4F8F9 !important',
          },
          '&.ActiveItemRow': {
            backgroundColor: '#F4F8F9 !important',
          },
        },
      },

      MUIDataTableSearch: {
        main: {
          opacity: '1 !important',
        },
      },

      MuiPaper: {
        root: {
          boxShadow: 'none !important',
          border: 'none !important',
          maxHeight: '100%',
          height: '100%',
        },
      },
      MUIDataTableToolbar: {
        root: {
          // position: 'absolute',
          // zIndex: 2000,
          // right: 0,
        },
        actions: {
          display: 'flex',
          flex: 1,
          flexWrap: 'nowrap',
          justifyContent: 'flex-end',
        },
      },
      MUIDataTableHeadCell: {
        root: {
          whiteSpace: 'nowrap',
        },
        toolButton: {
          flexWrap: 'nowrap',
          flexShrink: 0,
          display: 'flex',
        },
      },
      MUIDataTableSelectCell: {
        root: {
          display: 'none', // hides check box column to left when one or more cells is connected
        },
      },
      MUIDataTableToolbarSelect: {
        root: {
          justifyContent: 'flex-start',
        },
      },
      MUIDataTableBodyCell: {
        root: {
          padding: '4px 24px',
        },
      },
      MuiTablePagination: {
        root: {
          '&$selectIcon': {
            top: 0,
            paddingLeft: '5px',
          },
        },
        caption: {
          display: 'none',
        },
        actions: {
          marginLeft: 0,
        },
      },
      MuiMenu: {
        paper: {
          height: 'auto',
          maxHeight: 'auto',
        },
      },
      MUIDataTablePagination: {
        selectRoot: {
          padding: 0,
        },
        root: {
          '&:last-child': {
            padding: 0,
          },
        },
      },
    },
  });
