import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Section, UserTable, SummaryRow } from '_components';

export class UsersSectionContainer extends Component {
  state = {
    activeItem: null,
    isAdding: false,
  };

  handleAddClick = () => {
    this.setState({ isAdding: true });
  };

  handleItemClick = (rowData, rowMeta) => {
    let index;
    // table item
    if (rowMeta) {
      index = rowMeta.dataIndex;
    } else {
      // card
      index = rowData.currentTarget.dataset.index;
    }

    const activeItem = this.props.users[index];
    this.setState(
      { activeItem },
      () => {
        window.scrollTo(0, 0);
      },
      this.props.getData(activeItem.id),
    );
  };

  handleItemClose = () => {
    this.setState({ activeItem: null });
  };

  handleItemUpdate = () => {
    this.setState({ loading: { ...this.state.loading, activeItem: true } });
    this.props.updateItem(this.state.activeItem).then(() =>
      this.setState({
        loading: { ...this.state.loading, activeItem: false },
      }),
    );
  };

  handleItemDelete = () => {
    const { id } = this.state.activeItem;
    this.setState({ loading: { ...this.state.loading, activeItem: true } });
    this.props.deleteItem(id).then(() =>
      this.setState({
        loading: { ...this.state.loading, activeItem: false },
      }),
    );
  };

  handleAddCloseClick = () => {
    this.setState({ isAdding: false });
  };

  handleAddSaveClick = data => {
    this.setState({ isAdding: false });
  };

  summaryActions = () => {
    return [
      {
        onClick: this.handleItemUpdate,
        icon: faPencilAlt,
        label: 'Edit',
        title: 'Edit User',
      },
      {
        onClick: this.handleItemDelete,
        icon: faTrash,
        shouldConfirm: true,
        label: 'Delete',
        className: 'btn-error',
        title: 'Delete User',
      },
    ];
  };

  renderTitle = () => (
    <h1 className="headline">
      {this.state.isAdding ? 'Create User' : 'Users'}
    </h1>
  );

  renderActiveItem = () => (
    <React.Fragment>
      <SummaryRow
        data={this.state.activeItem}
        titleSelector="name"
        subtitleSelector="text"
        imageSelector="imgurl"
        actions={this.summaryActions}
      />
    </React.Fragment>
  );

  render() {
    const { activeItem, isAdding } = this.state;
    return (
      <Section
        handleItemClick={this.handleItemClick}
        handleItemClose={this.handleItemClose}
        handleAddClick={this.handleAddClick}
        handleAddSaveClick={this.handleAddSaveClick}
        handleAddCloseClick={this.handleAddCloseClick}
        isAdding={isAdding}
        activeItem={activeItem}
        summaryActions={this.summaryActions()}
        renderActiveItem={this.renderActiveItem}
        renderTitle={this.renderTitle}
        TableComponent={UserTable}
        AddFormComponent={UserTable} /*placeholder component*/
      />
    );
  }
}

UsersSectionContainer.propTypes = {
  users: PropTypes.array.isRequired,
};

export default { UsersSectionContainer };
