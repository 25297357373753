import React, { Component } from 'react';
import { SummaryRow, BooleanField } from '_components';
import { DisplayTile } from 'shared-code';

class ItemRow extends Component {
  itemSelector = data => {
    return {
      title: data.noun,
      subtitle: data.text,
      image: data.imgurlDefault,
    };
  };

  renderImage = ({ data }) => {
    const { isActive, editData, newImage } = this.props;

    return (
      <DisplayTile
        item={
          isActive
            ? {
                ...editData,
                imgurlDefault: newImage.croppedImage || data.imgurlDefault,
              }
            : data
        }
      />
    );
  };

  metaActions = props => {
    return [
      {
        key: 0,
        component: BooleanField,
        name: 'Active',
        id: 'active',
        label: 'active',
      },
    ];
  };

  render() {
    const { isActive, editData, actions, renderPrompt } = this.props;

    return (
      <React.Fragment>
        {renderPrompt && renderPrompt()}
        <SummaryRow
          {...this.props}
          data={isActive ? editData : null}
          dataKey="tileTemplates"
          itemSelector={this.itemSelector}
          actions={actions}
          isValid={true}
          renderImage={this.renderImage}
          iconOnly
          formTitle="Tile Template"
          metaActions={this.metaActions(this.props)}
        />
      </React.Fragment>
    );
  }
}

export default ItemRow;
