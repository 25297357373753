import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Radio } from '_components';

class _TileTemplateActiveRadio extends Component {
  render() {
    const { isChecked } = this.props;

    return <Radio checked={isChecked} {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  const { rowsSelected } = state.tileTemplatesTable;

  return {
    isChecked: rowsSelected.includes(props.rowIndex)
      ? !props.value
      : props.value,
  };
};

export const TileTemplateActiveRadio = connect(mapStateToProps)(
  _TileTemplateActiveRadio,
);

export default { TileTemplateActiveRadio };
