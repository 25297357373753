import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CollectionsPage } from './collectionsPage';
import LinearProgress from '@material-ui/core/LinearProgress';

export class CollectionsPageContainer extends Component {
  static propTypes = {
    getCollections: PropTypes.func.isRequired,
    getCategories: PropTypes.func.isRequired,
  };

  state = {
    loading: {
      collections: true,
      categories: true,
    },
  };

  componentDidMount() {
    const { getCollections, getCategories, getCollectionTypes } = this.props;
    getCollections()
      .then(() =>
        this.setState({
          loading: { ...this.state.loading, collections: false },
        }),
      )
      .catch(() => {
        this.setState({
          loading: { ...this.state.loading, collections: false },
        });
      });

    getCollectionTypes();

    getCategories()
      .then(() =>
        this.setState({
          loading: { ...this.state.loading, categories: false },
        }),
      )
      .catch(() => {
        this.setState({
          loading: { ...this.state.loading, categories: false },
        });
      });
  }

  render() {
    return Object.values(this.state.loading).every(item => item === false) ? (
      <CollectionsPage id={this.props.match.params.id} />
    ) : (
      <LinearProgress />
    );
  }
}

export default { CollectionsPageContainer };
