import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { SummaryRow } from '_components';
import { CardPreview } from '../preview';

export class CardTemplateActiveItemContainer extends PureComponent {
  renderCardPreview = () => {
    const { editData, isActive, imgurlCustom, imgurl, newImage } = this.props;
    return (
      <CardPreview
        {...this.props}
        imgurlCustom={newImage.croppedImage || imgurlCustom || imgurl}
        data={isActive ? editData : this.props}
      />
    );
  };

  handleCopyCard = () => {
    const { clone, id, history, tileTemplateId, packTemplateId } = this.props;

    history.push(`/tile-templates/${tileTemplateId}/packs/${packTemplateId}`);
    clone(id);
  };

  getFormTitle = () => {
    const { isActive, editData } = this.props;
    const { name } = !isActive ? this.props : editData;
    return name ? `Card: ${name}` : 'Card';
  };

  getDuplicateButton = () => {
    const { isActive, isDirty, validated, isValid } = this.props;
    return {
      onClick: this.handleCopyCard,
      icon: faClone,
      className:
        (isActive && !isDirty) || (validated && !isValid)
          ? 'btn-save disable'
          : null,
      label: 'Clone',
      title: 'Clone Card',
    };
  };

  getActions = () => {
    const { isActive, actions } = this.props;
    if (isActive) return actions;

    return [this.getDuplicateButton(), ...actions];
  };

  render() {
    const { isActive, itemSelector, renderPrompt } = this.props;

    return (
      <div
        className={classNames('card-template pack-template', {
          active: isActive,
        })}
      >
        {renderPrompt && renderPrompt()}
        <SummaryRow
          {...this.props}
          dataKey="cardTemplate"
          formKey="cardTemplate"
          itemSelector={itemSelector}
          actions={this.getActions()}
          iconOnly={true}
          isValid={true}
          formTitle={this.getFormTitle()}
          hasBody={false}
        />
        {this.renderCardPreview()}
      </div>
    );
  }
}
