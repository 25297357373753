import React, { PureComponent } from 'react';
import {
  faPencilAlt,
  faSave,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { Prompt } from '_components';

export const withActiveItem = WrappedComponent => {
  class HOC extends PureComponent {
    state = {
      isAdding: false,
      isEditing: false,
      activeItem: null,
      loadingDelete: false,
      isDeleting: false,
    };

    handleOpenEditForm = () => {
      const { setPanel, updateSectionView, formKey, addComponent } = this.props;

      setPanel({
        domain: formKey,
        type: 'form',
        level: 'ternary',
        component: addComponent,
      });

      updateSectionView({
        active: formKey,
        method: 'edit',
      });
    };

    edit = item => {
      this.props.resetFormData(this.props);
      this.setState({ isAdding: false, isEditing: true, activeItem: item });
    };

    create = () => {
      this.props.resetFormData(this.props);
      this.setState({ isAdding: true, isEditing: false });
    };

    handleCreate = () => {
      const {
        createItem,
        formData,
        clearForm,
        history,
        formKey,
        tileTemplateId,
        packTemplateId,
      } = this.props;

      createItem(formData)
        .then(item => {
          clearForm();
          if (!item || !item.id) return;
          switch (formKey) {
            case 'tileTemplate':
              history.push(`/tile-templates/${item.id}`);
              break;
            case 'packTemplate':
              history.push(
                `/tile-templates/${tileTemplateId}/packs/${item.id}`,
              );
              break;
            case 'cardTemplate':
              history.push(
                `/tile-templates/${tileTemplateId}/packs/${packTemplateId}/cards/${item.id}`,
              );
              break;
            default:
              break;
          }
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    };

    handleEdit = () => {
      const { updateItem, clearForm, formData } = this.props;
      updateItem(formData)
        .then(item => {
          clearForm();
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    };

    handleAddSaveClick = () => {
      const { isAdding, isValid, handleValidateAll, isDirty } = this.props;
      if (isAdding && !isValid) return handleValidateAll && handleValidateAll();
      if (!isDirty || !isValid || this.state.loading) return;
      this.setState({ loading: true });

      if (isAdding) this.handleCreate();
      else this.handleEdit();
    };

    handleItemClose = () => {
      const { history, itemCloseUrl } = this.props;
      history.push(itemCloseUrl);
    };

    itemSelector = ({ name, imgurl, imgurlCustom, cardTemplates = [] }) => {
      return {};
    };

    handleItemCloseForm = () => {
      // might need to handle cloning use case
      const { isDirty, updateSectionView, clearPanel } = this.props;
      if (isDirty) updateSectionView({ attemptToClose: true });
      else {
        clearPanel('ternary');
        updateSectionView({
          active: null,
          method: null,
        });
      }
    };

    // delete
    getDeleteAction = () => {
      const { isActive, deleteLabel } = this.props;
      return {
        onClick: this.confirmItemDelete,
        icon: faTrash,
        label: 'Delete',
        className: isActive ? 'btn-error hide' : 'btn-error',
        loading: this.state.loadingDelete,
        title: `Delete ${deleteLabel}`,
        isNegAction: true,
      };
    };

    confirmItemDelete = () => {
      this.setState({ isDeleting: true });
    };

    handleCancelDelete = () => {
      this.setState({ isDeleting: false });
    };

    handleItemDelete = () => {
      const {
        id,
        history,
        deleteItem,
        formKey,
        tileTemplateId,
        packTemplateId,
        clearForm,
      } = this.props;
      this.setState({ loadingDelete: true, isDeleting: false });
      deleteItem({ id, tileTemplateId, packTemplateId })
        .then(() => {
          clearForm();
          switch (formKey) {
            case 'tileTemplate':
              history.push('/tile-templates');
              break;
            case 'packTemplate':
              history.push(`/tile-templates/${tileTemplateId}`, {
                packTemplateId: null,
                params: {
                  packTemplateId: null,
                },
              });
              break;
            case 'cardTemplate':
              history.push(
                `/tile-templates/${tileTemplateId}/packs/${packTemplateId}`,
              );
              break;
            default:
              break;
          }
        })
        .catch(() => {
          this.setState({ loadingDelete: false });
        });
    };

    renderPrompt = () =>
      <Prompt
        show={this.state.isDeleting}
        onOk={this.handleItemDelete}
        onCancel={this.handleCancelDelete}
        okText="Delete"
        title={`Delete ${this.props.deleteLabel}`}
        content={`Deleting removes this  ${this.props
          .deleteLabel} from the platform.`}
      />;

    getCloseFormAction = () => {
      const { isActive } = this.props;
      return {
        withoutSideEffects: true,
        onClick: isActive ? this.handleItemCloseForm : this.handleItemClose,
        icon: faTimes,
      };
    };

    actions = () => {
      const {
        isDirty,
        isActive,
        validated,
        isValid,
        showActions,
        deleteLabel,
      } = this.props;
      if (!showActions) return [];
      return [
        {
          onClick: isActive ? this.handleAddSaveClick : this.handleOpenEditForm,
          icon: isActive ? faSave : faPencilAlt,
          className:
            (isActive && !isDirty) || (validated && !isValid)
              ? 'btn-save disable'
              : null,
          loading: this.state.loading,
          label: 'Save',
          title: isActive ? `Save` : `Edit ${deleteLabel}`,
        },
        this.getDeleteAction(),
        {
          withoutSideEffects: true,
          onClick: isActive ? this.handleItemCloseForm : this.handleItemClose,
          icon: faTimes,
          title: 'Go back',
        },
      ];
    };

    render() {
      return (
        <WrappedComponent
          renderPrompt={this.renderPrompt}
          {...this.props}
          actions={this.actions()}
          handleItemCloseForm={this.handleItemCloseForm}
          handleItemClose={this.handleItemClose}
          itemSelector={this.itemSelector}
          handleAddSaveClick={this.handleAddSaveClick}
          handleEdit={this.handleEdit}
          handleCreate={this.handleCreate}
          createItem={this.create}
          editItem={this.edit}
          handleOpenEditForm={this.handleOpenEditForm}
        />
      );
    }
  }
  return HOC;
};
