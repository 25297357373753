import update from "immutability-helper";

import {
  SET_PACK_TEMPLATES,
  UPDATE_PACK_TEMPLATE,
  ADD_PACK_TEMPLATE,
  REMOVE_PACK_TEMPLATE
} from "_actions/packTemplates";
import {
  ADD_PACK_TEMPLATE_CARD_TEMPLATE,
  REMOVE_CARD_TEMPLATE
} from "_actions/cardTemplates";
import { SET_TILE_TEMPLATES } from "_actions/tileTemplates";

function updateIds(state, ids = []) {
  if (ids.length === 0) return state;
  return [...new Set(state.concat(ids))];
}

function removeCardTemplates(state, { id, cardTemplates }) {
  let newState = state;
  cardTemplates.forEach(cardTemplate => {
    newState = update(newState, {
      byId: {
        [id]: {
          cardTemplates: {
            $set: state.byId[id].cardTemplates.filter(x => x !== cardTemplate)
          }
        }
      }
    });
  });
  return newState;
}

export default function packTemplates(state = { all: [], byId: {} }, action) {
  switch (action.type) {
    case SET_TILE_TEMPLATES:
    case SET_PACK_TEMPLATES:
      return update(state, {
        all: { $set: updateIds(state.all, action.packTemplatesAll) },
        byId: { $set: action.packTemplates }
      });
    case ADD_PACK_TEMPLATE:
      return update(state, {
        all: { $push: [action.id] },
        byId: {
          [action.id]: {
            $set: action.packTemplate
          }
        }
      });
    case ADD_PACK_TEMPLATE_CARD_TEMPLATE:
      return update(state, {
        byId: {
          [action.id]: {
            cardTemplates: {
              $set: updateIds(state.byId[action.id].cardTemplates || [], [
                action.cardTemplateId
              ])
            }
          }
        }
      });
    case REMOVE_PACK_TEMPLATE:
      return update(state, {
        all: { $set: state.all.filter(x => x !== action.id) },
        byId: { [action.id]: { $set: undefined } }
      });
    case REMOVE_CARD_TEMPLATE:
      return removeCardTemplates(state, {
        id: action.packTemplateId,
        cardTemplates: [action.id]
      });
    case UPDATE_PACK_TEMPLATE:
      return update(state, {
        byId: {
          [action.id]: {
            $set: action.packTemplate
          }
        }
      });
    default:
      return state;
  }
}
