import { connect } from 'react-redux';
import R from '_utils/ramda';
import { attemptDeleteUser, attemptUpdateUser } from '_thunks/users';
import { UsersSectionContainer } from './container';

const mapStateToProps = R.pick(['users']);

const mapDispatchToProps = dispatch => ({
  deleteItem: id => dispatch(attemptDeleteUser(id)),
  updateItem: () => dispatch(attemptUpdateUser()),
  getData: () => {},
});

export const UsersSection = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsersSectionContainer);

export default { UsersSection };
