import { connect } from 'react-redux';
import { addHOC } from '../../form';
import { AddFieldTemplateFormContainer } from './addContainer';
import { attemptUpdateFormData } from '_thunks/views';
import { fields } from '../fields';
import { fieldTemplateDefault as constraints } from '_api/constraints';

const mapStateToProps = (state, props) => {
  return {
    ...state.form.fieldTemplate,
    activeItem: state.fieldTemplates.byId[props.id],
  };
};

const mapStateToDispatch = dispatch => ({
  updateFormData: data =>
    dispatch(attemptUpdateFormData('fieldTemplate', data)),
  handleFieldTemplatesChange: (key, fieldTemplates) =>
    dispatch(
      attemptUpdateFormData('fieldTemplate', {
        children: true,
        [key]: fieldTemplates,
      }),
    ),
  resetFormData: (data, props) =>
    dispatch(
      attemptUpdateFormData('fieldTemplate', {
        ...data,
        activeItem: {
          fieldTemplateId: props.fieldTemplateId,
          ...data.activeItem,
        },
      }),
    ),
});

export const AddFieldTemplateForm = connect(
  mapStateToProps,
  mapStateToDispatch,
)(
  addHOC(AddFieldTemplateFormContainer, {
    fields,
    title: 'Field Template',
    constraints,
  }),
);
export default { AddFieldTemplateForm };
