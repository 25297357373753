import React from 'react';
import { CollectionsSection } from '_components';

export function CollectionsPage({ id }) {
  return (
    <div className="page-wrapper collections">
      <div className="container grid-lg" id="page-container">
        <div className="columns col-gapless">
          <CollectionsSection id={id} />
        </div>
      </div>
    </div>
  );
}

export default { CollectionsPage };
