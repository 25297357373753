import React, { PureComponent } from 'react';
import {
  faPencilAlt,
  faTrash,
  faTimes,
  faSave,
} from '@fortawesome/free-solid-svg-icons';
import { Prompt } from '_components';

export const sectionHOC = (
  WrappedComponent,
  {
    ItemComponent,
    TableComponent,
    AddFormComponent,
    itemSelector,
    routePrefix,
    key,
    dataKey,
    formKey,
    metaActions,
    constraints,
  },
) => {
  class HOC extends PureComponent {
    state = {
      isAdding: false,
      isEditing: false,
      isDeleting: false,
      attemptToClose: false,
      loading: false,
      loadingDelete: false,
      child: null,
    };

    componentDidMount() {
      const { id } = this.props;
      if (id) this.props.getData(id);
    }

    // componentWillUnmount() {
    //   console.log('unmount hoccsection');
    // }

    handleAddClick = () => {
      this.props.history.push(routePrefix);
      // if (!this.props.id) {
      this.setState({ isAdding: true });
      // }
      this.addClicked = true;
    };

    handleOtherAddClick = () => {
      this.setState({ isAdding: true, child: 'PACK' });
      this.addClicked = true;
    };

    handleAddBatchClick = () => {
      this.props.history.push(routePrefix);
      this.setState({ isBatchAdding: true });
      this.addClicked = true;
    };

    handleEditClick = () => {
      this.props.resetFormData({
        reset: true,
        activeItem: {},
      });
      this.setState({ isEditing: true });
    };

    handleItemClick = (rowData, rowMeta) => {
      let index;
      // table item
      if (rowMeta) {
        index = rowMeta.dataIndex;
      } else {
        // card
        index = rowData.currentTarget.dataset.index;
      }

      const activeItem = this.props.items[index].id;

      this.props.getData(activeItem);

      this.props.history.push(`${routePrefix}/${activeItem}`);
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.id !== this.props.id) {
        this.setState({
          isEditing: false,
          isAdding: this.addClicked && !nextProps.id,
        });
      }
      this.addClicked = null;
    }

    handleItemClose = () => {
      if (this.state.isEditing || this.state.isAdding) {
        if (!this.props.isDirty) {
          return this.setState({ isEditing: false, isAdding: false });
        } else {
          return this.setState({ attemptToClose: true });
        }
      }
      // throw prompt if editing or creating
      this.props.history.push(routePrefix);
    };

    confirmItemDelete = () => {
      this.setState({ isDeleting: true });
    };

    handleCancelDelete = () => {
      this.setState({ isDeleting: false });
    };

    handleItemDelete = () => {
      const { id, history } = this.props;
      this.setState({ loadingDelete: true, isDeleting: false });
      this.props
        .deleteItem(id)
        .then(() =>
          this.setState(
            {
              activeItem: null,
              loadingDelete: false,
            },
            history.push(routePrefix),
          ),
        )
        .finally(() => {
          this.setState({ loadingDelete: false });
        });
    };

    handleAddCloseClick = () => {
      this.setState({ isAdding: false, isEditing: false });
    };

    handleAddSaveClick = () => {
      if (this.state.isAdding && !this.props.isValid)
        return this.props.handleValidateAll();
      if (!this.props.isDirty || !this.props.isValid || this.state.loading)
        return;
      this.setState({ loading: true });

      this.state.isEditing
        ? this.props
            .updateItem(this.props.formData)
            .then(item => {
              this.props.resetFormData();
              this.setState({ isEditing: false, loading: false });
            })
            .catch(() => {
              this.setState({ loading: false });
            })
        : this.props
            .createItem(this.props.formData)
            .then(item => {
              this.props.resetFormData();

              this.props.history.push(`${routePrefix}/${item.id}`);
              this.setState({ isAdding: false, loading: false });
            })
            .catch(() => {
              this.setState({ loading: false });
            });
    };

    summaryActions = () => {
      return [
        {
          onClick:
            this.state.isEditing || this.state.isAdding
              ? this.handleAddSaveClick
              : this.handleEditClick,
          icon:
            this.state.isEditing || this.state.isAdding ? faSave : faPencilAlt,
          className:
            ((this.state.isEditing || this.state.isAdding) &&
              !this.props.isDirty) ||
            (this.props.validated && !this.props.isValid)
              ? 'btn-save disable'
              : null,
          loading: this.state.loading,
          label: this.state.isEditing ? 'Edit' : 'Create',
          title: this.state.isEditing ? `Edit ${key}` : `Create ${key}`,
        },
        {
          onClick: this.confirmItemDelete,
          icon: faTrash,
          label: 'Delete',
          className: this.state.isEditing ? 'btn-error hide' : 'btn-error',
          loading: this.state.loadingDelete,
          title: `Delete ${key}`,
          isNegAction: true,
        },
        {
          withoutSideEffects: true,
          onClick: this.handleItemClose,
          icon: faTimes,
          title: `Return to ${key}s`,
        },
      ];
    };

    renderActiveItem = () =>
      <ItemComponent
        {...this.props}
        id={this.props.id}
        iconOnly
        handleAddClick={this.handleOtherAddClick}
        isValid={this.props.validated ? this.props.isValid : true}
        isEditing={this.state.isEditing}
        isAdding={this.state.isAdding}
        actions={this.summaryActions()}
        formTitle={key}
        history={this.props.history}
        dataKey={dataKey}
        formKey={formKey}
        itemSelector={itemSelector}
        metaActions={metaActions && metaActions(this.props)}
        constraints={constraints}
        data={
          this.state.isEditing || this.state.isAdding
            ? this.props.editData
            : null
        }
      />;

    renderPrompt = () =>
      <Prompt
        show={this.state.isDeleting}
        onOk={this.handleItemDelete}
        onCancel={this.handleCancelDelete}
        okText="Delete"
        title={`Delete ${key}`}
        content={`Deleting removes this ${key} from the platform.`}
      />;

    afterConfirmAction = () => {
      this.props.resetFormData();
      this.setState({
        isEditing: false,
        isAdding: false,
        attemptToClose: false,
      });
    };
    toggleAttemptToClose = () => {
      this.setState({ attemptToClose: false });
    };

    render() {
      const { isAdding, isEditing } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          handleItemClick={this.handleItemClick}
          handleItemClose={this.handleItemClose}
          handleAddClick={this.handleAddClick}
          handleAddSaveClick={this.handleAddSaveClick}
          handleAddCloseClick={this.handleAddCloseClick}
          renderPrompt={this.renderPrompt}
          child={this.state.child}
          isAdding={isAdding}
          isEditing={isEditing}
          activeItem={this.props.id}
          summaryActions={this.summaryActions()}
          renderActiveItem={this.renderActiveItem}
          TableComponent={TableComponent}
          AddFormComponent={AddFormComponent}
          isDirty={this.props.isDirty}
          attemptToClose={this.state.attemptToClose}
          toggleAttemptToClose={this.toggleAttemptToClose}
          afterConfirm={this.afterConfirmAction}
        />
      );
    }
  }
  return HOC;
};

export default { sectionHOC };
