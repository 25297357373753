import { validate } from 'validate.js';
import { tileTemplateDefault as constraints } from '_api/constraints';
import {
  normalizeTileTemplates,
  normalizeTileTemplate,
} from '../normalise/tileTemplates';

export const SET_TILE_TEMPLATES = 'SET_TILE_TEMPLATES';
export const ADD_TILE_TEMPLATE = 'ADD_TILE_TEMPLATE';
export const UPDATE_TILE_TEMPLATE = 'UPDATE_TILE_TEMPLATE';
export const REMOVE_TILE_TEMPLATE = 'REMOVE_TILE_TEMPLATE';
export const ADD_COLLECTION_TILE_TEMPLATE = 'ADD_COLLECTION_TILE_TEMPLATE';

export function setTileTemplates(data) {
  const {
    tileTemplates,
    fieldTemplates,
    cardTemplates,
    packTemplates,
  } = normalizeTileTemplates(data);
  const res = {
    type: SET_TILE_TEMPLATES,
    tileTemplates: tileTemplates.filter(item => {
      return !validate(item, constraints);
    }),
    fieldTemplatesAll: fieldTemplates ? Object.keys(fieldTemplates) : [],
    cardTemplatesAll: cardTemplates ? Object.keys(cardTemplates) : [],
    packTemplatesAll: packTemplates ? Object.keys(packTemplates) : [],
    fieldTemplates,
    cardTemplates,
    packTemplates,
  };

  return res;
}

export const addTileTemplate = tileTemplate => ({
  type: ADD_TILE_TEMPLATE,
  id: tileTemplate.id,
  tileTemplate,
});

export const addCollectionTileTemplate = (id, tileTemplate) => ({
  type: ADD_COLLECTION_TILE_TEMPLATE,
  id,
  tileTemplate,
});

export const updateTileTemplate = tileTemplate => ({
  type: UPDATE_TILE_TEMPLATE,
  tileTemplate: normalizeTileTemplate(tileTemplate),
  id: tileTemplate.id,
});

export const removeTileTemplate = id => ({
  type: REMOVE_TILE_TEMPLATE,
  id,
});
