import {
  ColourPicker,
  Text,
  TextInput,
  CollectionField,
  CategoryField,
  BooleanField,
  ImageField,
} from '_components';
import { TileImageCropper } from 'shared-code';

export const fields = ({
  validation,
  data,
  validated,
  handleFieldChange,
  handleOnValidate,
  handleSelectChange,
  handleColourChange,
  hideCollection,
  isNested,
  updateFormData,
}) => [
  {
    component: Text,
    textComponent: TextInput,
    name: 'Noun',
    id: 'noun',
    value: data.noun,
    onChange: handleFieldChange,
    onValidate: handleOnValidate,
    validation: validation.noun,
    placeholder: 'Enter noun',
    validated: validated,

    metaActions: [
      {
        component: BooleanField,
        id: 'nounShow',
        value: data.nounShow,
        onChange: handleSelectChange,
        onValidate: handleOnValidate,
        validation: validation.nounShow,
      },
    ],
  },
  {
    component: CollectionField,
    name: 'Collection',
    id: 'collectionId',
    value: data.collectionId,
    onChange: handleSelectChange,
    isDisabled: isNested,
    hide: hideCollection,
    validated: validated,
    validation: validation.collectionId,
  },
  {
    component: CategoryField,
    name: 'Category',
    id: 'categoryId',
    value: data.categoryId,
    onChange: handleSelectChange,
    validated: validated,
    validation: validation.categoryId,
    isNested,
  },

  {
    type: 'row',
    id: 'imageRow',
    formGroups: [
      {
        type: 'column',
        component: ImageField,
        name: 'Image (Default)',
        id: 'imgurlDefault',
        showPreview: false,
        entityKey: 'tile',
        value: data,
        onChange: handleFieldChange,
        onValidate: handleOnValidate,
        width: !isNested ? 6 : 12,
        validated: validated,
        validation: validation.imgurlDefault,
        updateFormData,
        vertical: !isNested,
        ImageCropper: TileImageCropper,
      },
    ],
  },

  {
    type: 'row',
    id: 'colourRow',
    formGroups: [
      {
        type: 'column',
        component: ColourPicker,
        name: 'Hex',
        id: 'hexColour',
        value: data.hexColour,
        onChange: handleColourChange,
        onValidate: handleOnValidate,
        validated: validated,
        validation: validation.hexColour,
        width: 4,
      },
      {
        type: 'column',
        component: ColourPicker,
        name: 'Noun',
        id: 'nounHexColour',
        value: data.nounHexColour,
        onChange: handleColourChange,
        onValidate: handleOnValidate,
        validated: validated,
        validation: validation.nounHexColour,
        width: 4,
      },
      {
        type: 'column',
        component: ColourPicker,
        name: 'Text',
        id: 'textHexColour',
        value: data.textHexColour,
        onChange: handleColourChange,
        onValidate: handleOnValidate,
        validated: validated,
        validation: validation.textHexColour,
        width: 4,
      },
    ],
  },
  {
    component: BooleanField,
    name: 'Top 6',
    id: 's3Url',
    value:
      typeof data.s3Url === 'string'
        ? data.s3Url === 'true'
        : Boolean(data.s3Url),
    onValidate: handleOnValidate,
    onChange: handleFieldChange,
    validated: validated,
    validation: validation.s3Url,
  },
  {
    component: Text,
    textComponent: TextInput,
    name: 'Custom Title',
    id: 'apiUrl',
    value: data.apiUrl,
    validation: validation.apiUrl,
    onChange: handleFieldChange,
    onValidate: handleOnValidate,
    validated: validated,
    hide: typeof data.s3Url === 'string'
    ? data.s3Url !== 'true'
    : !Boolean(data.s3Url)
  },

  {
    component: Text,
    name: 'Text',
    id: 'text',
    value: data.text,
    onChange: handleFieldChange,
    onValidate: handleOnValidate,
    placeholder: 'Enter text',
    validated: validated,
    validation: validation.text,
    metaActions: [
      {
        component: BooleanField,
        id: 'textShow',
        value: data.textShow,
        onChange: handleSelectChange,
        onValidate: handleOnValidate,
        validation: validation.textShow,
      },
    ],
  },
];
