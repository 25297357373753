import { request } from '_utils/api';

export const getFieldTemplates = ({
  tileTemplateId,
  packTemplateId,
  cardTemplateId,
}) =>
  request(
    'get',
    `/tileTemplate/${tileTemplateId}/packtemplate/${packTemplateId}/cardtemplate/${cardTemplateId}/fieldTemplate`,
  );

export const createFieldTemplates = ({
  newTemplates,
  tileTemplateId,
  packTemplateId,
  cardTemplateId,
}) => {
  if (!newTemplates || newTemplates.length === 0) return Promise.resolve([]); // Promise.reject({ data: { message: "no fields" } });
  if (!tileTemplateId || !packTemplateId || !cardTemplateId)
    return Promise.reject({ data: { message: 'missing ids' } });
  const body = newTemplates;
  return request(
    'post',
    `/tileTemplate/${tileTemplateId}/packtemplate/${packTemplateId}/cardtemplate/${cardTemplateId}/fieldTemplate`,
    { body },
  );
};

export const updateFieldTemplates = data => {
  if (!data || data.length === 0)
    return Promise.reject({ data: { message: 'no fields' } });
  const { tileTemplateId, packTemplateId, cardTemplateId } = data[0];
  if (!tileTemplateId || !packTemplateId || !cardTemplateId)
    return Promise.reject({ data: { message: 'missing ids' } });
  return request(
    'put',
    `/tileTemplate/${tileTemplateId}/packtemplate/${packTemplateId}/cardtemplate/${cardTemplateId}/fieldTemplate`,
    {
      body: data,
    },
  );
};

export const deleteFieldTemplates = ({
  removedFieldTemplates,
  tileTemplateId,
  packTemplateId,
  cardTemplateId,
}) => {
  if (!tileTemplateId || !packTemplateId || !cardTemplateId)
    return Promise.reject({ data: { message: 'missing ids' } });
  return request(
    'del',
    `/tiletemplate/${tileTemplateId}/packtemplate/${packTemplateId}/cardtemplate/${cardTemplateId}/fieldTemplate`,
    { body: removedFieldTemplates },
  );
};
