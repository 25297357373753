import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CategoriesPage } from './categoriesPage';
import LinearProgress from '@material-ui/core/LinearProgress';

export class CategoriesPageContainer extends Component {
  static propTypes = {
    getCategories: PropTypes.func.isRequired,
  };

  state = {
    loading: {
      categories: true,
    },
  };

  componentDidMount() {
    const { getCategories } = this.props;
    getCategories().then(() =>
      this.setState({
        loading: { ...this.state.loading, categories: false },
      }),
    );
  }

  render() {
    return Object.values(this.state.loading).every(item => item === false) ? (
      <CategoriesPage id={this.props.match.params.id} />
    ) : (
      <LinearProgress />
    );
  }
}

export default { CategoriesPageContainer };
