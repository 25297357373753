import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router';
import Notifications from 'react-notification-system-redux';
import { homeRoutes } from './routes';
import { Navbar } from '_components';

export function Main({ notifications, dispatch }) {
  return (
    <div className="has-navbar-fixed-top">
      <Navbar />
      <Notifications notifications={notifications} />
      <div className="main">
        <Switch>
          {homeRoutes.map((route, i) => <Route key={i} {...route} />)}
        </Switch>
      </div>
    </div>
  );
}

Main.propTypes = {
  notifications: PropTypes.array.isRequired,
};

Main.contextTypes = {
  store: PropTypes.object,
};

export default { Main };
