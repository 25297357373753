import React from 'react';
import { Button, ConfirmButton } from '_components';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';

const noop = () => {};

function copyText(event) {
  event.currentTarget.select();
  document.execCommand('copy');
}

export function CollectionCard({
  data,
  handleCardClick,
  deleteCollection,
  ...rest
}) {
  return (
    <div
      className="card collection-card"
      data-id={data.id}
      onClick={handleCardClick}
      {...rest}
    >
      <div className="card-image" src={data.imgurl} alt="" />
      <div className="card-header">
        <div className="card-details">
          <h5 className="card-title">{data.name}</h5>
          <h6 className="card-subtitle">{data.text}</h6>
        </div>
      </div>
      <div className="card-body">
        <div className="card-group">
          <h6>Colour</h6>
          <div className="card-value">
            <div className="hex-color">
              <span
                className="collection-color"
                style={{ backgroundColor: data.hexColour }}
              />
              <span className="collection-color-code" onClick={copyText}>
                {data.hexColour}
              </span>
            </div>
          </div>
        </div>
        <div className="card-group">
          <h6>Text Colour</h6>
          <div className="card-value">
            <div className="hex-color">
              <span
                className="collection-color"
                style={{ backgroundColor: data.textHexColour }}
              />
              <span className="collection-color-code">
                {data.textHexColour}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer">
        <Button icon={faPencilAlt} onClick={noop} label="Edit" />
        <ConfirmButton
          icon={faTrash}
          onSubmit={deleteCollection}
          label="Delete"
          submitClassName="btn-error"
        />
      </div>
    </div>
  );
}

export default { CollectionCard };
