import React, { PureComponent } from 'react';
import validate from 'validate.js';

export const withFieldActions = WrappedComponent => {
  class HOC extends PureComponent {
    handleFieldChange = (key, value) => {
      const values = {
        data: { ...this.props.data, [key]: value === '' ? null : value },
        isDirty: true,
      };

      this.props.updateFormData(values);
      this.handleOnValidate(key, value, values.data);
    };

    handleSelectChange = (key, value) => {
      const data = { ...this.props.data, [key]: value };
      const values = { data, isDirty: true };

      this.props.updateFormData(values);
      this.handleOnValidate(key, value, data);
    };

    handleColourChange = (key, { hex: value }) => {
      const values = {
        data: { ...this.props.data, [key]: value },
        isDirty: true,
      };
      this.props.updateFormData(values);
    };

    handleValidateAll = () => {
      const validationAll = validate(this.props.data, this.props.constraints);

      const initialValues = {
        validation: {
          ...validationAll,
        },
        isValid: !validationAll,
        validated: true,
      };
      this.props.updateFormData(initialValues);
    };

    handleOnValidate = (key, value, data = this.props.data) => {
      const validationResponse = validate.single(
        value,
        this.props.constraints[key],
      );
      const validationAll = validate(data, this.props.constraints);

      const values = {
        validation: {
          ...this.props.validation,
          [key]: validationResponse,
        },
        isValid: !validationAll,
      };
      if (this.props.isEditing) {
        values.validated = true;
      }
      this.props.updateFormData(values);
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          handleFieldChange={this.handleFieldChange}
          handleSelectChange={this.handleSelectChange}
          handleColourChange={this.handleColourChange}
          handleValidateAll={this.handleValidateAll}
          handleOnValidate={this.handleOnValidate}
        />
      );
    }
  }
  return HOC;
};

export default { withFieldActions };
