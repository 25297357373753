import {
  getTileTemplates,
  updateTileTemplate as editTileTemplate,
  deleteTileTemplate,
  createTileTemplate as createTileTemplateApi,
} from '_api/tileTemplates';
import {
  setTileTemplates,
  removeTileTemplate,
  updateTileTemplate,
  addTileTemplate,
  addCollectionTileTemplate,
} from '_actions/tileTemplates';
import { dispatchError, handleErrorRes } from '_utils/api';
import imgurlUtils from '_utils/imgurl';
import { to, attemptUpdateEntityImages, uploadTileImage } from 'shared-code';

export const attemptGetTileTemplates = () => (dispatch, getState) => {
  if (getState().fetchStatus.tileTemplates) return Promise.resolve({});
  return getTileTemplates()
    .then(data => {
      dispatch(setTileTemplates(data));
      return data;
    })
    .catch(dispatchError(dispatch));
};

export const attemptCreateTileTemplate = item => dispatch =>
  createTileTemplateApi(item)
    .then(data => {
      dispatch(addTileTemplate(data));
      dispatch(addCollectionTileTemplate(data.collectionId, data));
      return data;
    })
    .catch(dispatchError(dispatch));

export const createTileTemplate = item => dispatch =>
  createTileTemplateApi(item).then(data => {
    dispatch(addTileTemplate(data));
    dispatch(addCollectionTileTemplate(data.collectionId, data));
    return data;
  });

export const attemptDeleteTileTemplate = ({ id }) => dispatch =>
  deleteTileTemplate(id)
    .then(data => {
      dispatch(removeTileTemplate(id));
      return data;
    })
    .catch(err => {
      handleErrorRes(dispatch, err);
      throw err;
    });

export const attemptEditTileTemplate = item => dispatch =>
  editTileTemplate(item)
    .then(data => {
      dispatch(updateTileTemplate(data));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptUpdateTileTemplates = selected => dispatch => {
  const actions = [];
  selected.forEach(element => {
    actions.push(dispatch(attemptEditTileTemplate(element)));
  });
  return Promise.all(actions);
};

export const attemptUpdateTileTemplate = (item, isCreating) => async (
  dispatch,
  getState,
) => {
  let imgResponse = null;
  let err = null;
  const state = getState();
  const { data } = state.form.tileTemplate;
  const { imageHasChanged, croppedImage } = state.editImage.tile;

  if (imageHasChanged) {
    [err, imgResponse] = await to(
      dispatch(
        attemptUpdateEntityImages({
          croppedImage,
          entity: data,
          entityType: 'tile',
          newImage: {
            ...state.editImage.tile,
            copyrightChecked: true,
            isPublic: true,
          },
          imageHasChanged,
          id: data.id || data.categoryId,
          categoryId: data.categoryId,
          updateEntityImage: uploadTileImage,
        }),
      ),
    );
    if (err) return err;
  }

  const newImagePaths = imgurlUtils.parseNewImagePaths({
    type: 'tileTemplate',
    editImage: state.editImage.tile,
    imgResponse,
    entity: data,
  });

  const updatedData = {
    ...data,
    s3Url: typeof data.s3Url === 'boolean' ? `${data.s3Url}` : data.s3Url,
    ...newImagePaths,
  };
  return isCreating
    ? dispatch(attemptCreateTileTemplate(updatedData))
    : dispatch(attemptEditTileTemplate(updatedData));
};
