import { request } from '_utils/api';

export const getUsers = () => request('get', '/user');

export const getUser = id => request('get', `/user/${id}`);

export const createUser = data => {
  const body = {
    ...data,
  };
  return request('get', '/user', { body });
};

export const updateUser = id => request('put', `/user/${id}`);

export const deleteUser = id => request('del', `/user/${id}`);
