import { connect } from 'react-redux';
import { addHOC } from '../../form';
import { AddCardTemplateFormContainer } from './addContainer';
import { attemptUpdateFormData } from '_thunks/views';
import { fields } from './fields';
import { cardTemplateDefault as constraints } from '_api/constraints';

const mapStateToProps = (state, props) => {
  const { match: { params: { id, packTemplateId, cardTemplateId } } } = props;

  const cloneId = state.sectionView.tileTemplate.cloneId;
  let activeItem = {};
  if (cloneId) {
    activeItem = state.cardTemplates.byId[cloneId] || {};
  } else if (cardTemplateId)
    activeItem = state.cardTemplates.byId[cardTemplateId] || {};

  const fieldTemplates = activeItem.fieldTemplates || [];
  return {
    tileTemplateId: id,
    packTemplateId: packTemplateId,
    id: cardTemplateId,
    cardTemplateId: cardTemplateId,
    ...state.form.cardTemplate,
    activeItem: {
      ...activeItem,
      name: cloneId ? undefined : activeItem.name,
      fieldTemplateIds: (!cloneId && fieldTemplates) || [],
      fieldTemplates: fieldTemplates.map(id => ({
        ...state.fieldTemplates.byId[id],
        isTemp: !!cloneId,
      })),
    },
  };
};

const mapStateToDispatch = dispatch => ({
  updateFormData: data => dispatch(attemptUpdateFormData('cardTemplate', data)),
  handleFieldTemplatesChange: (key, fieldTemplates) =>
    dispatch(
      attemptUpdateFormData('cardTemplate', {
        children: true,
        [key]: fieldTemplates,
      }),
    ),
  resetFormData: (data, props) => {
    dispatch(
      attemptUpdateFormData('cardTemplate', {
        ...data,
        activeItem: {
          id: props.cardTemplateId,
          packTemplateId: props.packTemplateId,
          tileTemplateId: props.tileTemplateId,
          ...data.activeItem,
        },
      }),
    );
  },
});

export const AddCardTemplateForm = connect(mapStateToProps, mapStateToDispatch)(
  addHOC(AddCardTemplateFormContainer, {
    fields,
    title: 'Card',
    constraints,
  }),
);
export default { AddCardTemplateForm };
