import {
  getPackTemplates,
  updatePackTemplate as editPackTemplate,
  deletePackTemplate,
  createPackTemplate,
} from '_api/packTemplates';
import {
  setPackTemplates,
  removePackTemplate,
  updatePackTemplate,
  addPackTemplate,
  addTileTemplatePackTemplate,
} from '_actions/packTemplates';
import { dispatchError } from '../../utils/api';
import imgurlUtils from '_utils/imgurl';
import { to, attemptUpdateEntityImages, uploadTileImage } from 'shared-code';

export const attemptGetPackTemplates = () => (dispatch, getState) => {
  if (getState().fetchStatus.tileTemplates) return Promise.resolve({});
  return getPackTemplates()
    .then(data => {
      dispatch(setPackTemplates(data));
      return data;
    })
    .catch(dispatchError(dispatch));
};

export const attemptEditPackTemplate = item => dispatch =>
  editPackTemplate(item)
    .then(data => {
      dispatch(updatePackTemplate(data));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptCreatePackTemplate = item => dispatch =>
  createPackTemplate(item)
    .then(data => {
      dispatch(addPackTemplate(data));
      dispatch(addTileTemplatePackTemplate(data.tileTemplateId, data));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptUpdatePackTemplate = (item, isCreating) => async (
  dispatch,
  getState,
) => {
  let imgResponse = null;
  let err = null;
  const state = getState();
  const { data } = state.form.packTemplate;
  const { imageHasChanged, croppedImage } = state.editImage.pack;
  const tileTemplate = state.tileTemplates.find(
    x => x.id === data.tileTemplateId,
  );

  if (imageHasChanged) {
    [err, imgResponse] = await to(
      dispatch(
        attemptUpdateEntityImages({
          croppedImage,
          entity: data,
          entityType: 'pack',
          newImage: {
            ...state.editImage.pack,
            copyrightChecked: true,
            isPublic: true,
          },
          imageHasChanged,
          id: data.tileTemplateId,
          categoryId: tileTemplate.categoryId,
          updateEntityImage: uploadTileImage,
        }),
      ),
    );
    if (err) return err;
  }

  const newImagePaths = imgurlUtils.parseNewImagePaths({
    type: 'packTemplate',
    editImage: state.editImage.pack,
    imgResponse,
    entity: data,
  });

  const updatedData = {
    ...data,
    ...newImagePaths,
    cardTemplates: undefined,
  };
  return isCreating
    ? dispatch(attemptCreatePackTemplate(updatedData))
    : dispatch(attemptEditPackTemplate(updatedData));
};

export const attemptDeletePackTemplate = ({ tileTemplateId, id }) => dispatch =>
  deletePackTemplate({ tileTemplateId, id })
    .then(() => {
      dispatch(removePackTemplate({ tileTemplateId, id }));
      return { tileTemplateId, id };
    })
    .catch(dispatchError(dispatch));
