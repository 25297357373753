// import { validate } from 'validate.js';

export const SET_SECTION_VIEW = "SET_SECTION_VIEW";
export const SET_PANEL = "SET_PANEL";
export const CLOSE_SECTION_FORM = "CLOSE_SECTION_FORM";
export const CLEAR_PANEL = "CLEAR_PANEL";
export const UPDATE_SECTION_VIEW = "UPDATE_SECTION_VIEW";

export function setSectionView(view) {
  return {
    type: SET_SECTION_VIEW,
    ...view
  };
}

export function closeSectionForm(id) {
  return {
    type: CLOSE_SECTION_FORM,
    id
  };
}

export function clearPanel(id, level) {
  return {
    type: CLEAR_PANEL,
    id,
    level
  };
}

export function updateSectionView(id, sectionView) {
  return {
    type: UPDATE_SECTION_VIEW,
    id,
    sectionView
  };
}

export function setPanel(id, { level, ...panel }) {
  return {
    type: SET_PANEL,
    id,
    level,
    panel
  };
}
