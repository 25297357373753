import { normalizeFieldTemplates } from "../normalise/tileTemplates";

export const SET_FIELD_TEMPLATES = "SET_FIELD_TEMPLATES";
export const UPDATE_FIELD_TEMPLATES = "UPDATE_FIELD_TEMPLATES";
export const ADD_FIELD_TEMPLATES = "ADD_FIELD_TEMPLATES";
export const ADD_CARD_TEMPLATE_FIELD_TEMPLATES =
  "ADD_CARD_TEMPLATE_FIELD_TEMPLATES";
export const REMOVE_CARD_TEMPLATE_FIELD_TEMPLATES =
  "REMOVE_CARD_TEMPLATE_FIELD_TEMPLATES";

export function setFieldTemplates(fieldTemplates) {
  return {
    type: SET_FIELD_TEMPLATES,
    ...normalizeFieldTemplates(fieldTemplates)
  };
}

export const addFieldTemplates = fieldTemplates => ({
  type: ADD_FIELD_TEMPLATES,
  fieldTemplates
});

export const addCardTemplateFieldTemplates = (id, fieldTemplates = []) => ({
  type: ADD_CARD_TEMPLATE_FIELD_TEMPLATES,
  id,
  ...normalizeFieldTemplates(fieldTemplates)
});

export const removeCardTemplateFieldTemplates = (id, fieldTemplates = []) => ({
  type: REMOVE_CARD_TEMPLATE_FIELD_TEMPLATES,
  id,
  fieldTemplates
});

export const updateFieldTemplates = fieldTemplates => ({
  type: UPDATE_FIELD_TEMPLATES,
  ...normalizeFieldTemplates(fieldTemplates)
});
