import React, { PureComponent } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const options = [
  {
    name: "Categories",
    path: "categories"
  },
  {
    name: "Collection Types",
    path: "collection-types"
  }
];

const ITEM_HEIGHT = 48;

export class More extends PureComponent {
  static defaultProps = {
    value: "#000000",
    code: null
  };

  state = {
    anchorEl: null,
    selected: null
  };

  togglePopout = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuItemClick(event, index) {
    const option = options[index];

    this.props.onMoreClick(option.path);
    this.setState({ selected: index, anchorEl: null });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <React.Fragment>
        <IconButton
          aria-label="More"
          aria-owns={open ? "long-menu" : undefined}
          aria-haspopup="true"
          onClick={this.togglePopout}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          id="long-menu"
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          style={{
            transformOrigin: "center bottom",
            top: "40px"
          }}
          PaperProps={{
            style: {
              transformOrigin: "center bottom",
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 150,
              top: "40px"
            }
          }}
        >
          {options.map((option, index) => (
            <MenuItem
              key={option}
              selected={index === this.state.selected}
              onClick={event => this.handleMenuItemClick(event, index)}
            >
              {option.name}
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    );
  }
}

export default { Menu };
