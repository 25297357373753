import { getUsers } from '_api/users';
import { setUsers } from '_actions/users';

import { dispatchError } from '_utils/api';

export const attemptGetUsers = () => (dispatch, getState) => {
  if (getState().fetchStatus.users) return Promise.resolve({});
  return getUsers()
    .then(data => {
      dispatch(setUsers(data));
      return data;
    })
    .catch(dispatchError(dispatch));
};

export const attemptCreateUser = () => {};
export const attemptDeleteUser = () => {};
export const attemptUpdateUser = () => {};
