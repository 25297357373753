import React from 'react';
import PropTypes from 'prop-types';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from '_components';

function renderSpacer() {
  return <div className="flex-spacer hide-xs hide-sm" />;
}

export function FormHeader(props) {
  return (
    <div className={props.isDialog ? 'dialog-header' : 'form-header'}>
      {props.isDialog ? renderSpacer() : null}

      <div className="title">
        <h1>{props.title}</h1>
        {props.validated && !props.isValid ? (
          <div className="sixtile-badge invalid">Invalid</div>
        ) : null}
      </div>
      {props.hasActionButtons ? (
        <div
          className={props.isDialog ? 'dialog-actions' : 'form-header-actions'}
        >
          {props.metaActions ? (
            <div className="form-header-meta-actions">
              {props.metaActions.map(
                ({ component: Component, id, name, ...childProps }) => {
                  return (
                    <Component id={id} key={id} name={name} {...childProps} />
                  );
                },
              )}
            </div>
          ) : null}
          <Button
            className="btn"
            label="Save"
            icon={props.isDialog ? null : faSave}
            iconOnly={!props.isDialog}
            disabled={!props.isValid && props.validated}
            onClick={props.handleSaveClick}
          />
          <Button
            className="btn-ghost"
            label="Cancel"
            iconOnly={!props.isDialog}
            icon={props.isDialog ? null : faTimes}
            onClick={props.handleCloseClick}
          />
        </div>
      ) : null}
    </div>
  );
}

FormHeader.propTypes = {
  hasActionButtons: PropTypes.bool,
  handleSaveClick: PropTypes.func,
  handleCloseClick: PropTypes.func,
  isValid: PropTypes.bool,
};

FormHeader.defaultProps = {
  hasActionButtons: true,
  isValid: true,
  handleSaveClick: undefined,
  handleCloseClick: undefined,
};
