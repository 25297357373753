import React, { Component } from "react";
import classNames from "classnames";
import { BooleanField, Action } from "_components";
import {
  faMinus,
  faTrash,
  faUndo,
  faCaretDown,
  faCaretUp
} from "@fortawesome/free-solid-svg-icons";

export class AddFieldTemplateInlineFormContainer extends Component {
  headerProps = () => ({
    metaActions: []
  });

  getMetaAction = () => {
    const {
      data,
      handleFieldChange,
      handleOnValidate,
      validation
    } = this.props;
    return {
      id: "editable",
      value: data.editable,
      onChange: handleFieldChange,
      onValidate: handleOnValidate,
      validation: validation.editable
    };
  };

  handleDialogCloseClick = () => {
    const { onCancel } = this.props;
    this.props.resetFormData(
      { reset: true, activeItem: this.props.activeItem },
      this.props
    );

    onCancel();
  };

  formGroups = () => {
    const {
      fields,
      handleFieldChange,
      handleOnValidate,
      handleSelectChange
    } = this.props;

    return fields({
      ...this.props,
      handleFieldChange,
      handleOnValidate: handleOnValidate,
      handleSelectChange: handleSelectChange
    });
  };

  renderField = field => {
    const FieldComponent = field.component;
    if (field.isHidden) return null;
    return (
      <div key={field.id} className="field-inline-form-property">
        <FieldComponent {...field} />
      </div>
    );
  };

  onRemove = () => {
    const {
      handleRemove,
      handleMarkAsRemove,
      data: { id, isTemp }
    } = this.props;

    return !isTemp ? handleMarkAsRemove(id) : handleRemove(id);
  };

  toggleCollapse = () => {
    const { data: id } = this.props;

    this.props.toggleCollapse(id);
  };

  renderCollapsedView = fields => {
    const { value: name } = fields[0] || {};
    const { value } = fields[2] || {};
    const { isRemoved } = this.props;

    return (
      <div className="field-inline-form-header">
        <div className="field-inline-form-inner-header">
          <BooleanField {...this.getMetaAction()} />
          <div className="name"> {name || value}</div>
        </div>
        {this.renderRemoveAction()}
        {!isRemoved ? this.renderCollapseAction() : null}
      </div>
    );
  };

  renderRemoveAction = () => {
    const {
      isRemoved,
      data: { isTemp }
    } = this.props;

    if (isTemp) {
      return (
        <Action
          type="st-outline"
          icon={faMinus}
          iconSize="1x"
          onClick={this.onRemove}
        />
      );
    }
    return (
      <Action
        type="st-outline"
        icon={isRemoved ? faUndo : faTrash}
        iconSize="1x"
        onClick={this.onRemove}
      />
    );
  };

  renderCollapseAction = () => {
    const { isCollapased } = this.props;
    return (
      <Action
        type="st-outline"
        icon={isCollapased ? faCaretDown : faCaretUp}
        iconSize="1x"
        onClick={this.toggleCollapse}
      />
    );
  };

  render() {
    const { isCollapased, isRemoved } = this.props;

    const fields = this.formGroups();

    return (
      <div
        className={classNames(
          "field-inline-form",
          { collapsed: isCollapased },
          { "delete-item": isRemoved }
        )}
      >
        {this.renderCollapsedView(fields)}
        {!isCollapased && !isRemoved && (
          <div className="field-inline-form-properties">
            {fields.map(field => this.renderField(field))}
          </div>
        )}
      </div>
    );
  }
}
