import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { titleize } from '_utils/strings';
import { More } from './more';

export function Tabs({ active, keys, subNavKeys, onChange, onMoreClick }) {
  return (
    <div className="tab tab-block">
      {keys.map(key => (
        <div
          key={key}
          className={classNames('tab-item', { active: active === key })}
        >
          <button data-key={key} onClick={onChange} className="button">
            {titleize(key.replace('-', ' '))}
          </button>
        </div>
      ))}
      <div
        key={'more'}
        className={classNames('tab-item', {
          active: subNavKeys.includes(active),
        })}
      >
        <More onMoreClick={onMoreClick} />
      </div>
    </div>
  );
}

Tabs.propTypes = {
  active: PropTypes.string.isRequired,
  keys: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default { Tabs };
