import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router";
import { ConnectedRouter } from "connected-react-router";

import { Main } from "_components";

export function Router(props) {
  const { history } = props;
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Main />
      </Switch>
    </ConnectedRouter>
  );
}

Router.propTypes = {
  history: PropTypes.object.isRequired
};

export default { Router };
