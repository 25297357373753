import {
  Text,
  ImageUrl,
  ColourPicker,
  CollectionTypeField,
  TextInput,
} from '_components';

export const fields = ({
  validation,
  data,
  validated,
  handleFieldChange,
  handleOnValidate,
  handleSelectChange,
  handleColourChange,
}) => [
  {
    component: Text,
    textComponent: TextInput,
    placeholder: 'Write A Collection Name',
    id: 'name',
    name: 'Name',
    value: data.name,
    validation: validation.name,
    onValidate: handleOnValidate,
    onChange: handleFieldChange,
    validated: validated,
    domain: 'collection',
  },
  {
    component: CollectionTypeField,
    placeholder: 'Select A Collection Type',
    id: 'typeId',
    isCustom: true,
    validation: validation.typeId,
    validated: validated,
    value: data.typeId,
    onChange: handleSelectChange,
    domain: 'collection',
  },
  {
    component: ImageUrl,
    name: 'Image',
    id: 'imgurl',
    value: data.imgurl,
    validation: validation.imgurl,
    onValidate: handleOnValidate,
    onChange: handleFieldChange,
    invalidMessageLocation: 'CONTENT',
    domain: 'collection',
  },
  {
    type: 'row',
    id: 'colourRow',
    formGroups: [
      {
        type: 'column',
        component: ColourPicker,
        name: 'Hex',
        id: 'hexColour',
        value: data.hexColour,
        validation: validation.hexColour,
        onValidate: handleOnValidate,
        onChange: handleColourChange,
        domain: 'collection',
      },
      {
        type: 'column',
        component: ColourPicker,
        name: 'Name',
        id: 'nameHexColour',
        value: data.nameHexColour,
        validation: validation.nameHexColour,
        onValidate: handleOnValidate,
        onChange: handleColourChange,
        domain: 'collection',
      },
      {
        type: 'column',
        component: ColourPicker,
        name: 'Text',
        id: 'textHexColour',
        value: data.textHexColour,
        validation: validation.textHexColour,
        onValidate: handleOnValidate,
        onChange: handleColourChange,
        domain: 'collection',
      },
    ],
  },
  {
    component: Text,
    name: 'Text',
    id: 'text',
    value: data.text,
    validation: validation.text,
    onValidate: handleOnValidate,
    onChange: handleFieldChange,
    validated: validated,
    domain: 'collection',
  },
];

export const formChildren = () => {};
