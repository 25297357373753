import validate from 'validate.js';

export const packTemplateDefault = {
  active: validate.isBoolean,
  editable: validate.isBoolean,
  tileTemplateId: {
    presence: true,
    length: {
      maximum: 36,
    },
  },
  imgurl: {
    presence: false,
    url: true,
    length: {
      maximum: 255,
    },
  },
  name: {
    presence: true,
    length: {
      maximum: 255,
    },
  },
};

export const packTemplateConstraints = {
  POST: {
    ...packTemplateDefault,
  },
  PUT: {
    ...packTemplateDefault,
    id: {
      presence: true,
      length: {
        maximum: 36,
      },
    },
  },
};

export default {
  packTemplateConstraints,
  packTemplateDefault,
};
