import { schema } from 'normalizr';

const defaultProcessStrategy = (value, parent, key) => {
  switch (key) {
    default:
      return { ...value };
  }
};

const fieldTemplate = new schema.Entity(
  'fieldTemplates',
  {},
  {
    processStrategy: defaultProcessStrategy,
  },
);

const cardTemplate = new schema.Entity(
  'cardTemplates',
  {
    fieldTemplates: [fieldTemplate],
  },
  {
    processStrategy: defaultProcessStrategy,
  },
);

const packTemplate = new schema.Entity(
  'packTemplates',
  {
    cardTemplates: [cardTemplate],
  },
  {
    processStrategy: defaultProcessStrategy,
  },
);

const tileTemplate = new schema.Entity('tileTemplates', {
  packTemplates: [packTemplate],
});

// schema does not normalise children
const tileTemplateBasic = new schema.Entity('tileTemplates');
const tileTemplateBasicSchema = [tileTemplateBasic];

const tileTemplatesSchema = [tileTemplate];
const tileTemplateSchema = tileTemplate;
const packTemplateSchema = packTemplate;
const packTemplatesSchema = [packTemplate];
const cardTemplateSchema = cardTemplate;
const cardTemplatesSchema = [cardTemplate];
const fieldTemplatesSchema = [fieldTemplate];

export {
  tileTemplateSchema,
  tileTemplatesSchema,
  packTemplatesSchema,
  cardTemplatesSchema,
  tileTemplateBasicSchema,
  fieldTemplatesSchema,
  packTemplateSchema,
  cardTemplateSchema,
};
