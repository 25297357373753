import { createStore, applyMiddleware, compose } from "redux";
import { createLogger } from "redux-logger";
import { routerMiddleware } from "connected-react-router";

import thunk from "redux-thunk";
import rootReducer from "./reducers";

export default function configureStore(history, initialState = {}) {
  const middlewares = [routerMiddleware(history), thunk];
  if (process.env.NODE_ENV !== "production") {
    const logger = createLogger({
      collapsed: true,
      diff: true
    });
    middlewares.push(logger);
  }

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  return createStore(
    rootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
}
