import { connect } from 'react-redux';
import { addHOC } from '../../form';
import { attemptUpdateFormData } from '_thunks/views';
import { fields, formChildren } from './fields';
import { AddCollectionTypeFormContainer } from './addContainer';
import { collectionTypeDefault as constraints } from '_api/constraints';

const mapStateToProps = (state, props) => {
  return {
    ...state.form.collectionType,
    activeItem: state.collectionTypes.find(x => x.id === props.id),
  };
};

const mapStateToDispatch = dispatch => ({
  updateFormData: data =>
    dispatch(attemptUpdateFormData('collectionType', data)),
  resetFormData: data =>
    dispatch(attemptUpdateFormData('collectionType', data)),
});

export const AddCollectionTypeForm = connect(
  mapStateToProps,
  mapStateToDispatch,
)(
  addHOC(AddCollectionTypeFormContainer, {
    fields,
    formChildren,
    title: 'Collection Type',
    constraints,
  }),
);

export default { AddCollectionTypeForm };
