import React from "react";
import { TileTemplateActiveRadio } from "./tileTemplateActiveRadio";
import { Radio } from "_components";

export default (isCollapsed = false, searchText = "") => [
  {
    label: "ID",
    key: "id",
    options: {
      display: false,
      filter: false,
      sort: false
    }
  },
  {
    name: "Active",
    key: "active",
    options: {
      display: !isCollapsed,
      filter: true,
      sort: true,
      download: false,
      customBodyRender: (...props) => {
        return (
          <TileTemplateActiveRadio
            value={props[0]}
            rowIndex={props[1].rowIndex}
            className={"cell-radio-container"}
            name="radio-button-active"
          />
        );
      },
      setCellProps: (...props) => {
        return {
          ...props,
          className: "cell-radio"
        };
      }
    }
  },
  {
    name: "Image",
    key: "imgurlDefault",
    options: {
      display: !isCollapsed,
      filter: false,
      sort: true,
      download: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div className="cell-image-parent">
            <div className="image-container">
              <img className="image-body" src={value} alt="" />
            </div>
          </div>
        );
      },
      setCellProps: (cellValue, rowIndex, columnIndex) => ({
        className: "cell-image"
      })
    }
  },
  {
    name: "Alt Image",
    key: "imgurlAlternate",
    options: {
      display: false,
      filter: false,
      sort: false,
      download: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div className="image-container">
            <img className="image-body" src={value} alt="" />
          </div>
        );
      },
      setCellProps: (cellValue, rowIndex, columnIndex) => ({
        className: "cell-image"
      })
    }
  },
  {
    name: "Noun",
    key: "noun",
    options: {
      filter: true,
      filterList: searchText ? [searchText] : []
    }
  },
  {
    name: "Show Noun",
    key: "nounShow",
    options: {
      display: false,

      sort: true,
      customBodyRender: (value, meta, updateValue) => {
        return <Radio checked={value} disabled name="radio-button-active" />;
      },
      setCellProps: (cellValue, rowIndex, columnIndex) => ({
        className: "cell-radio"
      })
    }
  },
  {
    name: "Text",
    key: "text",
    options: {
      display: false
    }
  },
  {
    name: "Show Text",
    key: "textShow",
    options: {
      display: false,
      filter: true,
      sort: true,
      customBodyRender: (value, meta, updateValue) => {
        return <Radio checked={value} disabled name="radio-button-active" />;
      },
      setCellProps: (cellValue, rowIndex, columnIndex) => ({
        className: "cell-radio"
      })
    }
  },
  {
    name: "Text Colour",
    key: "textHexColour",
    options: {
      display: false,
      filter: false,
      sort: false,
      setCellProps: (cellValue, rowIndex, columnIndex) => ({
        className: "cell-color-container"
      }),
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div className="hex-color">
            <span
              className="collection-color"
              style={{ backgroundColor: value }}
            />
            <span className="collection-color-code">{value}</span>
          </div>
        );
      }
    }
  },
  {
    name: "Noun Colour",
    key: "nounHexColour",
    options: {
      display: false,
      filter: false,
      sort: false,
      setCellProps: (cellValue, rowIndex, columnIndex) => ({
        className: "cell-color-container"
      }),
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div className="hex-color">
            <span
              className="collection-color"
              style={{ backgroundColor: value }}
            />
            <span className="collection-color-code">{value}</span>
          </div>
        );
      }
    }
  },
  {
    name: "Colour",
    key: "hexColour",
    options: {
      display: false,
      filter: false,
      sort: false,
      setCellProps: (cellValue, rowIndex, columnIndex) => ({
        className: "cell-color-container"
      }),
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div className="hex-color">
            <span
              className="collection-color"
              style={{ backgroundColor: value }}
            />
            <span className="collection-color-code">{value}</span>
          </div>
        );
      }
    }
  },

  {
    name: "S3 Url",
    key: "s3Url",
    options: {
      display: false,
      filter: false,
      sort: false
    }
  },
  {
    name: "API Url",
    key: "apiUrl",
    options: {
      display: false,
      filter: false,
      sort: false
    }
  }
  // {
  //   name: 'Category ID',
  //   key: 'categoryId',
  // },
  // {
  //   name: 'Collection ID',
  //   key: 'collectionId',
  // },
  // {
  //   name: 'Level Asset Bezel',
  //   key: 'levelAssetBezel',
  //   options: {
  //     display: false,
  //     filter: true,
  //     sort: true,
  //   },
  // },
  // {
  //   name: 'Level Schema ID',
  //   key: 'levelSchemaId',
  //   options: {
  //     display: false,
  //     filter: true,
  //     sort: false,
  //   },
  // },
];
