import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

class Container extends Component {
  handleClose = () => this.props.handleClose();
  handleOpen = () => this.props.handleOpen();

  render() {
    const { total, count, failures, isImporting } = this.props;
    if (!isImporting) return null;
    return (
      <div className="job-details">
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
        >
          <div
            id="customized-dialog-title"
            className="modal-header"
            onClose={this.handleClose}
          >
            <span className="title">Job Details</span>
            <IconButton
              aria-label="Close"
              className={'modal-header-close'}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal-content modal-content-top job-details-modal">
            <span>{`${total} Total`}</span>
            <span>{`${count.success} Successful`}</span>
            <span>{`${count.failure} Failures`}</span>
            <div className="items">
              {failures.map((failure, i) => (
                <span key={i} className="failure-item">
                  {failure}
                </span>
              ))}
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

function select(state, props) {
  return {
    ...state.batch,
  };
}

export const JobDetails = connect(select)(Container);
