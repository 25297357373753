import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { TileTemplateTable, AddTileTemplateForm } from '../.';
import { BooleanField } from '_components';
import { withFieldActions } from '../../form';
import { sectionHOC } from '../../templates/section';
import { TileTemplateSectionContainer } from './container';
import { attemptUpdateFormData } from '_thunks/views';
import { tileTemplateDefault as constraints } from '_api/constraints';
import { TileItem } from '../item';

import {
  setPanel,
  updateSectionView,
  clearPanel,
} from '../../../store/actions/sectionView';

const mapStateToProps = (state, props) => {
  return {
    isDirty: state.form.tileTemplate.isDirty,
    items: state.tileTemplates,
    isValid: state.form.tileTemplate.isValid,
    validated: state.form.tileTemplate.validated,
    formData: state.form.tileTemplate.data,
    ...state.sectionView.tileTemplate,
    constraints,
  };
};

const mapDispatchToProps = dispatch => ({
  updateFormData: data => dispatch(attemptUpdateFormData('tileTemplate', data)),
  setPanel: ({ match, history, queries }) => {
    dispatch(
      setPanel('tileTemplate', {
        domain: 'tileTemplate',
        type: 'item',
        level: 'secondary',
        component: 'tileTemplateItem',
      }),
    );
  },

  clearItemPanel: () => {
    dispatch(clearPanel('tileTemplate', 'secondary'));
  },
  confirmAddClick: domain => {
    dispatch(
      updateSectionView('tileTemplate', {
        active: domain,
        method: 'add',
      }),
    );
    dispatch(
      setPanel('tileTemplate', {
        domain: 'tileTemplate',
        type: 'item',
        level: 'secondary',
        component: 'tileTemplateItem',
      }),
    );
    dispatch(
      setPanel('tileTemplate', {
        domain: 'tileTemplate',
        type: 'form',
        level: 'ternary',
        component: 'addTileTemplateForm',
      }),
    );
  },
  getData: id => {},
  resetFormData: () =>
    dispatch(
      attemptUpdateFormData('tileTemplate', {
        reset: true,
      }),
    ),
});

function metaActions(props) {
  return [
    {
      component: BooleanField,
      name: 'Active',
      id: 'active',
      label: 'active',
    },
  ];
}

export const TileTemplatesSection = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withRouter(
    withFieldActions(
      sectionHOC(TileTemplateSectionContainer, {
        ItemComponent: TileItem,
        TableComponent: TileTemplateTable,
        AddFormComponent: AddTileTemplateForm,
        routePrefix: '/tile-templates',
        key: 'Tile Template',
        dataKey: 'tileTemplates',
        formKey: 'tileTemplate',
        metaActions,
        constraints,
      }),
    ),
  ),
);

export default { TileTemplatesSection };
