import update from 'immutability-helper';
import {
  SET_SECTION_VIEW,
  CLOSE_SECTION_FORM,
  SET_PANEL,
  CLEAR_PANEL,
  UPDATE_SECTION_VIEW,
} from '_actions/sectionView';

const INITIAL_STATE = {
  tileTemplate: {
    levels: ['primary', 'secondary', 'ternary'],
    attemptToClose: false,
    active: false,
    method: null,
    collaspedPanels: {
      primary: false,
    },
    panels: {
      primary: {
        domain: 'tileTemplate',
        type: 'table',
        component: 'tileTemplateTable',
      },
      secondary: null,
      ternary: null,
    },
  },
};

export default function panelsView(
  state = INITIAL_STATE,
  { id, type, level, ...data },
) {
  switch (type) {
    case SET_SECTION_VIEW:
      return update(state, {
        [id]: { $set: { ...state[id], ...data } },
      });
    case CLOSE_SECTION_FORM:
      return update(state, {
        [id]: {
          attemptToClose: { $set: false },
          active: { $set: null },
          cloneId: { $set: null },
          method: { $set: null },
          panels: { ternary: { $set: null } },
        },
      });
    case CLEAR_PANEL:
      return update(state, {
        [id]: {
          panels: {
            [level]: { $set: null },
          },
        },
      });
    case SET_PANEL:
      return update(state, {
        [id]: {
          panels: {
            [level]: { $set: data.panel },
          },
        },
      });
    case UPDATE_SECTION_VIEW:
      return update(state, {
        [id]: {
          $set: {
            ...state[id],
            ...data.sectionView,
          },
        },
      });

    default:
      return state;
  }
}
