import React, { Component } from "react";
import { connect } from "react-redux";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import IconButton from "@material-ui/core/IconButton";

class _BatchImportButton extends Component {
  render() {
    const { isDisabled = false, action } = this.props;

    return (
      <IconButton
        onClick={() => (action.onClick ? action.onClick(action.props) : null)}
        className={
          isDisabled
            ? "toolbar-selected-button disabled"
            : "toolbar-selected-button"
        }
        disabled={isDisabled}
      >
        <FileCopyIcon action={action} isdisabled={isDisabled.toString()} />
      </IconButton>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { isImporting } = state.batch;

  return {
    isDisabled: isImporting
  };
};

const BatchImportButton = connect(mapStateToProps)(_BatchImportButton);

export default BatchImportButton;
