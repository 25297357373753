import React, { Component } from 'react';
import { Main } from './main';
import { homeRoutes } from './routes';
// import Auth from '@aws-amplify/auth';

export class MainContainer extends Component {
  async componentDidMount() {
    this.props.setCurrentUser();
  }

  componentDidUpdate() {
    this.scrollToTop();
  }

  getTitle = () =>
    homeRoutes.find(
      route =>
        route.path.replace('/', '') ===
        this.props.location.pathname.split('/')[1],
    ).title || '';

  scrollToTop = () => window.scrollTo(0, 0);

  pageTitle = () =>
    <span>
      {this.getTitle()}
    </span>;

  render() {
    return <Main {...this.props} />;
  }
}

export default { MainContainer };
