import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: '10px 20px',
    backgroundColor: state.isFocused ? '#2196f3' : 'transparent',
    color: state.isFocused ? '#ffffff' : 'inherit',
    cursor: 'pointer',
  }),

  //   // none of react-select's styles are passed to <Control />
  //   width: '100%',
  //   height: '45px',

  //   borderRadius: '2px',
  //   display: 'flex',
  //   padding: '10px 20px',
  //   transition: 'border 0.25s ease-in-out',
  // }),
  // valueContainer: () => ({
  //   margin: '0px',
  //   padding: '0px 10px 0px 0px',
  //   flex: 1,
  // }),
  // placeholder: () => ({
  //   margin: '0',
  //   padding: '0',
  //   fontFamily: 'PT Mono, monospace',
  //   fontSize: '0.8rem',
  //   lineHeight: '1.2rem',
  //   color: '#bcc3ce',
  // }),
  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = 'opacity 300ms';

  //   return { ...provided, opacity, transition };
  // },
  menu: provided => ({
    ...provided,
    margin: '0px',
    borderRadius: '2px',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    border: 'none',
    maxHeight: '200px',
    overflowY: 'scroll',
    fontFamily: 'PT Mono, monospace',
    fontSize: '0.8rem',
    lineHeight: '1.2rem',
    cursor: 'pointer',
  }),
  menuList: () => ({}),
};

export function SelectInput({ options, value, onChange, ...otherProps }) {
  return (
    <Select
      styles={customStyles}
      value={value}
      onChange={onChange}
      options={options}
      {...otherProps}
    />
  );
}

SelectInput.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  classNamePrefix: PropTypes.string,
};

SelectInput.defaultProps = {
  autoFocus: false,
  className: '',
  isDisabled: false,
  isMulti: false,
  isSearchable: true,
  isClearable: true,
  name: null,
  placeholder: null,
  value: null,
  classNamePrefix: 'select',
};

export default { SelectInput };
