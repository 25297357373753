export const fieldTemplateTypes = [
  'boolean',
  'varchar',
  'location',
  'int',
  'dropdown',
  'date',
  'datetime',
  'time',
  'timestamp',
  'comment',
];
