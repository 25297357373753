import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  attemptDeleteCollection,
  attemptCreateCollection,
  attemptUpdateCollection,
  attemptGetCollectionData
} from "_thunks/collections";
import { CollectionItem, CollectionTable, AddCollectionForm } from "../.";
import { sectionHOC, Section } from "../../templates/section";
import { withFieldActions } from "_components/form";
import { attemptUpdateFormData } from "_thunks/views";
import { collectionDefault as constraints } from "_api/constraints";

const mapStateToProps = state => ({
  isDirty: state.form.collection.isDirty,
  items: state.collections,
  isValid: state.form.collection.isValid,
  validated: state.form.collection.validated,
  formData: state.form.collection.data,
  constraints
});

const mapDispatchToProps = dispatch => ({
  deleteItem: id => dispatch(attemptDeleteCollection(id)),
  createItem: collection => dispatch(attemptCreateCollection(collection)),
  updateItem: collection => dispatch(attemptUpdateCollection(collection)),
  getData: id => dispatch(attemptGetCollectionData(id)),
  updateFormData: data => dispatch(attemptUpdateFormData("collection", data)),
  resetFormData: () => {
    return dispatch(
      attemptUpdateFormData("collection", {
        reset: true
      })
    );
  }
});

function itemSelector(data) {
  return {
    title: data.name,
    subtitle: data.text,
    image: data.imgurl
  };
}

export const CollectionsSection = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withFieldActions(
      sectionHOC(Section, {
        ItemComponent: CollectionItem,
        TableComponent: CollectionTable,
        AddFormComponent: AddCollectionForm,
        routePrefix: "/collections",
        key: "Collection",
        itemSelector
      })
    )
  )
);

export default { CollectionsSection };
