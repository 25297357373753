import React from 'react';
import { string, func, element, oneOfType } from 'prop-types';
const PapaParse = require('papaparse/papaparse.min.js');

export class CSVFileReader extends React.Component {
  state = {
    filename: '',
  };

  static defaultProps = {
    cssClass: 'csv-reader-input',
    cssInputClass: 'csv-input',
    label: 'Browse',
    inputId: null,
    inputStyle: {},
    parserOptions: {},
  };

  handleChangeFile = e => {
    const { onFileLoaded, parserOptions, onError } = this.props;
    let reader = new FileReader();
    const filename = e.target.files[0] && e.target.files[0].name;

    reader.onload = event => {
      const csvData = PapaParse.parse(
        event.target.result,
        Object.assign(parserOptions, {
          error: onError,
        }),
      );
      onFileLoaded(csvData.data, filename);
      this.setState({ filename });
    };

    reader.readAsText(e.target.files[0]);
  };

  render() {
    const { cssClass, cssInputClass, label, inputStyle, inputId } = this.props;
    const { filename } = this.state;

    return (
      <div className={cssClass}>
        <span className={!filename ? 'placeholder' : ''}>
          {filename || 'choose a .csv file'}{' '}
        </span>
        <span>
          {label}
          <input
            className={cssInputClass}
            type="file"
            placeholder={'choose .csv file'}
            id={inputId}
            style={inputStyle}
            accept=".csv, text/csv"
            onChange={e => this.handleChangeFile(e)}
          />
        </span>
      </div>
    );
  }
}

CSVFileReader.propTypes = {
  cssClass: string,
  cssInputClass: string,
  label: oneOfType([string, element]),
  onFileLoaded: func.isRequired,
  onError: func,
  inputId: string,
};

export default { CSVFileReader };
