import React from 'react';
import { CollectionTypesSection } from '_components';

export function CollectionTypesPage({ id }) {
  return (
    <div className="page-wrapper collection-types">
      <div className="container grid-lg" id="page-container">
        <div className="columns col-gapless">
          <div className="column">
            <CollectionTypesSection id={id} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default { CollectionTypesPage };
