import {
  getCardTemplates,
  updateCardTemplate as editCardTemplate,
  deleteCardTemplate,
  createCardTemplate,
} from '_api/cardTemplates';
import {
  setCardTemplates,
  removeCardTemplate,
  updateCardTemplate,
  addCardTemplate,
  addPackTemplateCardTemplate,
} from '_actions/cardTemplates';
import {
  attemptCreateFieldTemplates,
  attemptDeleteFieldTemplates,
  attemptEditFieldTemplates,
} from './fieldTemplates';
import { dispatchError } from '_utils/api';
import imgurlUtils from '_utils/imgurl';
import { to, attemptUpdateEntityImages, uploadTileImage } from 'shared-code';

export const attemptGetCardTemplates = () => (dispatch, getState) => {
  if (getState().fetchStatus.tileTemplates) return Promise.resolve({});
  return getCardTemplates()
    .then(data => {
      dispatch(setCardTemplates(data));
      return data;
    })
    .catch(dispatchError(dispatch));
};

export const attemptEditCardTemplate = ({
  fieldTemplates,
  removedFieldTemplates = [],
  fieldTemplateIds,
  ...item
}) => dispatch =>
  editCardTemplate(item)
    .then(async data => {
      // create tile templates with collection id

      let newTemplates = [];
      let updateTemplates = [];

      fieldTemplates.forEach(({ isTemp, id, ...element }) => {
        if (isTemp) {
          newTemplates.push(element);
        } else if (!removedFieldTemplates.includes(id)) {
          updateTemplates.push({ ...element, id });
        }
      });

      if (newTemplates.length > 0)
        await dispatch(
          attemptCreateFieldTemplates({
            newTemplates,
            tileTemplateId: item.tileTemplateId,
            cardTemplateId: item.cardTemplateId || item.id,
            packTemplateId: item.packTemplateId,
          }),
        );
      if (updateTemplates.length > 0)
        await dispatch(attemptEditFieldTemplates(updateTemplates));

      if (removedFieldTemplates.length > 0)
        await dispatch(
          attemptDeleteFieldTemplates({
            removedFieldTemplates,
            tileTemplateId: item.tileTemplateId,
            cardTemplateId: item.cardTemplateId || item.id,
            packTemplateId: item.packTemplateId,
          }),
        );

      dispatch(updateCardTemplate(data));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptCreateCardTemplate = ({
  fieldTemplates,
  fieldTemplateIds,
  children,
  ...item
}) => dispatch =>
  createCardTemplate(item)
    .then(data => {
      dispatch(addCardTemplate(data));
      dispatch(addPackTemplateCardTemplate(data.packTemplateId, data));
      return data;
    })
    .then(async data => {
      // create field templates with cardTemplate id
      let i;
      const newTemplates = [];
      for (i = 0; i < fieldTemplates.length; i++) {
        const { isTemp, ...element } = fieldTemplates[i];

        newTemplates[i] = {
          ...element,
          tempId: undefined,
          tileTemplateId: item.tileTemplateId,
          cardTemplateId: data.id,
          packTemplateId: item.packTemplateId,
        };
      }
      await dispatch(
        attemptCreateFieldTemplates({
          newTemplates,
          tileTemplateId: item.tileTemplateId,
          cardTemplateId: data.id,
          packTemplateId: item.packTemplateId,
        }),
      );
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptUpdateCardTemplate = (item, isCreating) => async (
  dispatch,
  getState,
) => {
  let imgResponse = null;
  let err = null;
  const state = getState();
  const { data } = state.form.cardTemplate;
  const { imageHasChanged, croppedImage } = state.editImage.card;
  const tileTemplate = state.tileTemplates.find(
    x => x.id === data.tileTemplateId,
  );

  if (imageHasChanged) {
    [err, imgResponse] = await to(
      dispatch(
        attemptUpdateEntityImages({
          croppedImage,
          entity: data,
          entityType: 'card',
          newImage: {
            ...state.editImage.card,
            copyrightChecked: true,
            isPublic: true,
          },
          imageHasChanged,
          id: data.tileTemplateId,
          categoryId: tileTemplate.categoryId,
          updateEntityImage: uploadTileImage,
        }),
      ),
    );
    if (err) return err;
  }

  const newImagePaths = imgurlUtils.parseNewImagePaths({
    type: 'cardTemplate',
    editImage: state.editImage.card,
    imgResponse,
    entity: data,
  });

  const updatedData = {
    ...data,
    ...newImagePaths,
  };

  return isCreating
    ? dispatch(attemptCreateCardTemplate(updatedData))
    : dispatch(attemptEditCardTemplate(updatedData));
};

export const attemptDeleteCardTemplate = ({
  tileTemplateId,
  packTemplateId,
  id,
}) => dispatch =>
  deleteCardTemplate({ tileTemplateId, packTemplateId, id })
    .then(() => {
      dispatch(removeCardTemplate({ tileTemplateId, packTemplateId, id }));
      return { tileTemplateId, packTemplateId, id };
    })
    .catch(dispatchError(dispatch));
