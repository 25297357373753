import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BaseTable, EmptyTileTemplatesMessage } from '_components';
import { BatchTileTemplateModal } from '../batchModal';
import options from './options';
import columns from './columns';

const STATE_KEY_CHANGES = [
  'announceText',
  'page',
  'selectLimit',
  'rowsPerPage',
  'rowsSelected',
  'filterList',
  'selectedRows',
  'searchText',
  'isCreatingBatch',
];

export class TileTemplateTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      announceText: null,
      page: 0,
      selectLimit: 20,
      rowsPerPage: 100,
      rowsSelected: [],
      filterList: false,
      selectedRows: {
        data: [],
        lookup: null,
      },
      showResponsive: false,
      searchText: null,
      columnsX: columns(),
      isCreatingBatch: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    let update = false;
    STATE_KEY_CHANGES.forEach(key => {
      if (this.state[key] !== nextState[key]) {
        update = true;
        return;
      }
    });
    if (nextProps.tileTemplates.length !== this.props.tileTemplates.length)
      update = true;
    return update;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // if (nextProps.isCollasped !== this.props.isCollasped) {
    //   this.setState({
    //     columnsX: columns(nextProps.isCollasped, this.state.searchText)
    //   });
    // }
  }

  handleBatchAddClick = (clear = true) => {
    if (clear) this.props.clearBatchJob();
    this.setState({ isCreatingBatch: !this.state.isCreatingBatch });
  };

  handleTableStateChange = (action, tableState) => {
    switch (action) {
      case 'rowsSelect':
        break;
      case 'changePage':
        this.setState(
          {
            ...tableState,
            page: tableState.page,
            rowsPerPage: tableState.rowsPerPage,
            rowsSelected: [],
          },
          this.setTableState,
        );
        break;
      case 'changeRowsPerPage':
        this.setState(
          {
            ...tableState,
            rowsPerPage: tableState.rowsPerPage,
            rowsSelected: [],
          },
          this.setTableState,
        );
        break;
      case 'sort':
      case 'filter':
      case 'search':
        this.setState(
          {
            ...tableState,
            rowsSelected: [],
          },
          this.setTableState,
        );
        break;
      default:
        break;
    }
  };

  setTableState = () => {
    this.props.setTileTemplatesTableState(this.state);
  };

  handleClearAllSelectedClick = props => {
    this.setState({ rowsSelected: [] }, this.setTableState);
  };

  handleActivateClick = props => {
    const selected = props[0].data.map(i => {
      const item = Object.assign(
        {},
        this.props.tileTemplates[props[1][i.dataIndex].dataIndex],
      );
      item.active = !item.active;
      return item;
    });

    this.props.activateTileTemplates(selected).then(() => {
      if (this.props.selectorKey === 'COLLECTION')
        this.props.getCollectionData();
      this.setState({ rowsSelected: [] }, this.setTableState);
    });
  };

  handleToggleActiveStatusClick = (
    colData,
    { colIndex, rowIndex, dataIndex, ...meta },
  ) => {
    const offest = this.state.page * this.state.rowsPerPage;
    const rowNum = offest + rowIndex;

    const displayData = this.state.displayData;
    const displayIndex = displayData
      ? displayData.findIndex(x => x.dataIndex === dataIndex)
      : rowNum;

    const maxReached = this.state.rowsSelected.length > this.state.selectLimit;

    const isIncluded = this.state.rowsSelected.includes(displayIndex);

    // hit max limit
    if (!isIncluded && maxReached) return;

    const rowsSelected = isIncluded
      ? this.state.rowsSelected.filter(x => displayIndex !== x)
      : this.state.rowsSelected.concat([displayIndex]);

    this.setState(
      {
        rowsSelected,
      },
      this.setTableState,
    );
  };

  onCellClick = (colData, meta) => {
    if (meta.colIndex === 1 && !this.props.isDirty) {
      return this.handleToggleActiveStatusClick(colData, meta);
    }
    return (
      this.props.handleRowClick &&
      this.props.handleRowClick(null, meta, this.props.tileTemplates)
    );
  };

  renderBatchModal = () =>
    <BatchTileTemplateModal
      open={this.state.isCreatingBatch || false}
      handleBatchAddClick={this.handleBatchAddClick}
    />;

  render() {
    const {
      tileTemplates,
      handleAddClick,
      disableSelectableRows,
      isNested,
    } = this.props;

    if (!tileTemplates || tileTemplates.length === 0) {
      return <EmptyTileTemplatesMessage />;
    }

    const columnKeys = this.state.columnsX.map(col => col.key);
    const cleanedData = tileTemplates.map(collection => {
      return columnKeys.map(key => collection[key]);
    });
    const cleanedColumns = this.state.columnsX.map(
      ({ key, ...clean }) => clean,
    );
    const hasSelectableRows = disableSelectableRows ? 'none' : 'multiple';

    return (
      <React.Fragment>
        {this.renderBatchModal()}
        <BaseTable
          key={1}
          title={this.props.title}
          isNested={isNested}
          rows={cleanedData}
          columns={cleanedColumns}
          options={options(
            hasSelectableRows,
            handleAddClick,
            this.handleActivateClick,
            this.handleClearAllSelectedClick,
            this.onCellClick,
            this.handleTableStateChange,
            this.state,
            this.handleBatchAddClick,
          )}
        />
      </React.Fragment>
    );
  }
}

TileTemplateTable.propTypes = {
  tileTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
};

TileTemplateTable.defaultProps = {
  title: null,
  tileTemplates: [],
};

export default { TileTemplateTable };
