import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  progress: {
    position: 'absolute',
    color: '#2196f3',
  },
  negProgress: {
    position: 'absolute',
    color: '#f44336',
  },
  // buttonProgress: {
  //   color: green[500],
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   marginTop: -12,
  //   marginLeft: -12,
  // },
});

function _Button({
  onClick,
  icon,
  iconOnly,
  label,
  className,
  loading,
  classes,
  isNegAction,
  ...otherProps
}) {
  return (
    <React.Fragment>
      <button
        className={classNames('btn', 'btn-st', className, {
          'btn-icon-only': iconOnly,
        })}
        onClick={onClick}
        {...otherProps}
      >
        {loading && (
          <CircularProgress
            size={35}
            className={!isNegAction ? classes.progress : classes.negProgress}
          />
        )}
        {icon && <FontAwesomeIcon icon={icon} size="1x" />}
        {iconOnly ? null : label}
      </button>
    </React.Fragment>
  );
}

export const Button = withStyles(styles)(_Button);
export default { Button };
